import React, { useState } from 'react';
import cn from 'classnames';

import EditInvoiceIcon from 'assets/img/edit-invoice.svg';
import NewInvoiceIcon from 'assets/img/new-invoice.svg';

import InvoiceIcon from 'assets/img/invoice.svg';

import { Typography } from 'components/shared/Typography';
import { Modal } from 'components/shared/Modal';
import { Loading } from 'components/shared/Loading';

import styles from './CreateInvoiceModal.styles.scss';
import { useTranslation } from 'react-i18next';
import { UploadLogo } from '../UploadLogo';

const CreateInvoiceModal = ({
  open,
  loading,
  canEditInvoice,
  showNewInvoiceOptinos,
  onClose,
  onEditInvoiceOptionClick,
  onNewInvoiceClick,
  onNewInvoiceOptionClick,
}) => {
  const { t } = useTranslation();

  const [uploadInnerStyle, setUploadInnerStyle] = useState(styles.uploadInner);

  const setUploadStyle = (loading) => {
    loading
      ? setUploadInnerStyle(styles.uploadInnerLoading)
      : setUploadInnerStyle(styles.uploadInner);
  };

  return (
    <Modal
      className={styles.modalWrapper}
      centered
      open={open}
      onClose={onClose}
    >
      <div className={styles.modal}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <InvoiceIcon className={styles.icon} />
            <Typography className={styles.title}>
              {t('invoice_modal_create_modify')}
            </Typography>
            <div className={styles.inner}>
              {/* <div className={cn(styles.item)}>
                <div className={cn(styles.itemInner, uploadInnerStyle)}>
                  <UploadLogo setUploadStyle={setUploadStyle} />
                </div>
              </div> */}
              <div
                className={cn(styles.item, {
                  [styles.disabledItem]: !canEditInvoice,
                })}
                onClick={canEditInvoice ? onEditInvoiceOptionClick : () => {}}
              >
                <div className={styles.itemInner}>
                  <EditInvoiceIcon className={styles.itemIcon} />
                  <Typography className={styles.itemTitle}>
                    {t('invoice_modal_edit_invoice')}
                  </Typography>
                </div>
              </div>
              <div
                className={cn(styles.item, {
                  [styles.itemActive]: showNewInvoiceOptinos,
                })}
              >
                <div className={styles.itemInner} onClick={onNewInvoiceClick}>
                  <NewInvoiceIcon className={styles.itemIcon} />
                  <Typography className={styles.itemTitle}>
                    {t('invoice_modal_new_invoice')}
                  </Typography>
                </div>
                {showNewInvoiceOptinos && (
                  <div className={styles.itemOptions}>
                    <div
                      className={styles.itemOption}
                      onClick={() => onNewInvoiceOptionClick('cash')}
                    >
                      <Typography className={styles.itemOptionText}>
                        {t('invoice_modal_cash_invoice')}
                      </Typography>
                    </div>
                    <div
                      className={styles.itemOption}
                      onClick={() => onNewInvoiceOptionClick('receivable')}
                    >
                      <Typography className={styles.itemOptionText}>
                        {t('invoice_modal_receivable_invoice')}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default React.memo(CreateInvoiceModal);
