import React from 'react';
import { useTranslation } from 'react-i18next';

import NewInvoiceIcon from 'assets/img/new-invoice.svg';

import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import { MainLayout } from 'components/MainLayout';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { InvoiceTotals } from 'components/InvoiceTotals';

import { MainInfo } from './components/MainInfo';
import { UsersInfo } from './components/UsersInfo';
import { ItemsInfo } from './components/ItemsInfo';
import { NotesInfo } from './components/NotesInfo';

import styles from './InvoicePage.styles.scss';
import { Currencies } from '../../config/invoice/config';
import { INVOICE_TYPES } from '../../constants/common';

const InvoicePage = ({
  loading,
  invoice,
  invoiceCreationType,
  onExitClick,
  onPrintClick,
  logoImage,
  getJofotaraImage,
  loadingLogo,
  downloadInvoicePdfLoading,
}) => {
  const { t } = useTranslation();

  const getInvoiceTitle = () => {
    if (
      [INVOICE_TYPES[0], INVOICE_TYPES[1], INVOICE_TYPES[2]].includes(
        invoice.invoiceType,
      )
    ) {
      if (invoice.noteType) {
        return t('cach_return');
      }
      return t('cash_invoice');
    }
    if (invoice.noteType) {
      return t('receivable_return');
    }
    return t('receivable_invoice');
  };
  return (
    <MainLayout loading={loading}>
      <div className={styles.page}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className={styles.header}>
              <Breadcrumbs
                routeName={invoice.invoiceNumber}
                routeIcon={NewInvoiceIcon}
              />
            </div>
            {!!invoiceCreationType && (
              <Typography className={styles.title}>
                {t('view_invoice_generate_invoice')}
              </Typography>
            )}
            <div className={styles.inner}>
              <div className={styles.billingHeader}>
                {/* <img className={styles.logo} src={logoUrl} /> */}
                <img className={styles.qrCode} src={invoice.qrCodeImage} />
                {/* <Typography className={styles.bilingTitle}>
                  {t('view_invoice_national_billing')}
                </Typography> */}
                <>{loadingLogo ? <Loading /> : logoImage()}</>
                <>{getJofotaraImage()}</>
              </div>
              <div className={styles.invoiceTitle}>
                <Typography>{getInvoiceTitle()}</Typography>
              </div>
              <MainInfo
                invoice={invoice}
                invoiceCreationType={invoiceCreationType}
              />
              <UsersInfo
                seller={invoice.seller}
                buyer={invoice.buyer}
                invoiceType={invoice.invoiceType}
              />
              <ItemsInfo
                items={invoice.items}
                invoiceType={invoice.invoiceType}
              />
              <InvoiceTotals
                className={styles.totals}
                items={invoice.items}
                invoiceType={invoice.invoiceType}
                invoiceKind={invoice.invoiceKind}
                currencyType={Currencies(t).find(
                  (item) => item.value === invoice.currencyType,
                )}
              />
              <NotesInfo notes={invoice.notes} />

              <div className={styles.pageFooter}>
                <div className={styles.footerBorder}>
                  <div />
                  <div />
                </div>
                <div className={styles.footerText}>
                  <Typography>
                    {t('view_invoice_usersInfo_phoneNumber')}:{' '}
                    <span>{invoice.seller?.phoneNumber}</span>
                  </Typography>
                  <Typography>
                    {t('address')}: <span>{invoice.seller?.country}</span>
                  </Typography>
                </div>
              </div>
            </div>
            <div className={styles.actions}>
              <Button
                className={styles.action}
                variant="outlined"
                onClick={onExitClick}
              >
                {t('view_invoice_cancel_exit')}
              </Button>
              <Button
                className={styles.action}
                onClick={onPrintClick}
                disabled={downloadInvoicePdfLoading}
                loading={downloadInvoicePdfLoading}
              >
                {t('view_invoice_print')}
              </Button>
            </div>
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default React.memo(InvoicePage);
