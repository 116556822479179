import React from 'react';
import cn from 'classnames';

import styles from './Switch.styles.scss';

const Switch = ({ className, disabled, ...restProps }) => (
  <div
    className={cn(
      styles.switch,
      { [styles.switchDisabled]: disabled },
      className,
    )}
    tabIndex="-1"
  >
    <input type="checkbox" className={styles.field} {...restProps} />
    <div className={styles.body} />
    <div className={styles.toggle} />
  </div>
);

export default React.memo(Switch);
