import React, { useState } from 'react';

import * as api from 'api/methods';
import { useTranslation } from 'react-i18next';
import UserModal from './UserModal';

const UserModalContainer = ({
  user,
  setTriggerUsersRequest,
  onClose,
  ...restProps
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const loadActivityOptions = () =>
    api.getAllActivities().then((data) =>
      data.map((item) => {
        let label, value;
        if (item.invoiceType === 0) {
          label = t('activity_number_income', {
            activityNumber: item.activity,
          });
          value = item;
        } else if (item.invoiceType === 1) {
          label = t('activity_number_general', {
            activityNumber: item.activity,
          });
          value = item;
        } else {
          label = t('activity_number_special', {
            activityNumber: item.activity,
          });
          value = item;
        }

        return { label, value };
      }),
    );

  const handleActivityNumberChange = (activityNumber, setFieldValue) => {
    setFieldValue('notes', activityNumber?.value?.description);
  };

  const handleFormSubmit = ({ activityNumber, ...data }, { setFieldError }) => {
    setLoading(true);

    if (user && user.id) {
      api
        .updateSubAdmin(user.id, {
          ...data,
          activityDTO: { activity: activityNumber?.value?.activity },
        })
        .then((response) => {
          setLoading(false);

          setTriggerUsersRequest(new Date());

          onClose();
        })
        .catch(({ error }) => {
          setFieldError('username', error);

          setLoading(false);
        });
    } else {
      api
        .addSubAdmin({
          ...data,
          activityDTO: { activity: activityNumber?.value?.activity },
        })
        .then((response) => {
          setLoading(false);

          setTriggerUsersRequest(new Date());

          onClose();
        })
        .catch(({ error }) => {
          setFieldError('username', error);

          setLoading(false);
        });
    }
  };

  return (
    <UserModal
      {...restProps}
      loading={loading}
      loadActivityOptions={loadActivityOptions}
      user={user}
      onActivityNumberChange={handleActivityNumberChange}
      onClose={onClose}
      onFormSubmit={handleFormSubmit}
    />
  );
};

export default React.memo(UserModalContainer);
