export function truncateToThreeDecimalPlaces(value) {
  if (isNaN(value)) {
    return value;
  }
  const truncatedNumber = Math.floor(value * 1000) / 1000;
  return truncatedNumber;
}

export function roundToThreeDecimalPlaces(value) {
  if (isNaN(value)) {
    return value;
  }
  const roundedNumber = Math.round(value * 1000) / 1000;
  return roundedNumber;
}
