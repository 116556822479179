import React from 'react';
import { Formik, Form } from 'formik';
import parse from 'date-fns/parse';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'hooks/useLanguage';

import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';
import { FormGroup } from 'components/shared/FormGroup';

import { FormikField } from 'components/FormikField';
import {
  InputField,
  SelectField,
  DatePickerInputField,
} from 'components/FormikFields';

import {
  INVOICE_TYPES_OPTIONS,
  INVOICE_NOTE_TYPES_OPTIONS,
  INVOICE_STATUSES_OPTIONS,
} from './FilterModal.constants';

import { validationSchema } from './FilterModal.formConfig';

import { convertDataToForm } from './FilterModal.helpers';

import styles from './FilterModal.styles.scss';

import { useUser } from 'context/user';
import { Invoice_Kind } from '../../../../config/invoice/config';

const FilterModal = ({
  open,
  showUsernameField,
  currentFilters,
  loadActivityOptions,
  loadSubAdminsOptions,
  onClose,
  onSubmitFilters,
  onResetClick,
}) => {
  const { t } = useTranslation();
  const language = useLanguage();
  const { user } = useUser();

  const invoiceKind = [
    {
      label: t('unspecified'),
      value: null,
    },
    ...Invoice_Kind(t),
  ];
  return (
    <Modal
      className={styles.modalWrapper}
      closeClassName={styles.modalCloseIcon}
      open={open}
      onClose={onClose}
    >
      <div className={styles.modal}>
        <Typography className={styles.title} weight="bold">
          {t('filter_modal_filter_options')}
        </Typography>
        <Formik
          validateOnMount
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={convertDataToForm(currentFilters)}
          onSubmit={onSubmitFilters}
        >
          {({ resetForm, values }) => (
            <Form className={styles.form}>
              <div
                className={language === 'ar' ? 'row flex-row-reverse' : 'row'}
              >
                {/* //first row */}
                <div
                  className={language === 'ar' ? 'row flex-row-reverse' : 'row'}
                >
                  <div class="col-md-6 col-sm-12">
                    <FormikField
                      name="invoiceType"
                      component={SelectField}
                      componentProps={{
                        className: styles.field,
                        options: INVOICE_TYPES_OPTIONS({
                          t,
                        }),
                      }}
                      label={t('filter_modal_invoice_type')}
                    />
                  </div>

                  <div class="col-md-6 col-sm-12">
                    <FormikField
                      name="noteType"
                      component={SelectField}
                      componentProps={{
                        className: styles.field,
                        options: INVOICE_NOTE_TYPES_OPTIONS(),
                      }}
                      label={t('filter_modal_notice_type')}
                    />
                  </div>
                </div>

                <div
                  className={language === 'ar' ? 'row flex-row-reverse' : 'row'}
                >
                  <div class="col-md-3 col-sm-12">
                    <FormikField
                      name="issueDateFrom"
                      component={DatePickerInputField}
                      label={t('filter_modal_date_from')}
                    />
                  </div>

                  <div class="col-md-3 col-sm-12">
                    <FormikField
                      name="issueDateTo"
                      component={DatePickerInputField}
                      componentProps={{
                        disabledDays: values.issueDateFrom
                          ? [
                              {
                                before: parse(
                                  values.issueDateFrom,
                                  'dd/MM/yyyy',
                                  new Date(),
                                ),
                              },
                            ]
                          : [],
                      }}
                      label={t('filter_modal_date_to')}
                    />
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <FormikField
                      name="activityNumber"
                      component={SelectField}
                      componentProps={{
                        className: styles.field,
                        type: 'async',
                        cacheOptions: true,
                        defaultOptions: true,
                        loadOptions: loadActivityOptions,
                      }}
                      label={t('filter_modal_input_source_sequence')}
                    />
                  </div>
                </div>

                <div
                  className={language === 'ar' ? 'row flex-row-reverse' : 'row'}
                >
                  <div class="col-md-3 col-sm-12">
                    <FormikField
                      name="totalAmountFrom"
                      component={InputField}
                      label={t('filter_modal_invoice_amount_from')}
                    />
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <FormikField
                      name="totalAmountTo"
                      component={InputField}
                      label={t('filter_modal_invoice_amount_to')}
                    />
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <FormikField
                      name="invoiceStatus"
                      component={SelectField}
                      componentProps={{
                        className: styles.field,
                        options: invoiceKind,
                      }}
                      label={t('Invoice_Kind')}
                    />
                  </div>
                </div>

                {showUsernameField && (
                  <FormGroup>
                    <FormikField
                      name="username"
                      component={SelectField}
                      componentProps={{
                        className: styles.field,
                        type: 'async',
                        cacheOptions: true,
                        defaultOptions: true,
                        loadOptions: loadSubAdminsOptions,
                      }}
                      label={t('filter_modal_invoice_editor')}
                    />
                  </FormGroup>
                )}
              </div>

              <div className={styles.actions}>
                <Button
                  className={styles.action}
                  x
                  variant="outlined"
                  onClick={() => onResetClick(resetForm)}
                >
                  {t('filter_modal_re_set')}
                </Button>
                <Button className={styles.action} type="submit">
                  {t('filter_modal_filtering')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default React.memo(FilterModal);
