import React from 'react';
import cn from 'classnames';

import styles from './Typography.styles.scss';

const VARIANTS = {
  heading1: styles.heading1,
  heading2: styles.heading2,
  subtitle: styles.subtitle,
  body1: styles.body1,
  body2: styles.body2,
  body3: styles.body3,
  caption: styles.caption,
};

const FAMILIES = {
  tahoma: styles.tahoma,
};

const WEIGHTS = {
  bold: styles.bold,
  regular: styles.regular,
  medium: styles.medium,
  semiBold: styles.semiBold,
};

const Typography = ({
  className,
  children,
  variant,
  family,
  weight,
  component,
  ...restProps
}) => {
  const Component = component || 'p';

  return (
    <Component
      {...restProps}
      className={cn(
        styles.typography,
        FAMILIES[family],
        WEIGHTS[weight],
        VARIANTS[variant],
        className,
      )}
    >
      {children}
    </Component>
  );
};

Typography.defaultProps = {
  family: 'tahoma',
  weight: 'regular',
};

export default React.memo(Typography);
