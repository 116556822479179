import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/shared/Typography';
import { AuthLayout } from 'components/AuthLayout';

import { ChangePasswordForm } from './components/ChangePasswordForm';

import styles from './ChangePasswordPage.styles.scss';

const ChangePasswordPage = () => {
  const { t } = useTranslation();

  return (
    <AuthLayout>
      <Typography weight="bold" className={styles.title}>
        {t('change_password_title')}
      </Typography>
      <ChangePasswordForm />
    </AuthLayout>
  );
};

export default React.memo(ChangePasswordPage);
