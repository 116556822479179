import React from 'react';
import cn from 'classnames';

import { Loading } from 'components/shared/Loading';

import styles from './Button.styles.scss';

const VARIANTS = {
  contained: styles.contained,
  outlined: styles.outlined,
};

const Button = ({ className, loading, variant, children, ...restProps }) => (
  <button
    className={cn(styles.button, VARIANTS[variant], className)}
    {...restProps}
  >
    {children}
    {loading && (
      <Loading
        className={styles.loading}
        innerClassName={styles.loadingInner}
      />
    )}
  </button>
);

Button.defaultProps = {
  type: 'button',
  variant: 'contained',
};

export default React.memo(Button);
