import React, { useState } from 'react';
import AddVatForm from './AddVatForm';

import * as api from 'api/methods';

const AddVatContainer = (props) => {
  const [loading, setLoading] = useState(false);
  const [errorValue, setErrorValue] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');

  const handleSubmit = (values) => {
    setLoading(true);
    api
      .generateOTPForForgetPassword(values, captchaToken)
      .then((data) => {
        props.setUserData({
          mobileNum: data.mobile,
          taxNumber: values.taxNumber,
        });
        setLoading(false);
      })
      .catch(({ error }) => {
        if (error) {
          setErrorValue(error);
        }

        setLoading(false);
      });
  };

  const handleReCaptcha = (token) => {
    setCaptchaToken(token);
  };

  return (
    <AddVatForm
      {...props}
      loading={loading}
      onSubmit={handleSubmit}
      errorValue={errorValue}
      onCaptchaChange={handleReCaptcha}
      isValidCaptcha={!!captchaToken}
    />
  );
};

export default React.memo(AddVatContainer);
