import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './FormControl.styles.scss';

const FormControl = ({
  className,
  haveError,
  required,
  label,
  labelProps,
  error,
  customError,
  successNote,
  children,
}) => {
  return (
    <div className={cn(styles.control, className)}>
      {label && (
        <Typography
          {...labelProps}
          className={cn(
            styles.label,
            { [styles.labelRequired]: required },
            labelProps?.className,
          )}
        >
          {label}{' '}
        </Typography>
      )}
      {children}
      {((haveError && error) || customError) && (
        <div className={styles.error}>
          <Typography className={styles.errorText}>
            {error || customError}
          </Typography>
        </div>
      )}
      {!((haveError && error) || customError) && successNote && (
        <div className={styles.success}>
          <Typography className={styles.successText}>{successNote}</Typography>
        </div>
      )}
    </div>
  );
};
export default React.memo(FormControl);
