import React, {
  createContext,
  useEffect,
  useContext,
  useReducer,
  useState,
} from 'react';

import { TOKEN_KEY } from 'constants/common';

import * as api from 'api/methods';

import { Loading } from 'components/shared/Loading';

const UserContext = createContext();

function userReducer(state, action) {
  switch (action.type) {
    case 'SET_USER': {
      return { user: action.payload };
    }
  }
}

const UserProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const [state, dispatch] = useReducer(userReducer, { user: null });

  const value = { state, dispatch };

  useEffect(() => {
    const authToken = localStorage.getItem(TOKEN_KEY);

    if (authToken) {
      api
        .getUserInfo()
        .then((data) => {
          dispatch({ type: 'SET_USER', payload: data });

          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <UserContext.Provider value={value}>{children}</UserContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return { user: context.state.user, dispatchUser: context.dispatch };
};

export { UserProvider, useUser };
