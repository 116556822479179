import React from 'react';

import { Select } from 'components/shared/Select';

const SelectField = ({ field, form, onChange, ...restProps }) => {
  const handleChange = (value) => {
    form.setFieldValue(field.name, value);

    if (onChange) {
      onChange(value);
    }
  };

  const handleBlur = () => {
    form.setFieldTouched(field.name, true);
  };

  return (
    <Select
      {...field}
      {...restProps}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default React.memo(SelectField);
