import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as api from 'api/methods';

import AddDeviceModal from './AddDeviceModal';

const AddDeviceModalContainer = ({
  setTriggerDevicesRequest,
  onClose,
  ...restProps
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [openAttention, setOpenAttention] = useState(false);
  const [deviceValues, setDeviceValues] = useState({});

  const loadActivityOptions = () =>
    api.getAllActivities().then((data) =>
      data.map((item) => {
        let label, value;

        if (item.invoiceType === 0) {
          label = t('activity_number_income', {
            activityNumber: item.activity,
          });
          value = item.activity;
        } else if (item.invoiceType === 1) {
          label = t('activity_number_general', {
            activityNumber: item.activity,
          });
          value = item.activity;
        } else {
          label = t('activity_number_special', {
            activityNumber: item.activity,
          });
          value = item.activity;
        }

        return { label, value };
      }),
    );

  const handleConfirm = () => {
    setLoading(true);

    api
      .addDevice({
        deviceName: deviceValues.deviceName,
        activityDTO: { activity: deviceValues.activityNumber?.value },
      })
      .then((response) => {
        setLoading(false);
        setTriggerDevicesRequest(new Date());

        setOpenAttention(false);
        onClose();
      })
      .catch((error) => {
        console.warn(error);

        setLoading(false);
      });
  };

  const handleFormSubmit = (values) => {
    setDeviceValues(values);
    setOpenAttention(true);
  };

  return (
    <AddDeviceModal
      {...restProps}
      loading={loading}
      loadActivityOptions={loadActivityOptions}
      onClose={onClose}
      onFormSubmit={handleFormSubmit}
      openAttention={openAttention}
      onCloseAttentionModal={() => setOpenAttention(false)}
      onConfirm={handleConfirm}
    />
  );
};

export default React.memo(AddDeviceModalContainer);
