import { Form, Formik } from 'formik';
import { useLanguage } from 'hooks/useLanguage';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormikField } from 'components/FormikField';
import {
  CheckboxField,
  InputField,
  PasswordField,
} from 'components/FormikFields';
import { Button } from 'components/shared/Button';

import { initialValues, validationSchema } from './LoginForm.formConfig';

import styles from './LoginForm.styles.scss';

import { EINVOICE_GOV_PAGE } from 'constants/common';

const LoginForm = ({ loading, onSubmit, errorValue }) => {
  const { t } = useTranslation();
  const language = useLanguage();

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={validationSchema(t)}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {() => (
        <Form className={styles.form}>
          <FormikField
            name="taxNumber"
            component={InputField}
            label={t('login_page_tax_number')}
          />
          <FormikField
            name="username"
            component={InputField}
            label={t('login_page_user_name')}
          />
          <FormikField
            name="password"
            component={PasswordField}
            label={t('login_page_password')}
          />
          <div
            className={
              language === 'ar'
                ? 'd-flex justify-content-between flex-row-reverse'
                : 'd-flex justify-content-between'
            }
          >
            <div>
              <FormikField
                name="rememberPassword"
                component={CheckboxField}
                componentProps={{ label: t('login_page_remember_me') }}
              />
            </div>
            <div class=" text-center">
              <a href={EINVOICE_GOV_PAGE} className={styles.forgotPasswordLink}>
                {t('login_page_signup')}
              </a>
            </div>
            <div>
              <a href="/forgot-password" className={styles.forgotPasswordLink}>
                {t('login_page_frogot_password')}
              </a>
            </div>
          </div>
          <div className={styles.moreInfoContainer}>
            <a
              href="https://www.istd.gov.jo/AR/Pages/%D9%86%D8%B8%D8%A7%D9%85_%D8%A7%D9%84%D9%81%D9%88%D8%AA%D8%B1%D8%A9"
              className={styles.moreInfo}
              target="_blanc"
            >
              {t('login_page_more_info')}
            </a>
          </div>
          <p className={styles.errorMessage}>{errorValue}</p>
          <Button className={styles.button} loading={loading} type="submit">
            {t('login_page_sign_in')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(LoginForm);
