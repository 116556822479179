import React from 'react';

import { OTP_KEY } from 'constants/common';

import * as api from 'api/methods';

import { useUser } from 'context/user';

import { initialValues } from './FilterModal.formConfig';

import FilterModal from './FilterModal';
import { useTranslation } from 'react-i18next';

const FilterModalContainer = (props) => {
  const { user } = useUser();
  const { t } = useTranslation();

  const loadActivityOptions = () => {
    if (user.activitiesList.length) {
      return new Promise((resolve) => {
        const temp = user.activitiesList.map((item) => ({
          label: `${item.activity} (${item.description})`,
          value: item.activity,
        }));
        const options = [
          {
            label: t('unspecified'),
            value: null,
          },
          ...temp,
        ];
        resolve(options);
      });
    } else {
      return api.getAllActivities().then((data) => {
        const temp = data.map((item) => ({
          label: `${item.activity} (${item.description})`,
          value: item.activity,
        }));
        const options = [
          {
            label: t('unspecified'),
            value: null,
          },
          ...temp,
        ];
        return options;
      });
    }
  };

  const loadSubAdminsOptions = () => {
    const temp = api.getAllSubAdmins().then((data) =>
      data.map((item) => ({
        label: item.username,
        value: item.username,
      })),
    );
    const options = [
      {
        label: t('unspecified'),
        value: null,
      },
      ...temp,
    ];
    return options;
  };

  const handleResetClick = (resetForm) => {
    resetForm({ values: initialValues });
  };

  const showUsernameField =
    !!localStorage.getItem(OTP_KEY) && user?.role === 'ADMIN';

  return (
    <FilterModal
      {...props}
      showUsernameField={showUsernameField}
      loadActivityOptions={loadActivityOptions}
      loadSubAdminsOptions={loadSubAdminsOptions}
      onResetClick={handleResetClick}
    />
  );
};

export default React.memo(FilterModalContainer);
