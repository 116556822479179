import React from 'react';
import i18n from '../../../config/i18n';

export const parseItem = (item, i) => {
  switch (item.type) {
    case 'tel:' || 'mailto:': {
      if (typeof item.value === 'string') {
        return (
          <p key={i}>
            {item.manager && <span>{i18n.t('contact_us_page_manager')}: </span>}
            {item[i18n.language]}
            {' ('}
            {i18n.t('contact_us_page_phone')}:{' '}
            <a href={item.type + item.value}>{item.value}</a>
            {')'}
          </p>
        );
      } else {
        return (
          <p key={i}>
            {i18n.t(item.key)}:{' '}
            {item.value.map((ele) =>
              ele.length > 1 ? <a href={item.type + ele}>{ele}</a> : ` ${ele} `,
            )}
          </p>
        );
      }
    }
    case 'str': {
      return (
        <p key={i}>
          {i18n.t(item.key)}: {i18n.t(item.value)}
        </p>
      );
    }
    case 'link': {
      return (
        <p key={i}>
          {i18n.t(item.key)}{' '}
          <a href={item.value[i18n.language]}>{i18n.t('click_here')}</a>
        </p>
      );
    }
  }
};
