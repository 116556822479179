import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import UsersIcon from 'assets/img/user.svg';

import { Button } from 'components/shared/Button';
import { Table } from 'components/shared/Table';
import { Icon } from 'components/shared/Icon';
import { Switch } from 'components/shared/Switch';
import { Typography } from 'components/shared/Typography';

import { MainLayout } from 'components/MainLayout';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { Loading } from 'components/shared/Loading';

import { PasswordCell } from './components/PasswordCell';

import { USERS_COLUMNS } from './UsersPage.config';

import styles from './UsersPage.styles.scss';

const cellRenderer = (
  { className, item, itemIndex, key, minWidth, maxWidth },
  DefaultCell,
  { onUserEnabledChange, onEditSubAdminClick },
) => {
  switch (key) {
    case 'order': {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {itemIndex + 1}
        </DefaultCell>
      );
    }
    case 'action': {
      return (
        <div
          className={cn(styles.actionCell, className)}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <Icon
            className={styles.editIcon}
            name="edit"
            onClick={() => onEditSubAdminClick(item)}
          />
        </div>
      );
    }
    case 'activityNumber': {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {item[key]?.activity || '-'}
        </DefaultCell>
      );
    }
    case 'password': {
      return (
        <div
          className={cn(styles.passwordCell, className)}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <PasswordCell value={item[key]} />
        </div>
      );
    }
    case 'enabled': {
      return (
        <div
          className={cn(styles.actionCell, className)}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <Switch
            checked={item[key]}
            onChange={(event) => onUserEnabledChange(event, item.id)}
          />
        </div>
      );
    }
    default: {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {item[key] || '-'}
        </DefaultCell>
      );
    }
  }
};

const UsersPage = ({
  loading,
  subAdmins,
  onUserEnabledChange,
  onAddSubAdminClick,
  onEditSubAdminClick,
}) => {
  const { t } = useTranslation();

  return (
    <MainLayout loading={loading}>
      <div className={styles.page}>
        <div className={styles.header}>
          <Breadcrumbs
            routeName={t('users_bredcrumb_add_user')}
            routeIcon={UsersIcon}
          />
          <Button className={styles.button} onClick={onAddSubAdminClick}>
            {t('users_add_user')}
          </Button>
        </div>
        <div className={styles.inner}>
          {loading ? (
            <Loading className={styles.loading} />
          ) : subAdmins.length ? (
            <Table
              columns={USERS_COLUMNS(t)}
              data={subAdmins}
              cellRenderer={(props, DefaultCell) =>
                cellRenderer(props, DefaultCell, {
                  onUserEnabledChange,
                  onEditSubAdminClick,
                })
              }
            />
          ) : (
            <Typography className={styles.emptyText}>
              {t('users_no_subAdmin')}
            </Typography>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default React.memo(UsersPage);
