export const TOKEN_KEY = 'einv-token';
export const OTP_KEY = 'einv-otp';
export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL_SME = process.env.REACT_APP_API_URL_SME;
export const REFRESH_TOKEN = 'refresh-token';
export const SESSION_TIMEOUT = 'tokenExpiry';
export const EINVOICE_GOV_PAGE = 'https://etax.istd.gov.jo/Login.aspx';
// export const ReCAPTCHA_SITE_KEY = '6LfJgC4jAAAAAIy949y3GpkGhKqzO3_u3SFM8eGr'; //local_and_stg
// export const ReCAPTCHA_SITE_KEY = 'GLOMbiEJAAAAAPS-aM7ORXSDO-SKBd-GWYOBBUDY';
export const ReCAPTCHA_SITE_KEY = '6Le5QUsjAAAAAEs8qSFcyqgEmKnFI7T5arzpmrtV'; //V2

export const INVOICE_TYPES_ENUM = {
  CASH_INCOME: 'CASH_INCOME',
  CASH_GENERAL_TAX: 'CASH_GENERAL_TAX',
  CASH_SPECIAL_TAX: 'CASH_SPECIAL_TAX',
  RECEIVABLE_INCOME: 'RECEIVABLE_INCOME',
  RECEIVABLE_GENERAL_TAX: 'RECEIVABLE_GENERAL_TAX',
  RECEIVABLE_SPECIAL_TAX: 'RECEIVABLE_SPECIAL_TAX',
};
export const INVOICE_TYPES = [
  'CASH_INCOME',
  'CASH_GENERAL_TAX',
  'CASH_SPECIAL_TAX',
  'RECEIVABLE_INCOME',
  'RECEIVABLE_GENERAL_TAX',
  'RECEIVABLE_SPECIAL_TAX',
];
export const INVOICE_STATUSES = ['ACTIVE', 'CANCELED'];
export const INVOICE_NOTE_TYPES = ['CREDIT_INVOICE'];
