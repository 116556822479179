import React, { useState, useEffect } from 'react';

import Paginator from './Paginator';

const PaginatorContainer = ({ pageCount, getPageCount }) => {
  const [activePage, setActivePage] = useState(1);
  const pages = Array.from(Array(pageCount).keys());

  const handlePageClick = (page) => {
    setActivePage(page);
  };

  const handleNext = () => {
    setActivePage((current) => (current < pageCount ? current + 1 : current));
  };

  const handlePrevious = () => {
    setActivePage((current) => (current > 1 ? current - 1 : current));
  };

  useEffect(() => {
    getPageCount && getPageCount(activePage);
  }, [activePage]);

  return (
    <Paginator
      activePage={activePage}
      pages={pages}
      onPageClick={handlePageClick}
      onPrevious={handlePrevious}
      onNext={handleNext}
    />
  );
};

export default React.memo(PaginatorContainer);
