import React, { useEffect, useState } from 'react';
import _orderBy from 'lodash/orderBy';

import * as api from 'api/methods';
import { useUser } from 'context/user';

import { useLanguage } from 'hooks/useLanguage';
import { showToast } from 'helpers/showToast';

import {
  getInvoiceStatusTextByCode,
  getInvoiceNoteTypeTextByCode,
  getInvoiceTypeTextByCode,
} from './InvoicesPage.helpers';

import InvoicesPage from './InvoicesPage';

import { initialParams } from './InvoicesPage.config';
import { useTranslation } from 'react-i18next';

const InvoicesPageContainer = () => {
  const { user } = useUser();
  const language = useLanguage();
  const { t, i18n } = useTranslation();

  const [prevInvoices, setPrevInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [params, setParams] = useState(initialParams);
  const [downloading, setDownloading] = useState(false);
  const [downloadInvoicePdfLoading, setDownloadInvoicePdfLoading] =
    useState(false);

  const callApi = () => {
    api.getAllInvoices(params, user.activityNumber).then((resp) => {
      setLoading(false);
      setData(resp);
    });
  };

  useEffect(() => {
    setLoading(true);
    callApi(params);
  }, [params]);

  useEffect(() => {
    if (!prevInvoices?.length) {
      setPrevInvoices(data?.invoices);
    }
  }, [data.invoices]);

  const handleSearchQueryChange = (event) => {
    const query = event.target.value;

    if (!query) {
      setData((state) => ({ ...state, invoices: prevInvoices }));
      return;
    }

    setData((state) => ({
      ...state,
      invoices: prevInvoices.filter((invoice) =>
        [
          getInvoiceNoteTypeTextByCode({
            code: invoice.noteType,
            language,
          }) || '',
          invoice.activityNumber,
          invoice.invoiceNumber,
          getInvoiceTypeTextByCode({
            code: invoice.invoiceType,
            language,
          }),
          getInvoiceStatusTextByCode({
            code: invoice.invoiceStatus,
            language,
          }),
          invoice.issueDate,
          invoice.username,
          `${invoice.invoiceTotal}`,
        ].some(
          (fieldValue) =>
            fieldValue.toLowerCase().indexOf(query.toLowerCase()) > -1,
        ),
      ),
    }));
  };

  const setParamFromFilter = (key, val) => {
    switch (key) {
      case 'activityNumber': {
        key = 'activity';
        break;
      }
      case 'issueDateFrom': {
        key = 'dateFrom';
        break;
      }
      case 'issueDateTo': {
        key = 'dateTo';
        break;
      }
      case 'totalAmountFrom': {
        key = 'invoiceTotalFrom';
        break;
      }
      case 'totalAmountTo': {
        key = 'invoiceTotalTo';
        break;
      }
      default: {
        break;
      }
    }
    setParams((prev) => ({ ...prev, [key]: val }));
  };

  const handleSubmitFilters = (filtersData) => {
    if (!filtersData) {
      setData((state) => ({ ...state, invoices: prevInvoices }));
      return;
    }

    setParamFromFilter('noteType', filtersData.noteType?.value || null);

    setParamFromFilter('activity', filtersData.activityNumber?.value || null);

    setParamFromFilter('invoiceType', filtersData.invoiceType?.value || null);

    setParamFromFilter(
      'invoiceStatus',
      filtersData.invoiceStatus?.value || null,
    );

    if (filtersData.issueDateFrom) {
      setParamFromFilter('dateFrom', filtersData.issueDateFrom);
    }

    if (filtersData.issueDateTo) {
      setParamFromFilter('dateTo', filtersData.issueDateTo);
    }

    if (filtersData.totalAmountFrom) {
      setParamFromFilter('invoiceTotalFrom', filtersData.totalAmountFrom);
    }

    if (filtersData.totalAmountTo) {
      setParamFromFilter('invoiceTotalTo', filtersData.totalAmountTo);
    }
  };

  const handleDownloadItemClick = (item) => {
    setDownloadInvoicePdfLoading(true);
    api
      .getInvoicePDF({ invoiceId: item.id, invoiceNumber: item.invoiceNumber })
      .then((fileBlob) => {
        const url = window.URL.createObjectURL(fileBlob);

        const link = document.createElement('a');

        link.href = url;

        link.setAttribute('download', 'invoice.pdf');

        link.click();
        setDownloadInvoicePdfLoading(false);
      })
      .catch(() => {
        setDownloadInvoicePdfLoading(false);
      });
  };

  const invoicesToShow = _orderBy(data.invoices, ['invoiceNumber'], ['desc']);

  const getPageCount = (count) => {
    setParams((prev) => ({ ...prev, page: count }));
  };

  const handleResetFilters = () => {
    setParams(initialParams);
  };

  const handleRemoveFilterClick = (type) => {
    setParamFromFilter(type, null);
  };
  const hasAtLeastTwoNonNullValues = (obj = params) => {
    if (obj !== null && typeof obj === 'object') {
      // Count the non-null values
      const nonNullValuesCount = Object.values(obj).filter(
        (value) => value !== null,
      ).length;

      return nonNullValuesCount >= 2;
    }
    return false;
  };

  const onDownloadReportHandler = () => {
    if (!hasAtLeastTwoNonNullValues()) {
      showToast({
        data: {
          variant: 'error',
          text: t('please_select_filter'),
        },
      });
      return;
    }
    if (data.totalItems > 100000) {
      showToast({
        data: {
          variant: 'error',
          text: t('invoice_download_limit'),
        },
      });
      return;
    }

    setDownloading(true);
    api
      .getTaxpayerInvoicesReport(
        params,
        user.activityNumber,
        i18n.language.toUpperCase(),
      )
      .then((response) => {
        const href = URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'taxpayer-invoices.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setDownloading(false);
      })
      .catch((err) => {
        setDownloading(false);
        console.log({ err });
      });
  };

  return (
    <InvoicesPage
      loading={loading}
      downloading={downloading}
      invoices={invoicesToShow}
      generatedThisYear={data.generatedThisYear}
      generatedInvoices={data.generatedInvoices}
      generatedNotes={data.generatedNotes}
      totalItems={data.totalItems}
      onSearchQueryChange={handleSearchQueryChange}
      onSubmitFilters={handleSubmitFilters}
      onResetFilters={handleResetFilters}
      onDownloadItemClick={handleDownloadItemClick}
      getPageCount={getPageCount}
      totalPages={data.totalPages}
      totalOfTotals={data.totalOfTotals}
      onRemoveFilterClick={handleRemoveFilterClick}
      onDownloadReportHandler={onDownloadReportHandler}
      hasAtLeastTwoNonNullValues={hasAtLeastTwoNonNullValues}
      downloadInvoicePdfLoading={downloadInvoicePdfLoading}
    />
  );
};

export default React.memo(InvoicesPageContainer);
