import React, { useEffect, useState } from 'react';

import { OTP_KEY } from 'constants/common';

import * as api from 'api/methods';

import { useModal } from 'hooks/useModal';
import { useFetch } from 'hooks/useFetch';

import { UserModal } from './components/UserModal';
import { CheckOTPModal } from './components/CheckOTPModal';

import UsersPage from './UsersPage';

const UsersPageContainer = () => {
  const [triggerUsersRequest, setTriggerUsersRequest] = useState(null);

  const [userModalOpen, openUserModal, closeUserModal, userModalData] =
    useModal({
      defaultOpen: false,
    });
  const [
    checkOTPModalOpen,
    openCheckOTPModal,
    closeCheckOTPModal,
    checkOTPModalData,
  ] = useModal({
    defaultOpen: false,
  });

  const otpCode = localStorage.getItem(OTP_KEY);

  const {
    loading,
    data: subAdmins,
    setState: setSubAdminsState,
  } = useFetch(
    {
      defaultData: [],
      fetcher: api.getAllSubAdmins,
      immediately: false,
      // stopRequest: !otpCode,
    },
    {},
    [triggerUsersRequest],
  );

  useEffect(() => {
    if (!otpCode) {
      // openCheckOTPModal({ setTriggerUsersRequest });
    }
  }, []);

  const handleUserEnabledChange = (event, adminId) => {
    const checked = event.target.checked;

    if (!checked) {
      api
        .disableSubAdminById(adminId)
        .then(() => {
          setSubAdminsState((state) => ({
            ...state,
            data: state.data.map((subAdmin) => {
              if (subAdmin.id === adminId) {
                return {
                  ...subAdmin,
                  enabled: false,
                };
              }

              return subAdmin;
            }),
          }));
        })
        .catch((error) => {
          console.warn(error);
        });
    } else {
      api
        .enableSubAdminById(adminId)
        .then(() => {
          setSubAdminsState((state) => ({
            ...state,
            data: state.data.map((subAdmin) => {
              if (subAdmin.id === adminId) {
                return {
                  ...subAdmin,
                  enabled: true,
                };
              }

              return subAdmin;
            }),
          }));
        })
        .catch((error) => {
          console.warn(error);
        });
    }
  };

  const handleAddSubAdminClick = () => {
    openUserModal({ user: null, setTriggerUsersRequest });
  };

  const handleEditSubAdminClick = (subAdmin) => {
    openUserModal({ user: subAdmin, update: true, setTriggerUsersRequest });
  };

  return (
    <>
      <UsersPage
        loading={loading}
        subAdmins={subAdmins}
        onUserEnabledChange={handleUserEnabledChange}
        onAddSubAdminClick={handleAddSubAdminClick}
        onEditSubAdminClick={handleEditSubAdminClick}
      />
      <UserModal
        {...userModalData}
        open={userModalOpen}
        onClose={closeUserModal}
      />
      <CheckOTPModal
        {...checkOTPModalData}
        open={checkOTPModalOpen}
        onClose={closeCheckOTPModal}
      />
    </>
  );
};

export default React.memo(UsersPageContainer);
