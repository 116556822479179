import React from 'react';
import cn from 'classnames';

import styles from './FormGroup.styles.scss';

const FormGroup = ({ className, children }) => (
  <div className={cn(styles.group, className)}>{children}</div>
);

export default React.memo(FormGroup);
