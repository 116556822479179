import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './Link.styles.scss';

const TYPES = {
  default: styles.default,
};

const Link = ({ className, type, to, children, ...restProps }) => {
  if (to) {
    return (
      <NavLink
        {...restProps}
        to={to}
        className={cn(styles.link, TYPES[type], className)}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <Typography
      {...restProps}
      className={cn(styles.link, TYPES[type], className)}
      component="a"
    >
      {children}
    </Typography>
  );
};

Link.defaultProps = {
  type: 'default',
};

export default React.memo(Link);
