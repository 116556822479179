import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/shared/Typography';
import { AuthLayout } from 'components/AuthLayout';

import { ForgotPasswordForm } from './components/ForgotPasswordForm';
import { AddVatForm } from './components/AddVatForm/';

import styles from './ForgotPasswordPage.styles.scss';

const ForgotPasswordPage = () => {
  const [userData, setUserData] = useState({});
  const { t } = useTranslation();

  return (
    <AuthLayout isForgotPassword>
      <Typography weight="bold" className={styles.title}>
        {t('reset_password_title')}
      </Typography>
      {!userData.mobileNum ? (
        <AddVatForm setUserData={(value) => setUserData(value)} />
      ) : (
        <ForgotPasswordForm userData={userData} />
      )}
    </AuthLayout>
  );
};

export default React.memo(ForgotPasswordPage);
