import React from 'react';

import LogoUrl from 'assets/img/Arabic-Transparent.png';
import EnLogoUrl from 'assets/img/English-Transparent.png';

import { useLanguage } from 'hooks/useLanguage';

import { Icon } from 'components/shared/Icon';
import { Link } from 'components/shared/Link';

import { LangSelect } from 'components/LangSelect';

import { AccountMenu } from './components/AccountMenu';
import { MobileMenu } from './components/MobileMenu';

import { Typography } from 'components/shared/Typography';
import { useUser } from 'context/user';

import styles from './Header.styles.scss';

const Header = ({ menuOpen, onMenuIconClick }) => {
  const language = useLanguage();
  const { user } = useUser();
  const isAdmin = user?.role === 'ADMIN';

  return (
    <div className={styles.header}>
      {user && (
        <>
          <Icon
            name="menu"
            className={styles.menuIcon}
            onClick={onMenuIconClick}
          />
          <MobileMenu open={menuOpen} />
        </>
      )}
      {user && (
        <div className={styles.aside}>
          {' '}
          <AccountMenu />
          <LangSelect />
        </div>
      )}

      {!user && <LangSelect />}
      {user && (
        <div className={styles.userInfo}>
          {!isAdmin && (
            <div className="d-flex mx-2">
              <div className={styles.main}>
                <Typography className={styles.caption} weight="bold">
                  {user.activitiesList[0]?.description || user.name}
                </Typography>
              </div>
            </div>
          )}
          <div className="d-flex mx-2">
            <div className={styles.main}>
              <Typography className={styles.caption} weight="bold">
                {user?.name || ''}
              </Typography>
            </div>
          </div>

          <div className="d-flex mx-2">
            <div className={styles.main}>
              <Typography className={styles.caption} weight="bold">
                {user?.taxNumber || ''}
              </Typography>
            </div>
          </div>
        </div>
      )}

      <div className={styles.inner}>
        <Link className={styles.logoLink} to={`/${language}`}>
          {language === 'ar' ? (
            <img src={LogoUrl} alt="logo" width={'200'} />
          ) : (
            <img src={EnLogoUrl} alt="logo" width={'200'} />
          )}
        </Link>
      </div>
    </div>
  );
};

export default React.memo(Header);
