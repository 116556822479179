import React from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';

import { useLanguage } from 'hooks/useLanguage';

import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';
import { Link } from 'components/shared/Link';
import { Loading } from 'components/shared/Loading';

import { FormikField } from 'components/FormikField';
import { InputField, PasswordField } from 'components/FormikFields';

import { validationSchema, initialValues } from './RegisterForm.formConfig';

import styles from './RegisterForm.styles.scss';

const RegisterForm = ({ loading, taxNumberLoading, taxNumber, onSubmit }) => {
  const { t } = useTranslation();

  const language = useLanguage();

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={validationSchema(t)}
      initialValues={{ ...initialValues, taxNumber }}
      onSubmit={onSubmit}
    >
      {() => (
        <Form className={styles.form}>
          <div className={styles.taxNumber}>
            <FormikField
              name="taxNumber"
              component={InputField}
              componentProps={{ disabled: true }}
              label={t('register_page_tax_number')}
            />
            {taxNumberLoading && (
              <Loading className={styles.taxNumberLoading} />
            )}
          </div>

          <FormikField
            name="username"
            label={t('register_page_user_name')}
            component={InputField}
          />
          <FormikField
            name="password"
            label={t('register_page_password')}
            component={PasswordField}
          />

          <FormikField
            name="confirmPassword"
            label={t('register_page_confirm_password')}
            component={PasswordField}
          />
          <Button className={styles.button} loading={loading} type="submit">
            {t('register_page_title')}
          </Button>
          <div className={styles.info}>
            <Link className={styles.infoLink} to={`/${language}/login`}>
              {t('sign_in')}
            </Link>
            <Typography className={styles.infoText}>
              {t('register_page_have_account')}
            </Typography>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default React.memo(RegisterForm);
