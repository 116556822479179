import { initialValues } from './FilterModal.formConfig';

export const convertDataToForm = (data) => {
  if (!data || data.length === 0) {
    return initialValues;
  }

  const noteType = data.find((filter) => filter.type === 'noteType');
  const invoiceType = data.find((filter) => filter.type === 'invoiceType');
  const activityNumber = data.find(
    (filter) => filter.type === 'activityNumber',
  );
  const issueDateFrom = data.find((filter) => filter.type === 'issueDateFrom');
  const issueDateTo = data.find((filter) => filter.type === 'issueDateTo');
  const invoiceStatus = data.find((filter) => filter.type === 'invoiceStatus');
  const totalAmountFrom = data.find(
    (filter) => filter.type === 'totalAmountFrom',
  );
  const totalAmountTo = data.find((filter) => filter.type === 'totalAmountTo');
  const username = data.find((filter) => filter.type === 'username');

  return {
    noteType: noteType?.value || '',
    invoiceType: invoiceType?.value || '',
    activityNumber: activityNumber?.value || '',
    issueDateFrom: issueDateFrom?.value || '',
    issueDateTo: issueDateTo?.value || '',
    invoiceStatus: invoiceStatus?.value || '',
    totalAmountFrom: totalAmountFrom?.value || '',
    totalAmountTo: totalAmountTo?.value || '',
    username: username?.value || '',
  };
};
