import React from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';

import { FormikField } from 'components/FormikField';
import {
  InputField,
  PasswordField,
  SelectField,
} from 'components/FormikFields';

import { validationSchema, initialValues } from './UserModal.formConfig';

import { convertDataToForm } from './UserModal.helpers';

import styles from './UserModal.styles.scss';

import { useLanguage } from 'hooks/useLanguage';

const UserModal = ({
  loading,
  update,
  open,
  user,
  loadActivityOptions,
  onActivityNumberChange,
  onClose,
  onFormSubmit,
}) => {
  const { t } = useTranslation();
  const language = useLanguage();

  return (
    <Modal
      className={styles.modalWrapper}
      closeClassName={styles.modalCloseIcon}
      open={open}
      centered
      onClose={onClose}
    >
      <div className={styles.modal}>
        <Typography className={styles.title} weight="bold">
          {update ? t('usersmodal_edit_user') : t('usersmodal_add_user')}
        </Typography>
        <Formik
          validateOnMount
          enableReinitialize
          validationSchema={validationSchema(t)}
          initialValues={convertDataToForm(user) || initialValues}
          onSubmit={onFormSubmit}
        >
          {({ setFieldValue }) => (
            <Form className={styles.form}>
              {/* <div className={styles.formRow}> */}
              <div
                className={language === 'ar' ? 'row flex-row-reverse' : 'row'}
              >
                <div class="col-md-6 col-sm-12">
                  <FormikField
                    name="username"
                    component={InputField}
                    componentProps={{
                      disabled: update,
                      className: styles.field,
                    }}
                    label={t('usersmodal_user_name')}
                  />
                </div>
                <div class="col-md-6 col-sm-12">
                  <FormikField
                    name="password"
                    component={PasswordField}
                    componentProps={{
                      className: styles.field,
                      autoComplete: 'new-password',
                    }}
                    label={t('usersmodal_password')}
                  />
                </div>
              </div>

              {/* <div className={styles.formRow}> */}
              <div
                className={language === 'ar' ? 'row flex-row-reverse' : 'row'}
              >
                <div class="col-md-6 col-sm-12">
                  <FormikField
                    name="activityNumber"
                    component={SelectField}
                    componentProps={{
                      className: styles.field,
                      type: 'async',
                      cacheOptions: true,
                      defaultOptions: true,
                      loadOptions: loadActivityOptions,
                      onChange: (value) =>
                        onActivityNumberChange(value, setFieldValue),
                    }}
                    label={t('usersmodal_activity_number')}
                  />
                </div>
                <div class="col-md-6 col-sm-12">
                  <FormikField
                    name="notes"
                    component={InputField}
                    componentProps={{ disabled: true, className: styles.field }}
                    label={t('usersmodal_notes')}
                  />
                </div>
              </div>
              <div className={styles.actions}>
                <Button
                  className={styles.action}
                  variant="outlined"
                  onClick={onClose}
                >
                  {t('usersmodal_cancel')}
                </Button>
                <Button
                  className={styles.action}
                  loading={loading}
                  type="submit"
                >
                  {update ? t('usersmodal_edit') : t('usersmodal_add')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
export default React.memo(UserModal);
