export const contactInfo = [
  {
    manager: true,
    en: 'Ayman Alkhawaldeh',
    ar: 'ايمن الخوالدة',
    value: '0799055201',
    type: 'tel:',
  },
  {
    en: 'Khaldoun Abu-Yaman',
    ar: 'خلدون ابو يمن',
    value: '0799058372',
    type: 'tel:',
  },
  {
    en: 'Fadi Alhamad',
    ar: 'فادي الحمد',
    value: '0790122303',
    type: 'tel:',
  },
  {
    en: 'Mohammed Gharably',
    ar: 'محمد غرابلي',
    value: '0789187087',
    type: 'tel:',
  },
  {
    en: 'Belal Al-Hendi',
    ar: 'بلال الهندي',
    value: '0799069084',
    type: 'tel:',
  },
  {
    en: 'Omran Al-Azzazi',
    ar: 'عمران العزازي',
    value: '0795871614',
    type: 'tel:',
  },
  {
    en: 'Muhannad Al-Masri',
    ar: 'مهند المصري',
    value: ' 0795844748',
    type: 'tel:',
  },
];
