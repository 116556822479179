import React, { useState, useEffect } from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';
import { useNavigate } from 'react-router-dom';

import { OTP_KEY } from 'constants/common';

import * as api from 'api/methods';

import { CheckOTPModal } from '../CheckOTPModal';

import { useModal } from 'hooks/useModal';

import { showToast } from 'helpers/showToast';

import { useTranslation } from 'react-i18next';

const ForgotPasswordContainer = (props) => {
  const [username, setUsername] = useState('');
  const history = useNavigate();

  const { t } = useTranslation();

  const [
    checkOTPModalOpen,
    openCheckOTPModal,
    closeCheckOTPModal,
    checkOTPModalData,
  ] = useModal({
    defaultOpen: true,
  });

  const otpCode = localStorage.getItem(OTP_KEY);

  useEffect(() => {
    if (otpCode) {
      api
        .getUsername({ taxNumber: props.userData?.taxNumber }, otpCode)
        .then((resp) => {
          console.log('resp', resp);
          setUsername(resp);
          setTimeout(() => {
            setUsername(resp);
          }, 1000);
          // localStorage.removeItem(OTP_KEY);
        })
        .catch(() => {});
    }
  }, [otpCode]);

  console.log('username', username);

  const handleSubmit = (values) => {
    api
      .updatePassword(
        { taxNumber: props.userData?.taxNumber, password: values.password },
        otpCode,
      )
      .then(() => {
        showToast({
          data: {
            variant: 'success',
            text: t('reset_password_success'),
          },
        });
        history('/login');
      })
      .catch(() => {
        history('login');
      });
  };

  return (
    <>
      <ForgotPasswordForm username={username} onSubmit={handleSubmit} />
      <CheckOTPModal
        {...checkOTPModalData}
        open={checkOTPModalOpen}
        onClose={closeCheckOTPModal}
        {...props}
      />
    </>
  );
};

export default React.memo(ForgotPasswordContainer);
