import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';

import styles from 'assets/styles/invoice.scss';

import innerStyles from './DeleteInvoiceProductModal.styles.scss';

const DeleteInvoiceProductModal = ({
  open,
  loading,
  itemId,
  values,
  setFieldValue,
  onClose,
  onRemoveItemClick,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose}>
      <Typography className={innerStyles.title} weight="bold">
        {t('editInvoiceProduct_are_you_sure')}
      </Typography>

      <div className={innerStyles.actions}>
        <Button
          className={innerStyles.action}
          variant="outlined"
          onClick={onClose}
        >
          {t('usersmodal_cancel')}
        </Button>
        <Button
          className={innerStyles.action}
          loading={loading}
          onClick={() => {
            onRemoveItemClick(itemId, { values, setFieldValue });
            onClose();
          }}
        >
          {t('otpmodal_confirm')}
        </Button>
      </div>
    </Modal>
  );
};

export default React.memo(DeleteInvoiceProductModal);
