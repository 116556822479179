import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import DeviceIcon from 'assets/img/device.svg';

import { Button } from 'components/shared/Button';
import { Table } from 'components/shared/Table';
import { Loading } from 'components/shared/Loading';
import { Switch } from 'components/shared/Switch';
import { Typography } from 'components/shared/Typography';
import { Link } from 'components/shared/Link';

import { MainLayout } from 'components/MainLayout';
import { Breadcrumbs } from 'components/Breadcrumbs';

import { DEVICES_COLUMNS } from './DevicesPage.config';

import styles from './DevicesPage.styles.scss';

const cellRenderer = (
  { className, item, itemIndex, key, minWidth, maxWidth },
  DefaultCell,
  { onDeviceEnabledChange, t },
) => {
  switch (key) {
    case 'order': {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {itemIndex + 1}
        </DefaultCell>
      );
    }
    case 'secretKey':
    case 'clientId': {
      return (
        <div
          className={cn(styles.copyCell, className)}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <CopyToClipboard text={item[key]}>
            <Link className={styles.copyLink}>Copy</Link>
          </CopyToClipboard>
          <Typography>{item[key].substring(0, 10) || '-'}</Typography>
        </div>
      );
    }
    case 'enabled': {
      return (
        <div
          className={cn(styles.actionCell, className)}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          {/* {item[key] ? t('active') : t('inactive')} */}
          <Switch
            checked={item[key]}
            disabled={item[key]}
            onChange={(event) => onDeviceEnabledChange(event, item.id)}
          />
        </div>
      );
    }
    default: {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {item[key] || '-'}
        </DefaultCell>
      );
    }
  }
};

const DevicesPage = ({
  loading,
  devices,
  onDeviceEnabledChange,
  onAddDeviceClick,
}) => {
  const { t } = useTranslation();

  return (
    <MainLayout loading={loading}>
      <div className={styles.page}>
        <div className={styles.header}>
          <Breadcrumbs
            routeName={t('devices_connect_devices')}
            routeIcon={DeviceIcon}
          />
          <Button className={styles.button} onClick={onAddDeviceClick}>
            {t('devices_create')}
          </Button>
        </div>
        <div className={styles.inner}>
          {loading ? (
            <Loading className={styles.loading} />
          ) : devices.length ? (
            <Table
              columns={DEVICES_COLUMNS(t)}
              data={devices}
              cellRenderer={(props, DefaultCell) =>
                cellRenderer(props, DefaultCell, { onDeviceEnabledChange, t })
              }
            />
          ) : (
            <Typography className={styles.emptyText}>
              {t('devices_no_devices')}
            </Typography>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default React.memo(DevicesPage);
