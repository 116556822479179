import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useUser } from 'context/user';

import { Typography } from 'components/shared/Typography';

import styles from './MobileMenu.styles.scss';

const MobileMenu = ({ open, onItemClick }) => {
  const { t } = useTranslation();

  const { user } = useUser();

  return (
    <div className={cn(styles.menu, { [styles.menuOpen]: open })}>
      <div className={styles.header}>
        <Typography className={styles.text}>
          {`${user?.role === 'ADMIN' ? t('header_account_admin_text') : ''} ${t(
            'header_account_admin_caption',
          )}`}
        </Typography>
        <Typography className={styles.caption} weight="bold">
          {user?.username || ''}
        </Typography>
      </div>
      <div className={styles.header}>
        <Typography className={styles.text}>
          {t('header_account_tax_number')}
        </Typography>
        <Typography className={styles.caption} weight="bold">
          {user?.taxNumber || ''}
        </Typography>
      </div>
      <div className={styles.header}>
        <Typography className={styles.text}>
          {t('header_account_tax_payer_name')}
        </Typography>
        <Typography className={styles.caption} weight="bold">
          {user?.name || ''}
        </Typography>
      </div>
      {user?.role !== 'ADMIN' && (
        <div
          className={styles.menuItem}
          onClick={() => onItemClick('changePassword')}
        >
          <Typography style={styles.logouText}>
            {t('accountmenu_changePassword')}
          </Typography>
        </div>
      )}
      <div className={styles.logout} onClick={() => onItemClick('logout')}>
        <Typography style={styles.logouText}>
          {t('accountmenu_logout')}
        </Typography>
      </div>
    </div>
  );
};

export default React.memo(MobileMenu);
