import React from 'react';

import { Typography } from 'components/shared/Typography';
import { Table } from 'components/shared/Table';

import { INVOICE_COLUMNS } from '../../InvoicePage.config';

import styles from './ItemsInfo.styles.scss';

import { t } from 'i18next';
import { truncateToThreeDecimalPlaces } from '../../../../helpers/formatDicimalNumbers';

const cellRenderer = (
  { className, item, itemIndex, key, minWidth, maxWidth },
  DefaultCell,
) => {
  switch (key) {
    case 'generalTaxPercentage': {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {item.generalTaxType === 'EXEMPT'
            ? t('tax_rate_exempt')
            : `${item[key]} %`}
        </DefaultCell>
      );
    }
    case 'quantity':
    case 'specialTaxAmount':
    case 'discountAmount': {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {truncateToThreeDecimalPlaces(Number(item[key]))}
          {/* {Number(item[key]).toFixed(3)} */}
        </DefaultCell>
      );
    }
    case 'isic4': {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          <p dir="rtl" title={t(`isic4.isic4_${item?.isic4}`)}>
            {item?.isic4 ? t(`isic4.isic4_${item?.isic4}`) : '-'}
          </p>
        </DefaultCell>
      );
    }
    default: {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {truncateToThreeDecimalPlaces(item[key])}
        </DefaultCell>
      );
    }
  }
};

const ItemsInfo = ({ items, invoiceType }) => {
  return (
    <div className={styles.info}>
      {/* <Typography className={styles.title}>
        {t('view_invoice_Items_info')}
      </Typography> */}
      <Table
        className={styles.table}
        headerTextClassName={styles.tableHeaderText}
        columns={INVOICE_COLUMNS({ t, invoiceType })}
        data={items}
        cellRenderer={(props, DefaultCell) => cellRenderer(props, DefaultCell)}
      />
    </div>
  );
};

export default React.memo(ItemsInfo);
