import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { OTP_KEY } from 'constants/common';

import * as api from 'api/methods';

import { CODE_LENGTH } from './components/CodeInput/CodeInput.constants';

import CheckOTPModal from './CheckOTPModal';

const CheckOTPModalContainer = ({
  open,
  onClose,
  setTriggerUsersRequest,
  ...restProps
}) => {
  const history = useNavigate();

  const [loading, setLoading] = useState(true);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otpCode, setOtpCode] = useState('');
  const [otpCodeLength, setOtpCodeLength] = useState(CODE_LENGTH);
  const [isWrongOTP, setIsWrongOTP] = useState(false);

  useEffect(() => {
    if (open) {
      api
        .generateOTP()
        .then((data) => {
          setPhoneNumber(`+${data.mobile.replaceAll('*', 'X')}`);

          if (data.otp) {
            setOtpCodeLength(data.otp.length);
          }

          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [open]);

  const handleClose = () => {
    history('/');

    onClose();
  };

  const handleCodeChange = (value) => {
    setOtpCode(value);
  };

  const handleSubmitCodeClick = () => {
    setVerifyLoading(true);

    api
      .verifyOTP({ otp: otpCode })
      .then(() => {
        setVerifyLoading(false);

        localStorage.setItem(OTP_KEY, otpCode);

        setTriggerUsersRequest(new Date());

        onClose();
      })
      .catch(() => {
        setVerifyLoading(false);
        setIsWrongOTP(true);
      });
  };

  return (
    <CheckOTPModal
      {...restProps}
      open={open}
      loading={loading}
      otpError={isWrongOTP}
      verifyLoading={verifyLoading}
      phoneNumber={phoneNumber}
      otpCodeLength={otpCodeLength}
      onClose={handleClose}
      onCodeChange={handleCodeChange}
      onSubmitCodeClick={handleSubmitCodeClick}
    />
  );
};

export default React.memo(CheckOTPModalContainer);
