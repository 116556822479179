export const INVOICES_COLUMNS = (t) => [
  {
    key: 'action',
    label: t('invoices_columns_action'),
    minWidth: 100,
    maxWidth: 100,
  },
  // {
  //   key: 'invoiceStatus',
  //   label: t('invoices_column_status'),
  //   minWidth: 170,
  //   maxWidth: 170,
  // },
  {
    key: 'invoiceKind',
    label: t('Invoice_Kind'),
    minWidth: 110,
  },
  {
    key: 'currencyType',
    label: t('Currency_Type'),
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'invoiceTotal',
    label: t('invoices_column_value'),
    minWidth: 120,
    maxWidth: 120,
  },
  {
    key: 'issueDate',
    label: t('invoices_column_issueDate'),
    minWidth: 120,
    maxWidth: 120,
  },
  {
    key: 'invoiceNumber',
    label: t('invoices_column_invoiceNumber'),
    minWidth: 150,
  },
  {
    key: 'noteType',
    label: t('invoices_column_noteType'),
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: 'activityNumber',
    label: t('invoices_column_sequencetype'),
    minWidth: 120,
    // maxWidth: 120,
  },
  {
    key: 'username', // TODO: getUserInfo
    label: t('invoices_column_username'),
    minWidth: 100,
    maxWidth: 120,
  },
  {
    key: 'invoiceType',
    label: t('invoices_column_invoiceType'),
    minWidth: 115,
    maxWidth: 115,
  },
  { key: 'order', label: '#', minWidth: 40, maxWidth: 40 },
];

export const initialParams = {
  invoiceType: null,
  dateFrom: null,
  dateTo: null,
  page: 1,
  invoiceTotalFrom: null,
  invoiceTotalTo: null,
  noteType: null,
  activity: null,
  invoiceStatus: null,
};
