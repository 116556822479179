import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from 'context/user';
import { Breadcrumbs } from 'components/Breadcrumbs';
import PrivacyIcon from 'assets/img/privacyPolicy.svg';
import { MainLayout } from 'components/MainLayout';
import styles from './PrivacyPage.styles.scss';
import { Typography } from 'components/shared/Typography';

const PrivacyPage = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  return (
    <MainLayout>
      <div className={styles.page}>
        <div className={styles.header}>
          <Breadcrumbs
            routeName={t('footer_links_privacy')}
            routeIcon={PrivacyIcon}
            isLoggedIn={!!user}
          />
        </div>
        <div className={styles.container}>
          <Typography className={styles.title}>
            {t('footer_links_privacy')}
          </Typography>
          <div className={styles.links + ' ' + styles.inner}>
            <p>{t('privacy.template')}</p>
            <p>{t('privacy.sub_template')}</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default React.memo(PrivacyPage);
