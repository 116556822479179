import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'hooks/useLanguage';

import { Typography } from 'components/shared/Typography';

import { LangSelect } from 'components/LangSelect';
import { Footer } from 'components/Footer';
import authBgUrl from 'assets/img/auth-bg.png';

import LogoUrl from 'assets/img/Arabic-Transparent-login.png';
import EnLogoUrl from 'assets/img/English-Transparent-login.png';

import styles from './AuthLayout.styles.scss';

const AuthLayout = ({ children, isRegister, isForgotPassword }) => {
  const { t } = useTranslation();
  const language = useLanguage();

  return (
    <div className={styles.layout}>
      <div className={styles.line} />
      <div className={styles.inner}>
        <div className={styles.aside}>
          <img className={styles.bgImg} src={authBgUrl} />
          <LangSelect className={styles.langSelect} />
          <div className={styles.asideContent}>
            <Typography className={styles.asideTitle}>
              {t('welcome_in')}
            </Typography>
            <Typography className={styles.asideText}>
              {t('electronic_billing')}
            </Typography>
            <Typography className={styles.asideCaption}>
              {isRegister
                ? t('sign_up')
                : isForgotPassword
                ? t('reset_password')
                : t('sign_in')}
            </Typography>
          </div>
        </div>
        <div className={styles.main}>
          <a className={styles.logo}>
            {language === 'ar' ? (
              <img src={LogoUrl} alt="logo" width={'200'} />
            ) : (
              <img src={EnLogoUrl} alt="logo" width={'200'} />
            )}
          </a>
          <div className={styles.mainContent}>{children}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default React.memo(AuthLayout);
