import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './Checkbox.styles.scss';

const Checkbox = ({ className, disabled, label, ...restProps }) => (
  <div className={styles.checkboxWrapper}>
    {label && <Typography className={styles.label}>{label}</Typography>}
    <div
      className={cn(
        styles.checkbox,
        {
          [styles.checkboxDisabled]: disabled,
        },
        className,
      )}
    >
      <input type="checkbox" className={styles.field} {...restProps} />
      <div className={styles.iconWrapper}>
        <Icon name="check" className={styles.icon} />
      </div>
    </div>
  </div>
);

export default React.memo(Checkbox);
