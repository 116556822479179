import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import * as api from 'api/methods';

import { useFetch } from 'hooks/useFetch';
import { useLanguage } from 'hooks/useLanguage';

import InvoicePage from './InvoicePage';

import LogoUrl from 'assets/img/Arabic-Transparent.png';
import EnLogoUrl from 'assets/img/English-Transparent.png';

import { viewUserLogo } from 'helpers/viewUserLogo';
import styles from './InvoicePage.styles.scss';
import { useUser } from 'context/user';

const InvoicePageContainer = () => {
  const [userLogo, setUserLogo] = useState(null);
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [downloadInvoicePdfLoading, setDownloadInvoicePdfLoading] =
    useState(false);

  const { user } = useUser();

  const history = useNavigate();
  const language = useLanguage();

  const { invoiceId, invoiceNumber, invoiceCreationType } = useParams();

  const { loading, data: invoice } = useFetch(
    {
      defaultData: [],
      fetcher: api.getInvoiceById,
      immediately: true,
    },
    { invoiceId, invoiceNumber },
    [],
  );

  const handleExitClick = () => {
    history(`/${language}`);
  };

  const handlePrintClick = () => {
    setDownloadInvoicePdfLoading(true);
    api
      .getInvoicePDF({
        invoiceId: invoice.id,
        invoiceNumber: invoice.invoiceNumber,
      })
      .then((fileBlob) => {
        const url = window.URL.createObjectURL(fileBlob);

        const link = document.createElement('a');

        link.href = url;

        link.setAttribute('download', 'invoice.pdf');

        link.click();
        setDownloadInvoicePdfLoading(false);
      })
      .catch(() => {
        setDownloadInvoicePdfLoading(false);
      });
  };

  useEffect(() => {
    if (invoice?.activityDTO?.activity) {
      viewUserLogo({
        setImage: setUserLogo,
        setLoading: setLoadingLogo,
        setFieldValue: null,
        activityNumber: { value: invoice?.activityDTO?.activity },
      });
    }
    // viewUserLogo({ setLoading: setLoadingLogo, setImage: setUserLogo });
  }, [invoice]);

  const getUserLogo = () => {
    if (userLogo) {
      return (
        <img width={250} src={userLogo} className={styles.jofotaraImage} />
      );
    } else {
      return null;
    }
  };
  const getJofotaraImage = () => {
    return language === 'ar' ? (
      <img width={250} src={LogoUrl} className={styles.jofotaraImage} />
    ) : (
      <img width={250} src={EnLogoUrl} className={styles.jofotaraImage} />
    );
  };

  return (
    <InvoicePage
      loading={loading}
      invoice={invoice}
      invoiceCreationType={invoiceCreationType}
      onExitClick={handleExitClick}
      onPrintClick={handlePrintClick}
      logoImage={getUserLogo}
      getJofotaraImage={getJofotaraImage}
      loadingLogo={loadingLogo}
      downloadInvoicePdfLoading={downloadInvoicePdfLoading}
    />
  );
};

export default React.memo(InvoicePageContainer);
