import React from 'react';

import { Typography } from 'components/shared/Typography';
import { useTranslation } from 'react-i18next';
import styles from './MainInfo.styles.scss';

import { useLanguage } from 'hooks/useLanguage';

import {
  getCurrencyTypeLabel,
  getInvoiceKindLabel,
  getInvoiceNoteTypeTextByCode,
  getInvoiceTypeByCode,
} from './MainInfo.constants';
import { INVOICE_TYPES } from '../../../../constants/common';
import { useUser } from 'context/user';

const MainInfo = ({ invoice, invoiceCreationType }) => {
  const { t } = useTranslation();
  const language = useLanguage();
  const { user } = useUser();

  const showOriginalInvoice = () => {
    if (
      [INVOICE_TYPES[0], INVOICE_TYPES[1], INVOICE_TYPES[2]].includes(
        invoice.invoiceType,
      )
    ) {
      if (invoice.noteType) {
        return true;
      }
      return false;
    }
    if (invoice.noteType) {
      return true;
    }
    return false;
  };
  // invoice_page_tax_track_name
  return (
    <div className={styles.info}>
      <div className={styles.inner}>
        {/* <Typography weight="bold">{t('invoice_page_tax_number')}</Typography> */}
        <div className={styles.item}>
          <Typography className={styles.itemType}>
            <span>{t('invoice_page_tax_track_name')}: </span>
            <span>
              {invoice.activityDTO.description === '*'
                ? user.name
                : invoice.activityDTO.description}
            </span>
          </Typography>
          <Typography className={styles.itemType}>
            <span>{t('view_invoice_usersInfo_taxNumber')}: </span>
            <span>{invoice.seller.taxNumber}</span>
          </Typography>
          <Typography className={styles.itemType}>
            <span>{t('view_invoice_usersInfo_inputSourceSequence')}: </span>
            <span>{invoice.seller.activityNumber}</span>
          </Typography>
        </div>
        <div className={`${styles.item} ${styles.border}`}>
          <Typography className={styles.itemType}>
            <span>{t('view_invoice_mainInfo_serialNumber')}: </span>
            <span>{invoice.invoiceNumber}</span>
          </Typography>
          <Typography className={`${styles.itemType}`}>
            <span>{t('view_invoice_mainInfo_VendorInvoiceNumber')}: </span>
            {/* <span>{invoice.originalInvoiceNumber}</span> */}
            <span>{invoice.buyerInvoiceNumber}</span>
          </Typography>
          <Typography className={styles.itemType}>
            <span>{t('view_invoice_mainInfo_issue_date')}: </span>
            <span>{invoice.issueDate}</span>
          </Typography>
          <Typography className={styles.itemType}>
            <span>{t('Invoice_Kind')}: </span>
            <span>
              {getInvoiceKindLabel({ invoiceKind: invoice.invoiceKind, t })}
            </span>
          </Typography>
          <Typography className={styles.itemType}>
            <span>{t('Currency_Type')}: </span>
            <span>
              {getCurrencyTypeLabel({ currencyType: invoice.currencyType, t })}
            </span>
          </Typography>
          {showOriginalInvoice() && (
            <Typography className={`${styles.itemType} ${styles.redText}`}>
              <span>{t('view_invoice_original_invoice_number')}: </span>
              <span>{invoice.originalInvoiceNumber}</span>
            </Typography>
          )}
        </div>
      </div>
    </div>
    // <div className={styles.info}>
    //   {/* <div className={styles.qrCode} /> */}
    //   <img className={styles.qrCode} src={invoice.qrCodeImage} />
    //   <div className={styles.inner}>
    //     <Typography className={styles.title} weight="bold">
    //       {t('view_invoice_mainInfo_invoice')}
    //     </Typography>
    //     <div className={styles.main}>
    //       <div className={styles.itemsRow}>
    //         <div className={styles.item}>
    //           <Typography className={styles.itemType}>
    //             {t('view_invoice_mainInfo_invoice_type')}
    //           </Typography>
    //           <Typography className={styles.itemText}>
    //             {getInvoiceTypeByCode({
    //               code: invoice.invoiceTypeCode,
    //               language,
    //             })}
    //           </Typography>
    //         </div>
    //         {invoiceCreationType === 'edit' && (
    //           <div className={styles.item}>
    //             <Typography className={styles.itemType}>
    //               {t('view_invoice_mainInfo_noteType')}
    //             </Typography>
    //             <Typography className={styles.itemText}>
    //               {getInvoiceNoteTypeTextByCode({
    //                 code: invoice.noteType,
    //                 language,
    //               }) || ''}
    //             </Typography>
    //           </div>
    //         )}
    //         <div className={styles.item}>
    //           <Typography className={styles.itemType}>
    //             {t('view_invoice_mainInfo_issue_date')}
    //           </Typography>
    //           <Typography className={styles.itemText}>
    //             {invoice.issueDate}
    //           </Typography>
    //         </div>
    //         <div className={styles.item}>
    //           <Typography className={styles.itemType}>
    //             {t('view_invoice_mainInfo_serialNumber')}
    //           </Typography>
    //           <Typography className={styles.itemText}>
    //             {invoice.invoiceNumber}
    //           </Typography>
    //         </div>
    //         <div className={styles.item}>
    //           <Typography className={styles.itemType}>
    //             {t('view_invoice_mainInfo_VendorInvoiceNumber')}
    //           </Typography>
    //           <Typography className={styles.itemText}>
    //             {invoice.buyerInvoiceNumber}
    //           </Typography>
    //         </div>
    //       </div>
    //       {invoiceCreationType === 'edit' && (
    //         <div className={styles.itemsRow}>
    //           <div className={styles.item}>
    //             <Typography className={styles.itemType}>
    //               {t('view_invoice_mainInfo_reasonOfNote')}
    //             </Typography>
    //             <Typography className={styles.itemText}>
    //               {invoice.reasonOfNote || '-'}
    //             </Typography>
    //           </div>
    //           <div className={styles.item}>
    //             <Typography className={styles.itemType}>
    //               {t('view_invoice_mainInfo_Indicate_invoice_issued')}
    //             </Typography>
    //             <Typography className={styles.itemText}>
    //               {invoice.originalInvoiceNumber}
    //             </Typography>
    //           </div>
    //         </div>
    //       )}
    //       <div className={styles.itemsRow}>
    //         <div className={styles.item}>
    //           <Typography className={styles.itemType}>
    //             {t('view_invoice_mainInfo_invoiceUUID')}
    //           </Typography>
    //           <Typography className={styles.itemText}>{invoice.id}</Typography>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default React.memo(MainInfo);
