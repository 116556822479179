import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'hooks/useLanguage';

import { INVOICE_TYPES_ENUM } from 'constants/common';

import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import { InputField, SelectField } from 'components/FormikFields';

import styles from 'assets/styles/invoice.scss';

const InvoiceSellerSection = ({ user, invoiceType, loadActivityOptions }) => {
  const { t } = useTranslation();

  const language = useLanguage();

  return (
    <div className={styles.section}>
      <Typography weight="bold" className={styles.title}>
        {t('newInvoice_sellerData')}
      </Typography>
      <div className={language === 'ar' ? 'row flex-row-reverse' : 'row'}>
        <div class="col-md-4 col-sm-12">
          <FormikField
            name="seller.name"
            component={InputField}
            componentProps={{
              disabled: true,
            }}
            controlProps={{
              labelProps: {
                className: styles.fieldLabel,
              },
            }}
            label={t('newInvoice_name')}
          />
        </div>

        {invoiceType !== INVOICE_TYPES_ENUM.CASH_INCOME &&
        invoiceType !== INVOICE_TYPES_ENUM.RECEIVABLE_INCOME ? (
          <>
            {user.activityNumber ? (
              <div class="col-md-4 col-sm-12">
                <FormikField
                  name="seller.activityNumber"
                  component={InputField}
                  componentProps={{
                    disabled: true,
                  }}
                  controlProps={{
                    labelProps: {
                      className: styles.fieldLabel,
                    },
                  }}
                  label={t('newInvoice_sales_Input_source_sequence')}
                />
              </div>
            ) : (
              <div class="col-md-4 col-sm-12">
                <FormikField
                  name="seller.activityNumber"
                  component={SelectField}
                  componentProps={{
                    type: 'async',
                    cacheOptions: true,
                    defaultOptions: true,
                    loadOptions: loadActivityOptions,
                  }}
                  controlProps={{
                    labelProps: {
                      className: styles.fieldLabel,
                    },
                  }}
                  label={t('newInvoice_sales_Input_source_sequence')}
                />
              </div>
            )}
          </>
        ) : language === 'ar' ? null : null}

        {invoiceType === INVOICE_TYPES_ENUM.CASH_INCOME ||
        invoiceType === INVOICE_TYPES_ENUM.RECEIVABLE_INCOME ? (
          <div class="col-md-4 col-sm-12">
            <FormikField
              name="seller.country"
              component={InputField}
              componentProps={{
                disabled: true,
              }}
              controlProps={{
                labelProps: {
                  className: styles.fieldLabel,
                },
              }}
              label={t('newInvoice_Country')}
            />
          </div>
        ) : language === 'ar' ? null : null}
        <div class="col-md-4 col-sm-12">
          <FormikField
            name="seller.taxNumber"
            component={InputField}
            componentProps={{
              disabled: true,
            }}
            controlProps={{
              labelProps: {
                className: styles.fieldLabel,
              },
            }}
            label={t('newInvoice_taxNumber')}
          />
        </div>

        {invoiceType !== INVOICE_TYPES_ENUM.CASH_INCOME &&
        invoiceType !== INVOICE_TYPES_ENUM.RECEIVABLE_INCOME ? (
          <div class="col-md-4 col-sm-12">
            <FormikField
              name="seller.country"
              component={InputField}
              componentProps={{
                disabled: true,
              }}
              controlProps={{
                labelProps: {
                  className: styles.fieldLabel,
                },
              }}
              label={t('newInvoice_Country')}
            />
          </div>
        ) : language === 'ar' ? null : null}

        <div class="col-md-4 col-sm-12">
          <FormikField
            name="seller.postalCode"
            component={InputField}
            componentProps={{
              disabled: true,
            }}
            controlProps={{
              labelProps: {
                className: styles.fieldLabel,
              },
            }}
            label={t('newInvoice_postalCode')}
          />
        </div>
        <div class="col-md-4 col-sm-12">
          <FormikField
            name="seller.phoneNumber"
            component={InputField}
            componentProps={{
              disabled: true,
            }}
            controlProps={{
              labelProps: {
                className: styles.fieldLabel,
              },
            }}
            label={t('newInvoice_phone_number')}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(InvoiceSellerSection);
