export const DEVICES_COLUMNS = (t) => [
  {
    key: 'activityNumber',
    label: t('devices_columns_activityNumber'),
    minWidth: 148,
    maxWidth: 280,
  },
  {
    key: 'secretKey',
    label: t('devices_column_secretKey'),
    minWidth: 148,
    maxWidth: 345,
  },
  {
    key: 'clientId',
    label: t('devices_column_clientId'),
    minWidth: 148,
    maxWidth: 355,
  },
  {
    key: 'enabled',
    label: t('devices_column_enabled'),
    minWidth: 148,
    maxWidth: 340,
  },
  {
    key: 'name',
    label: t('devices_column_name'),
    minWidth: 148,
    maxWidth: 340,
  },
  {
    key: 'order',
    label: '#',
    minWidth: 64,
    maxWidth: 64,
  },
];
