export const USERS_COLUMNS = (t) => [
  {
    key: 'action',
    label: t('users_columns_action'),
    minWidth: 270,
    maxWidth: 270,
  },
  {
    key: 'notes',
    label: t('users_column_notes'),
    minWidth: 148,
    maxWidth: 344,
  },
  {
    key: 'activityNumber',
    label: t('users_columns_activityNumber'),
    minWidth: 148,
    maxWidth: 246,
  },
  // {
  //   key: 'password',
  //   label: t('users_column_password'),
  //   minWidth: 148,
  //   maxWidth: 217,
  // },
  {
    key: 'enabled',
    label: t('users_column_enabled'),
    minWidth: 148,
    maxWidth: 220,
  },
  {
    key: 'username',
    label: t('users_column_username'),
    minWidth: 188,
    maxWidth: 345,
  },
  {
    key: 'order',
    label: '#',
    minWidth: 64,
    maxWidth: 64,
  },
];
