import React from 'react';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './PasswordCell.styles.scss';

const PasswordCell = ({ visible, value, onIconClick }) => (
  <div className={styles.cell}>
    {!visible && (
      <Icon className={styles.icon} name="eye" onClick={onIconClick} />
    )}
    {visible && (
      <Icon className={styles.icon} name="crossEye" onClick={onIconClick} />
    )}
    {visible && <Typography className={styles.value}>{value}</Typography>}
    {!visible && (
      <div className={styles.dots}>
        {value.split('').map((_, index) => (
          <div key={index} className={styles.dot} />
        ))}
      </div>
    )}
  </div>
);

export default React.memo(PasswordCell);
