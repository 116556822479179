import React from 'react';
import { DayPicker } from 'react-day-picker';

import { Caption } from './components/Caption';

const DatePicker = ({ className, disabledDays, ...restProps }) => (
  <DayPicker
    {...restProps}
    mode="single"
    showOutsideDays
    disabled={disabledDays}
    components={{
      Caption,
    }}
  />
);

export default React.memo(DatePicker);
