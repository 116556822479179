import React from 'react';

import { useModal } from 'hooks/useModal';

import { EditInvoiceProductModal } from '../EditInvoiceProductModal';

import InvoiceProductsSection from './InvoiceProductsSection';

const InvoiceProductsSectionContainer = ({ invoiceType, ...restProps }) => {
  const [
    editInvoiceProductModalOpen,
    openEditInvoiceProductModal,
    closeEditInvoiceProductModal,
    editInvoiceProductModalData,
  ] = useModal({ defaultOpen: false });

  const handleEditItemClick = (
    { item, itemIndex },
    { values, setFieldValue },
  ) => {
    openEditInvoiceProductModal({
      item,
      itemIndex,
      invoiceType,
      parentValues: values,
      parentSetFieldValue: setFieldValue,
    });
  };

  return (
    <>
      <InvoiceProductsSection
        {...restProps}
        invoiceType={invoiceType}
        onEditItemClick={handleEditItemClick}
      />
      <EditInvoiceProductModal
        {...editInvoiceProductModalData}
        open={editInvoiceProductModalOpen}
        onClose={closeEditInvoiceProductModal}
      />
    </>
  );
};

export default React.memo(InvoiceProductsSectionContainer);
