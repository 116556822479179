import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from 'context/user';
import { TOKEN_KEY } from 'constants/common';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';
import { Popover } from 'components/shared/Popover';

import styles from './AccountMenu.styles.scss';

const AccountMenu = ({ onItemClick }) => {
  const { t } = useTranslation();

  const { user } = useUser();

  const onStorageUpdate = (e) => {
    const { key, newValue } = e;
    if (key === TOKEN_KEY && newValue) {
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener('storage', onStorageUpdate);
    return () => {
      window.removeEventListener('storage', onStorageUpdate);
    };
  }, []);

  return (
    <Popover
      content={
        <div className={styles.menu}>
          {user?.role !== 'ADMIN' && (
            <div
              className={styles.menuItem}
              onClick={() => onItemClick('changePassword')}
            >
              {t('accountmenu_changePassword')}
            </div>
          )}
          <div
            className={styles.menuItem}
            onClick={() => onItemClick('logout')}
          >
            {t('accountmenu_logout')}
          </div>
        </div>
      }
    >
      <div className={styles.inner}>
        <Icon className={styles.arrowIcon} name="angleDown" />
        <div className={styles.main}>
          <Typography className={styles.text}>
            {`${
              user?.role === 'ADMIN' ? t('header_account_admin_text') : ''
            } ${t('header_account_admin_caption')}`}
          </Typography>
          <Typography className={styles.caption} weight="bold">
            {user?.username || ''}
          </Typography>
        </div>
        <Icon className={styles.userIcon} name="user" />
      </div>
    </Popover>
  );
};

export default React.memo(AccountMenu);
