import React from 'react';
import cn from 'classnames';

import { OFFSET } from './Loading.constants';

import styles from './Loading.styles.scss';

const VARIANTS = {
  centered: styles.centered,
  centeredX: styles.centeredX,
};

const Loading = ({ className, innerClassName, variant, size }) => (
  <div
    className={cn(styles.loading, VARIANTS[variant], className)}
    style={{ width: size, height: size }}
  >
    <div
      className={cn(styles.inner, innerClassName)}
      style={{
        width: size - OFFSET * 2,
        height: size - OFFSET * 2,
        margin: OFFSET,
      }}
    />
    <div
      className={cn(styles.inner, innerClassName)}
      style={{
        width: size - OFFSET * 2,
        height: size - OFFSET * 2,
        margin: OFFSET,
      }}
    />
    <div
      className={cn(styles.inner, innerClassName)}
      style={{
        width: size - OFFSET * 2,
        height: size - OFFSET * 2,
        margin: OFFSET,
      }}
    />
    <div
      className={cn(styles.inner, innerClassName)}
      style={{
        width: size - OFFSET * 2,
        height: size - OFFSET * 2,
        margin: OFFSET,
      }}
    />
  </div>
);

Loading.defaultProps = {
  size: 20,
};

export default React.memo(Loading);
