import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { INVOICE_TYPES_ENUM } from 'constants/common';

import * as api from 'api/methods';

import { useUser } from 'context/user';

import { defaultItemData } from 'config/invoice/constants';

import {
  getProductWithTotals,
  convertFormDataToApi,
  getInvoiceType,
  validateItemFields,
} from 'config/invoice/helpers';

import { showToast } from 'helpers/showToast';

import { useLanguage } from 'hooks/useLanguage';

import NewInvoicePage from './NewInvoicePage';
import { TAX_RATE_OPTIONS } from '../../config/invoice/config';

const NewInvoicePageContainer = () => {
  const history = useNavigate();
  const params = useParams();
  const language = useLanguage();

  const { t } = useTranslation();

  const { user } = useUser();

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState('-');

  const invoiceType = getInvoiceType({
    generalType: params.invoiceGeneralType,
    user,
  });

  useEffect(() => {
    api
      .getNextInvoiceNumber()
      .then((data) => {
        setInvoiceNumber(data.invoiceNumber);

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const loadActivityOptions = () =>
    api.getAllActivities().then((data) =>
      data.map((item) => ({
        label: `${item.activity} (${item.description})`,
        value: item.activity,
      })),
    );

  const handleAddItemClick = async ({
    values,
    validateForm,
    setTouched,
    setErrors,
    resetForm,
  }) => {
    const haveError = await validateItemFields({
      t,
      item: values.tempItem,
      invoiceType,
      setTouched,
      setErrors,
      validateForm,
    });

    if (haveError) {
      return;
    }

    values.tempItem.quantity = Number(values.tempItem.quantity).toFixed(9);
    values.tempItem.specialTaxAmount = Number(
      values.tempItem.specialTaxAmount,
    ).toFixed(9);
    values.tempItem.subtotalAmount = Number(
      values.tempItem.subtotalAmount,
    ).toFixed(9);
    values.tempItem.unitPrice = Number(values.tempItem.unitPrice).toFixed(9);

    const currentItem = getProductWithTotals({
      item: values.tempItem,
      invoiceType,
    });

    currentItem.creating = false;

    if (values.invoiceKind && values.invoiceKind.value !== 'LOCAL') {
      resetForm({
        values: {
          ...values,
          items: [...values.items, currentItem],
          tempItem: {
            id: uuid(),
            ...defaultItemData(
              TAX_RATE_OPTIONS(t).find((item) => item.value === 'ZERO'),
            ),
          },
        },
      });
    } else {
      resetForm({
        values: {
          ...values,
          items: [...values.items, currentItem],
          tempItem: { id: uuid(), ...defaultItemData() },
        },
      });
    }
  };

  const handleRemoveItemClick = (itemId, { values, setFieldValue }) => {
    const nextItems = values.items.filter((item) => item.id !== itemId);

    setFieldValue('items', nextItems);
  };

  const handleCancelClick = () => {
    history(`/${language}`);
  };

  const handleSubmitFormClick = (values) => {
    if (!values.items.length) {
      showToast({
        data: {
          variant: 'error',
          text: t('create_invoice_validation_all_fields'),
        },
      });

      return;
    }

    const data = convertFormDataToApi({
      invoiceTypeCode: invoiceType,
      data: values,
      user,
    });

    setSubmitLoading(true);

    api
      .submitInvoice(data)
      .then((response) => {
        setSubmitLoading(false);
        showToast({
          data: {
            variant: 'success',
            text: t('invoice_has_been_sent'),
          },
        });

        history(
          `/${language}/invoices/${response.invoiceId}/${response.invoiceNumber}/new`,
        );
      })
      .catch(({ error }) => {
        showToast({
          data: {
            variant: 'error',
            text: error,
          },
        });

        setSubmitLoading(false);
      });
  };

  const isBuyerFieldsRequired =
    invoiceType === INVOICE_TYPES_ENUM.RECEIVABLE_INCOME ||
    invoiceType === INVOICE_TYPES_ENUM.RECEIVABLE_GENERAL_TAX ||
    invoiceType === INVOICE_TYPES_ENUM.RECEIVABLE_SPECIAL_TAX;

  return (
    <div>
      {submitLoading ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(255, 255, 255, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 999,
          }}
        >
          <div
            style={{
              border: '8px solid #f3f3f3',
              borderTop: '8px solid #3498db',
              borderRadius: '50%',
              width: '50px',
              height: '50px',
              animation: 'spin 1s linear infinite',
            }}
          />
        </div>
      ) : (
        <NewInvoicePage
          loading={loading}
          submitLoading={submitLoading}
          isBuyerFieldsRequired={isBuyerFieldsRequired}
          user={user}
          invoiceNumber={invoiceNumber}
          invoiceType={invoiceType}
          loadActivityOptions={loadActivityOptions}
          onAddItemClick={handleAddItemClick}
          onRemoveItemClick={handleRemoveItemClick}
          onCancelClick={handleCancelClick}
          onSubmitFormClick={handleSubmitFormClick}
        />
      )}
    </div>
  );
};

export default React.memo(NewInvoicePageContainer);
