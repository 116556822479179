import * as Yup from 'yup';

import { PASSWORD_REGEX, USERNAME_REGEX } from 'constants/validation';

export const validationSchema = (t) =>
  Yup.object().shape({
    username: Yup.string()
      .required(t('user_modal_validation_username'))
      .matches(USERNAME_REGEX, t('username_validation')),
    password: Yup.string()
      .required(t('user_modal_validation_password'))
      .matches(PASSWORD_REGEX, t('password_validation')),
    notes: Yup.string().ensure(),
    activityNumber: Yup.object().required(
      t('user_modal_validation_activity_number'),
    ),
  });

export const initialValues = {
  username: '',
  password: '',
  notes: '',
  activityNumber: '',
};

export default {
  initialValues,
  validationSchema,
};
