import React from 'react';
import styles from './ContactUs.styles.scss';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { MainLayout } from 'components/MainLayout';
import NewInvoiceIcon from 'assets/img/contact_us.svg';
import { useTranslation } from 'react-i18next';
import { Typography } from 'components/shared/Typography';
import { parseItem } from './config/helpers';
import { contactInfo } from './config/config';
import { useUser } from 'context/user';

const ContactUs = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  return (
    <MainLayout>
      <div className={styles.page}>
        <div className={styles.header}>
          <Breadcrumbs
            routeName={t('footer_links_contact_us')}
            routeIcon={NewInvoiceIcon}
            isLoggedIn={!!user}
          />
        </div>
        <div className={styles.container}>
          <Typography className={styles.title}>
            {t('contact_us_page_contact_info')}
          </Typography>
          <div className={styles.links + ' ' + styles.inner}>
            {contactInfo.map((item, i) => parseItem(item, i))}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default React.memo(ContactUs);
