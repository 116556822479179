import React from 'react';
import MaskedInput from 'react-text-mask';

import { DATE_PIPE, MASK } from './DatePickerInput.constants';

import { Input } from 'components/shared/Input';
import { Icon } from 'components/shared/Icon';
import { Popover } from 'components/shared/Popover';
import { DatePicker } from 'components/shared/DatePicker';

import styles from './DatePickerInput.styles.scss';

const DatePickerInput = ({
  visible,
  inputRef,
  disabledDays,
  value,
  onTriggerPickerClick,
  onOutsidePickerClick,
  onDayClick,
  ...restProps
}) => (
  <MaskedInput
    {...restProps}
    keepCharPositions
    pipe={DATE_PIPE}
    mask={MASK}
    guide={false}
    placeholderChar=" "
    value={value}
    render={(ref, props) => (
      <Input
        {...props}
        readOnly
        inputRef={(element) => {
          ref(element);

          if (inputRef) {
            inputRef.current = element;
          }
        }}
      />
    )}
    startAdornment={
      <Popover
        mode="controlled"
        visible={visible}
        interactive
        appendTo={() => document.body}
        content={
          <div>
            <DatePicker disabledDays={disabledDays} onDayClick={onDayClick} />
          </div>
        }
        onOutsideClick={onOutsidePickerClick}
        onTriggerClick={onTriggerPickerClick}
      >
        {({ onTriggerClick }) => (
          <span onClick={onTriggerClick}>
            <Icon className={styles.icon} name="calendar" />
          </span>
        )}
      </Popover>
    }
  />
);

export default React.memo(DatePickerInput);
