import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';
import { Loading } from 'components/shared/Loading';

import { Cell } from './components/Cell';

import cellStyles from './components/Cell/Cell.styles.scss';

import styles from './Table.styles.scss';
import { Paginator } from '../Paginator';

const Table = ({
  className,
  headerTextClassName,
  cellClassName,
  loading,
  data,
  columns,
  cellRenderer,
  onRowClick,
  withPagination,
  getPageCount,
  totalPages,
  fitContent,
}) => (
  <div className={styles.tableWrapper}>
    <div className={cn(styles.table, className)}>
      <div className={styles.header}>
        {columns.map((column) => (
          <div
            key={column.key}
            className={cn(cellStyles.cell, styles.headerCell)}
            style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
          >
            <Typography
              className={cn(styles.headerText, headerTextClassName)}
              weight="bold"
            >
              {column.label}
            </Typography>
          </div>
        ))}
      </div>
      {loading ? (
        <Loading variant={'centeredX'} />
      ) : (
        <div
          className={styles.body + ' ' + (fitContent ? styles.fitContent : '')}
        >
          {data.map((item, itemIndex) => (
            <div
              key={item.id}
              className={`${styles.row}  ${item.updated && styles.updatedRow} ${
                item.deleted && styles.deletedRow
              }`}
              onClick={() => onRowClick(item)}
            >
              {columns.map((column, index) =>
                cellRenderer ? (
                  cellRenderer(
                    {
                      ...column,
                      itemIndex,
                      item,
                      className: cn(cellStyles.cell, cellClassName),
                    },
                    Cell,
                  )
                ) : (
                  <Cell
                    minWidth={column.minWidth}
                    maxWidth={column.maxWidth}
                    key={column.key}
                  >
                    {item[column.key]}
                  </Cell>
                ),
              )}
            </div>
          ))}
        </div>
      )}
      {withPagination && (
        <Paginator getPageCount={getPageCount} pageCount={totalPages} />
      )}
    </div>
  </div>
);

Table.defaultProps = {
  onRowClick: () => {},
};

export default React.memo(Table);
