import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useLanguage } from 'hooks/useLanguage';

import Breadcrumbs from './Breadcrumbs';

const BreadcrumbsContainer = (props) => {
  const history = useNavigate();
  const language = useLanguage();

  const handleHomeClick = () => {
    history(`/${language}`);
  };

  return <Breadcrumbs {...props} onHomeClick={handleHomeClick} />;
};

export default React.memo(BreadcrumbsContainer);
