import React, { useEffect } from 'react';
import UploadLogo from './UploadLogo';
import { useTranslation } from 'react-i18next';
import { viewUserLogo } from '../../../../helpers/viewUserLogo';
import { allowedImgFormats, fileSizeLimit } from './UploadLogo.config';
import { showToast } from 'helpers/showToast';

const UploadLogoContainer = ({
  setUploadStyle,
  setFieldValue,
  loading,
  setLoading,
  values,
  setPreviewImage,
  previewImage,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    viewUserLogo({
      setImage: setPreviewImage,
      setLoading,
      setFieldValue,
      activityNumber: values.activityNumber,
    });
  }, []);

  useEffect(() => {
    setUploadStyle(loading);
  }, [loading]);

  const handleUpload = (e) => {
    setLoading(true);
    const file = e.files[0];

    const isAllowedType = allowedImgFormats.values.indexOf(file.type) !== -1;
    const isAllowedSize = file.size < fileSizeLimit;

    // Validate dimensions (max 300x300 pixels)
    const img = new Image();
    img.onload = function () {
      if (img.width > 300 || img.height > 300) {
        showToast({
          data: {
            variant: 'error',
            text: t('user_logo_dimension_allowed'),
          },
        });
        setLoading(false);
      } else {
        if (
          isAllowedType &&
          isAllowedSize //file size in bytes
        ) {
          // const data = new window.FormData();
          // data.append('file', file);
          setFieldValue('invoiceLogo', file);
          setPreviewImage(URL.createObjectURL(file));
          setLoading(false);
          // uploadUserLogo(data).then(async () => {
          //   await viewUserLogo({ setImage: setPreviewImage, setLoading });

          //   showToast({
          //     data: {
          //       variant: 'success',
          //       text: t('user_logo_logo_uploaded_successfully'),
          //     },
          //   });
          //   e.options.clear();
          // });
        } else {
          if (!isAllowedSize) {
            showToast({
              data: {
                variant: 'error',
                text: t('user_logo_size_allowed', {
                  fileSizeLimit: fileSizeLimit / 1000000,
                }),
              },
            });
          }
          if (!isAllowedType) {
            showToast({
              data: {
                variant: 'error',
                text: t('user_logo_type_allowed', {
                  allowedImgFormats: allowedImgFormats.labels.join(', '),
                }),
              },
            });
          }
          setLoading(false);
          e.options.clear();
          return;
        }
      }
    };
    img.src = URL.createObjectURL(file);
  };

  return (
    <UploadLogo
      t={t}
      previewImage={previewImage}
      loading={loading}
      uploadHandler={handleUpload}
    />
  );
};

export default React.memo(UploadLogoContainer);
