import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { OTP_KEY } from 'constants/common';

import * as api from 'api/methods';

import { CODE_LENGTH } from './components/CodeInput/CodeInput.constants';

import CheckOTPModal from './CheckOTPModal';

const CheckOTPModalContainer = ({
  open,
  onClose,
  setTriggerUsersRequest,
  userData,
  ...restProps
}) => {
  const history = useNavigate();

  const [verifyLoading, setVerifyLoading] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [isWrongOTP, setIsWrongOTP] = useState(false);

  const otpCodeLength = CODE_LENGTH;

  const handleClose = () => {
    history('/');
    onClose();
  };

  const handleCodeChange = (value) => {
    setOtpCode(value);
  };

  const handleSubmitCodeClick = () => {
    setVerifyLoading(true);

    api
      .verifyForgetPasswordOTP({
        taxNumber: userData.taxNumber,
        otp: otpCode,
      })
      .then(() => {
        setVerifyLoading(false);
        localStorage.setItem(OTP_KEY, otpCode);
        onClose();
      })
      .catch(() => {
        setVerifyLoading(false);
        setIsWrongOTP(true);
      });
  };

  return (
    <CheckOTPModal
      {...restProps}
      open={open}
      otpError={isWrongOTP}
      verifyLoading={verifyLoading}
      otpCodeLength={otpCodeLength}
      onClose={handleClose}
      onCodeChange={handleCodeChange}
      onSubmitCodeClick={handleSubmitCodeClick}
      userData={userData}
    />
  );
};

export default React.memo(CheckOTPModalContainer);
