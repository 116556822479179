import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './Toast.styles.scss';

const VARIANTS = {
  success: styles.success,
  warning: styles.warning,
  info: styles.info,
  error: styles.error,
};

const Toast = ({ className, variant, title, text }) => (
  <div className={cn(styles.toast, VARIANTS[variant], className)}>
    <div className={styles.inner}>
      <div className={styles.main}>
        {title && (
          <Typography variant="body2" weight="medium" className={styles.title}>
            {title}
          </Typography>
        )}
        <Typography className={styles.text} variant="body3">
          {text}
        </Typography>
      </div>
    </div>
  </div>
);

export default React.memo(Toast);
