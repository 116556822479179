import React from 'react';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';
import styles from './Breadcrumbs.styles.scss';
import { useTranslation } from 'react-i18next';

const Breadcrumbs = ({
  routeName,
  routeIcon: RouteIcon,
  onHomeClick,
  isLoggedIn,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.breadcrumbs}>
      <div className={styles.route}>
        <Typography className={styles.routeText} weight="bold">
          {routeName}
        </Typography>
        <RouteIcon className={styles.routeIcon} />
      </div>
      <Icon className={styles.separatorIcon} name="angleLeft" />
      <div className={styles.homeRoute} onClick={onHomeClick}>
        <Typography className={styles.homeRouteText}>
          {isLoggedIn ? t('breadcrumb_home_screen') : t('login_page_sign_in')}
        </Typography>
        <Icon className={styles.homeRouteIcon} name="arrowRight" />
      </div>
    </div>
  );
};
export default React.memo(Breadcrumbs);
