import React from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/shared/Button';
import { FormikField } from 'components/FormikField';
import { PasswordField } from 'components/FormikFields';

import {
  validationSchema,
  initialValues,
} from './ChangePasswordForm.formConfig';

import styles from './ChangePasswordForm.styles.scss';

const ChangePasswordForm = ({ loading, onSubmit, errorValue }) => {
  const { t } = useTranslation();
  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={validationSchema(t)}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {() => (
        <Form className={styles.form}>
          <FormikField
            name="currentPassword"
            component={PasswordField}
            label={t('changePassword_currentPassword')}
          />
          <FormikField
            name="newPassword"
            component={PasswordField}
            label={t('changePassword_password')}
          />
          <FormikField
            name="confirmPassword"
            component={PasswordField}
            label={t('changePassword_confirm_password')}
          />
          <p className={styles.errorMessage}>{errorValue}</p>
          <Button className={styles.button} loading={loading} type="submit">
            {t('changePassword_changePassword')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(ChangePasswordForm);
