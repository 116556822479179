import React, { useState } from 'react';
import format from 'date-fns/format';

import DatePickerInput from './DatePickerInput';

const DatePickerInputContainer = ({ onChange, ...restProps }) => {
  const [visible, setVisible] = useState(false);

  const handleChange = (event) => {
    const value = event.target.value;

    if (onChange) {
      onChange(value);
    }
  };

  const handleTriggerPickerClick = () => {
    setVisible(true);
  };

  const handleOutsidePickerClick = () => {
    setVisible(false);
  };

  const handleDayClick = (nextDate) => {
    if (onChange) {
      onChange(format(nextDate, 'dd/MM/yyyy'));
    }

    setVisible(false);
  };

  return (
    <DatePickerInput
      {...restProps}
      visible={visible}
      onTriggerPickerClick={handleTriggerPickerClick}
      onOutsidePickerClick={handleOutsidePickerClick}
      onChange={handleChange}
      onDayClick={handleDayClick}
    />
  );
};

export default React.memo(DatePickerInputContainer);
