import React, { useState } from 'react';

import Header from './Header';

const HeaderContainer = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuIconClick = () => {
    setMenuOpen(!menuOpen);
  };

  return <Header menuOpen={menuOpen} onMenuIconClick={handleMenuIconClick} />;
};

export default React.memo(HeaderContainer);
