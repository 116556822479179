import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useUser } from 'context/user';

import { useModal } from 'hooks/useModal';
import { useLanguage } from 'hooks/useLanguage';

import { CreateInvoiceModal } from './components/CreateInvoiceModal';
import { SettingsModal } from './components/SettingsModal';

import HomePage from './HomePage';

const HomePageContainer = () => {
  const history = useNavigate();
  const language = useLanguage();

  const { user } = useUser();

  const isAdmin = user?.role === 'ADMIN';
  const isDeviceInUse = user?.deviceInUse;

  const [
    createInvoiceModalOpen,
    openCreateInvoiceModal,
    closeCreateInvoiceModal,
  ] = useModal({ defaultOpen: false });

  const [settingsModalOpen, openSettingsModal, closeSettingsModal] = useModal({
    defaultOpen: false,
  });

  const handleItemClick = (key) => {
    switch (key) {
      case 'devices': {
        history(`/${language}/devices`);

        break;
      }
      case 'invoices': {
        history(`/${language}/invoices`);

        break;
      }
      case 'createInvoice': {
        openCreateInvoiceModal();

        break;
      }
      case 'settings': {
        openSettingsModal();

        break;
      }
      case 'users': {
        history(`/${language}/users`);

        break;
      }
    }
  };

  return (
    <>
      <HomePage
        isAdmin={isAdmin}
        isDeviceInUse={isDeviceInUse}
        user={user}
        onItemClick={handleItemClick}
      />
      <CreateInvoiceModal
        open={createInvoiceModalOpen}
        onClose={closeCreateInvoiceModal}
      />
      <SettingsModal open={settingsModalOpen} onClose={closeSettingsModal} />
    </>
  );
};

export default React.memo(HomePageContainer);
