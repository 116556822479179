import {
  Currencies,
  CurrenciesFullName,
  Invoice_Kind,
} from '../../../../config/invoice/config';

export const getInvoiceNoteTypeTextByCode = ({ code, language }) => {
  switch (code) {
    case 'DEBIT_INVOICE': {
      return language === 'en' ? 'Debit Invoice' : 'مدين';
    }
    case 'CREDIT_INVOICE': {
      return language === 'en' ? 'Credit Invoice' : 'دائن';
    }
  }
};

export const getInvoiceTypeByCode = ({ code, language }) => {
  if (code.indexOf('CASH') !== -1) {
    return language === 'en' ? 'Cash Invoice' : 'فاتورة نقدية';
  } else {
    return language === 'en' ? 'Receivable Invoice' : 'فاتورة ذمم';
  }
};
export const getInvoiceKindLabel = ({ invoiceKind, t }) => {
  if (invoiceKind) {
    return Invoice_Kind(t).find((item) => item.value === invoiceKind)?.label;
  }
  return Invoice_Kind(t).find((item) => item.value === 'LOCAL').label;
};
export const getCurrencyTypeLabel = ({ currencyType, t }) => {
  const currencies = Currencies(t).map((item) => ({
    value: item.value,
    // label: `${item.label}`,
    label: `${CurrenciesFullName(t)[item.value]} (${item.label})`,
  }));
  if (currencyType) {
    return currencies.find((item) => item.value === currencyType).label;
  }
  return currencies.find((item) => item.value === 'JOD').label;
};
