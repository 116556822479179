export const discountMaxValidation = ({
  t,
  unitPrice,
  quantity,
  discountAmountSchema,
}) => {
  if (!unitPrice || !quantity) {
    return discountAmountSchema;
  }

  return discountAmountSchema.max(
    +unitPrice * +quantity,
    t('create_invoice_validation_discount_allownece_value'),
  );
};

export const additionalBuyerIdValidation = ({
  t,
  additionalBuyerIdType,
  additionalBuyerIdSchema,
}) => {
  if (additionalBuyerIdType?.value === 'NATIONAL_ID_NUMBER') {
    return additionalBuyerIdSchema.matches(
      /^\d{10}$/,
      t('create_invoice_validation_national_id_allownece'),
    );
  }
  if (additionalBuyerIdType?.value === 'PERSONAL_NUMBER') {
    return additionalBuyerIdSchema.matches(
      /^\d{10}$/,
      t('create_invoice_validation_personal_number_allownece'),
    );
  }
  if (additionalBuyerIdType?.value === 'TAXPAYER_NUMBERS') {
    return additionalBuyerIdSchema.matches(
      /^\d{2,11}$/,
      t('create_invoice_validation_taxpayer_number_allownece'),
    );
  }
  if (additionalBuyerIdType?.value === 'SERIAL_INCOME_NUMBER') {
    return additionalBuyerIdSchema.matches(
      /^\d{7}$/,
      t('create_invoice_validation_income_serial_number_allownece'),
    );
  }
  // if (additionalBuyerIdType?.value === 'NATIONAL_ID_NUMBER') {
  //   return additionalBuyerIdSchema.test(
  //     'is-national-id',
  //     t('create_invoice_validation_national_id_allownece'),
  //     (value) => {
  //       return value === '' || /^\d{10}$/.test(value);
  //     },
  //   );
  // }
  // if (additionalBuyerIdType?.value === 'PERSONAL_NUMBER') {
  //   return additionalBuyerIdSchema.test(
  //     'is-personal-number',
  //     t('create_invoice_validation_personal_number_allownece'),
  //     (value) => {
  //       return value === '' || /^\d{10}$/.test(value);
  //     },
  //   );
  // }
  // if (additionalBuyerIdType?.value === 'TAXPAYER_NUMBERS') {
  //   return additionalBuyerIdSchema.test(
  //     'is-taxpayer-number',
  //     t('create_invoice_validation_taxpayer_number_allownece'),
  //     (value) => {
  //       return value === '' || /^\d{2,11}$/.test(value);
  //     },
  //   );
  // }
  // if (additionalBuyerIdType?.value === 'SERIAL_INCOME_NUMBER') {
  //   return additionalBuyerIdSchema.test(
  //     'is-serial-income-number',
  //     t('create_invoice_validation_income_serial_number_allownece'),
  //     (value) => {
  //       return value === '' || /^\d{7}$/.test(value);
  //     },
  //   );
  // }

  return additionalBuyerIdSchema;
};
