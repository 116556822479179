export const getInvoiceStatusTextByCode = ({ code, language }) => {
  switch (code) {
    case 'ACTIVE': {
      return language === 'en' ? 'Approved' : 'موافق عليه';
    }
    case 'CANCELED': {
      return language === 'en' ? 'Canceled' : 'ملغي';
    }
  }
};

export const getInvoiceNoteTypeTextByCode = ({ code, language }) => {
  switch (code) {
    case 'DEBIT_INVOICE': {
      return language === 'en' ? 'Debit Invoice' : 'مدين';
    }
    case 'CREDIT_INVOICE': {
      return language === 'en' ? 'Credit Invoice' : 'دائن';
    }
  }
};
export const getInvoiceKindTextByCode = ({ code, language }) => {
  switch (code) {
    case 'LOCAL': {
      return language === 'en' ? 'Local Invoice' : 'فاتورة محلية';
    }
    case 'EXPORT': {
      return language === 'en' ? 'Export Invoice' : 'فاتورة تصدير';
    }
    case 'DEVELOPMENTAL': {
      return language === 'en'
        ? 'Developmental Invoice'
        : 'فاتورة مناطق تنموية / تشجيع استثمار';
    }
  }
};

export const getInvoiceTypeTextByCode = ({ code, language }) => {
  switch (code) {
    case 'CASH_INCOME':
    case 'CASH_GENERAL_TAX':
    case 'CASH_SPECIAL_TAX': {
      return language === 'en' ? 'Cash Invoice' : 'فاتورة نقدية';
    }
    case 'RECEIVABLE_INCOME':
    case 'RECEIVABLE_GENERAL_TAX':
    case 'RECEIVABLE_SPECIAL_TAX': {
      return language === 'en' ? 'Receivable Invoice' : 'فاتورة ذمم';
    }
  }
};
