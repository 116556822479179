import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './Cell.styles.scss';

const Cell = ({ children, className, minWidth, maxWidth, ...restProps }) => (
  <div
    className={cn(styles.cell, className)}
    style={{ minWidth, maxWidth }}
    {...restProps}
  >
    <Typography className={styles.text}>{children}</Typography>
  </div>
);

export default React.memo(Cell);
