import React from 'react';

import { Modal } from 'components/shared/Modal';
import { Button } from 'components/shared/Button';

import sanadLogo from 'assets/img/rating/sanad/popup-sanad-logo.png';
import thankyou from 'assets/img/rating/Thank-you.png';
import { useTranslation } from 'react-i18next';

import styles from './RatingModal.styles.scss';

const RatingModal = ({
  isOpen,
  openModal,
  onCancel,
  ratingPoints,
  ratings,
  isSubmitted,
  onSubmit,
  updateRate,
  rating,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Button
        onClick={() => openModal()}
        className={styles.rateButton + ' mx-4 bottom-0 text-white border-0'}
      >
        {t('rating_modal_title')}
      </Button>
      <Modal className={styles.modal} open={isOpen} onClose={onCancel}>
        {isSubmitted ? (
          <div className="d-flex justify-content-center">
            <img src={thankyou} className="mx-2" alt="logo" />
          </div>
        ) : (
          <>
            <img
              src={sanadLogo}
              className="mx-2 text-end"
              width="80"
              alt="logo"
            />

            <h6 className="text-center mb-4 mt-2">{t('rating_modal_title')}</h6>

            <table className="text-end bg-light w-100 my-5">
              <tbody>
                {ratingPoints?.map(({ title, name }) => (
                  <tr key={name}>
                    <td className="p-2">
                      <h6 className="text-danger">{title}</h6>
                    </td>

                    {ratings?.map(({ icon, value }) => (
                      <td key={value} className="p-2 text-center">
                        <img
                          src={icon}
                          className={
                            'text-left cursor-pointer ' +
                            (value === rating[name] ? styles.selected : '')
                          }
                          style={{ backgroundColor: '#808081' }}
                          width={'100%'}
                          alt="logo"
                          onClick={() => updateRate(name, value)}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>

            <textarea
              className={'w-100 fm-kufi-regular notes-text-area my-3 p-2'}
              placeholder={t('rating_modal_anyNotes')}
              rows={3}
            />

            <div className="d-flex justify-content-center">
              <button
                style={{
                  borderRadius: '5px',
                  backgroundColor: '#dd0f20',
                  color: 'white',
                  paddingLeft: '50px',
                  paddingRight: '50px',
                }}
                className="btn btn-regular fm-kufi-regular tex-sm"
                onClick={() => onSubmit()}
              >
                {t('rating_modal_send')}
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default React.memo(RatingModal);
