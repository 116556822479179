import { INVOICE_TYPES_ENUM } from 'constants/common';

import i18n from 'config/i18n';

export const INVOICE_COLUMNS = ({ t, invoiceType }) => {
  let columns = [
    {
      key: 'action',
      label: t('invoice_columns_action'),
      minWidth: 120,
      maxWidth: 120,
    },
    {
      key: 'totalAmountAfterTaxes',
      label: t('invoice_columns_totalAmountAfterTaxes'),
      minWidth: 174,
      maxWidth: 174,
    },
    {
      key: 'generalTaxAmount',
      label: t('invoice_columns_generalTaxAmount'),
      minWidth: 174,
      maxWidth: 174,
    },
    {
      key: 'generalTaxPercentage',
      label: t('invoice_columns_generalTaxPercentage'),
      minWidth: 174,
      maxWidth: 174,
    },
    {
      key: 'specialTaxAmount',
      label: t('invoice_columns_special_tax_amount'),
      minWidth: 174,
      maxWidth: 174,
    },
    {
      key: 'totalAmountAfterDiscount',
      label: t('invoice_columns_totalAmountAfterDiscount'),
      minWidth: 136,
      maxWidth: 136,
    },
    {
      key: 'discountAmount',
      label: t('invoice_columns_discountAmount'),
      minWidth: 136,
      maxWidth: 136,
    },
    {
      key: 'subtotalAmount',
      label: t('invoice_columns_subtotalAmount'),
      minWidth: 136,
      maxWidth: 136,
    },
    {
      key: 'unitPrice',
      label: t('invoice_columns_unitPrice'),
      minWidth: 136,
      maxWidth: 136,
    },
    {
      key: 'quantity',
      label: t('invoice_columns_quantity'),
      minWidth: 136,
      maxWidth: 136,
    },
    {
      key: 'description',
      label: t('invoice_columns_description'),
      minWidth: 274.5,
    },
    {
      key: 'isic4',
      label: t('invoice_columns_isic4'),
      minWidth: 275,
      maxWidth: 2000,
    },
    { key: 'order', label: '#', minWidth: 64, maxWidth: 64 },
  ];

  if (
    invoiceType === INVOICE_TYPES_ENUM.CASH_INCOME ||
    invoiceType === INVOICE_TYPES_ENUM.RECEIVABLE_INCOME
  ) {
    columns = columns.filter(
      (column) =>
        column.key !== 'subtotalAmount' &&
        column.key !== 'totalAmountAfterDiscount' &&
        column.key !== 'generalTaxPercentage' &&
        column.key !== 'generalTaxAmount',
    );
  }

  if (
    invoiceType !== INVOICE_TYPES_ENUM.CASH_SPECIAL_TAX &&
    invoiceType !== INVOICE_TYPES_ENUM.RECEIVABLE_SPECIAL_TAX
  ) {
    columns = columns.filter((column) => column.key !== 'specialTaxAmount');
  }

  return columns;
};

export const TAX_RATE_OPTIONS = (t) => [
  { label: t('tax_rate_exempt'), value: 'EXEMPT' },
  { label: '0%', value: 'ZERO' },
  { label: '1%', value: 'ONE' },
  { label: '2%', value: 'TWO' },
  { label: '3%', value: 'THREE' },
  { label: '4%', value: 'FOUR' },
  { label: '5%', value: 'FIVE' },
  { label: '6%', value: 'SIX' },
  { label: '7%', value: 'SEVEN' },
  { label: '8%', value: 'EIGHT' },
  { label: '10%', value: 'TEN' },
  { label: '16%', value: 'SIXTEEN' },
];

export const PROVINCE_OPTIONS = () => {
  const language = i18n.language;

  return [
    {
      label: language === 'en' ? 'Al Balqa' : 'البلقاء',
      value: 'JO-BA',
    },
    { label: language === 'en' ? 'Maan' : 'معان', value: 'JO-MN' },
    {
      label: language === 'en' ? 'Madaba' : 'مادبا',
      value: 'JO-MD',
    },
    {
      label: language === 'en' ? 'Al Mafraq' : 'المفرق',
      value: 'JO-MA',
    },
    { label: language === 'en' ? 'Al Karak' : 'الكرك', value: 'JO-KA' },
    { label: language === 'en' ? 'Jarash' : 'جرش', value: 'JO-JA' },
    {
      label: language === 'en' ? 'Irbid' : 'إربد',
      value: 'JO-IR',
    },
    { label: language === 'en' ? 'Al Zarqa' : 'الزرقاء', value: 'JO-AZ' },
    {
      label: language === 'en' ? 'Al Tafila' : 'الطفيلة',
      value: 'JO-AT',
    },
    {
      label: language === 'en' ? 'Al Aqaba' : 'العقبة',
      value: 'JO-AQ',
    },
    {
      label: language === 'en' ? 'Amman' : 'عمان',
      value: 'JO-AM',
    },
    { label: language === 'en' ? 'Ajloun' : 'عجلون', value: 'JO-AJ' },
  ];
};

export const TYPE_OPTIONS = (t) => [
  { label: t('type_options_item'), value: 'PRODUCT' },
  { label: t('type_options_service allowance'), value: 'SERVICE_CHARGE' },
];

export const DOCUMENT_TYPE_OPTIONS = (t) => [
  {
    label: t('unspecified'),
    value: '',
  },
  {
    label: t('document_type_options_IDNumber'),
    value: 'NATIONAL_ID_NUMBER',
  },
  {
    label: t('document_type_options_private_number'),
    value: 'PERSONAL_NUMBER',
  },
  {
    label: t('document_type_options_tax_number'),
    value: 'TAXPAYER_NUMBERS',
  },
  // {
  //   label: t('document_type_options_sales_hierarchy'),
  //   value: 'SERIAL_INCOME_NUMBER',
  // },
];
export const Invoice_Kind = (t, defaultValue = 'Local') =>
  [
    {
      label: t('invoice_kind_Local'),
      value: 'LOCAL',
    },
    {
      label: t('invoice_kind_Export'),
      value: 'EXPORT',
    },
    {
      label: t('invoice_kind_Development'),
      value: 'DEVELOPMENTAL',
    },
  ].map((item) => ({
    ...item,
    default: item.value === defaultValue,
  }));

export const CurrenciesFullName = (t) => ({
  JOD: t('Jordanian Dinar'),
  USD: t('United States Dollar'),
  EUR: t('Euro'),
  SAR: t('Saudi Riyal'),
  AED: t('UAE Dirham'),
  OMR: t('Rial Omani'),
  GBP: t('Pound Sterling'),
  QAR: t('Qatari Rial'),
  KWD: t('Kuwaiti Dinar'),
  BHD: t('Bahraini Dinar'),
  AUD: t('Australian Dollar'),
  CAD: t('Cadian Dollar'),
  JPY: t('Japanese Yen'),
  CHF: t('Swiss Franc'),
  TRY: t('Turkish Lira'),
  SYP: t('Syrian Pound'),
  EGP: t('Egyptian Pound'),
});
export const Currencies = (t) => [
  {
    label: t('JOD'),
    value: 'JOD',
  },
  {
    label: t('USD'),
    value: 'USD',
  },
  {
    label: t('EUR'),
    value: 'EUR',
  },
  // {
  //   label: t('YAN'),
  //   value: 'YAN',
  // },
  {
    label: t('SAR'),
    value: 'SAR',
  },
  {
    label: t('AED'),
    value: 'AED',
  },
  {
    label: t('OMR'),
    value: 'OMR',
  },
  {
    label: t('GBP'),
    value: 'GBP',
  },
  {
    label: t('QAR'),
    value: 'QAR',
  },
  {
    label: t('KWD'),
    value: 'KWD',
  },
  {
    label: t('BHD'),
    value: 'BHD',
  },
  {
    label: t('AUD'),
    value: 'AUD',
  },
  {
    label: t('CAD'),
    value: 'CAD',
  },
  {
    label: t('JPY'),
    value: 'JPY',
  },
  {
    label: t('CHF'),
    value: 'CHF',
  },
  {
    label: t('TRY'),
    value: 'TRY',
  },
  {
    label: t('SYP'),
    value: 'SYP',
  },
  {
    label: t('EGP'),
    value: 'EGP',
  },
];
export const ISIC_4_OPTIONS = (t) => [
  {
    label: t('isic4.isic4_0111'),
    value: '0111',
  },
  {
    label: t('isic4.isic4_0112'),
    value: '0112',
  },
  {
    label: t('isic4.isic4_0113'),
    value: '0113',
  },
  {
    label: t('isic4.isic4_0114'),
    value: '0114',
  },
  {
    label: t('isic4.isic4_0115'),
    value: '0115',
  },
  {
    label: t('isic4.isic4_0116'),
    value: '0116',
  },
  {
    label: t('isic4.isic4_0119'),
    value: '0119',
  },
  {
    label: t('isic4.isic4_0121'),
    value: '0121',
  },
  {
    label: t('isic4.isic4_0122'),
    value: '0122',
  },
  {
    label: t('isic4.isic4_0123'),
    value: '0123',
  },
  {
    label: t('isic4.isic4_0124'),
    value: '0124',
  },
  {
    label: t('isic4.isic4_0125'),
    value: '0125',
  },
  {
    label: t('isic4.isic4_0126'),
    value: '0126',
  },
  {
    label: t('isic4.isic4_0127'),
    value: '0127',
  },
  {
    label: t('isic4.isic4_0128'),
    value: '0128',
  },
  {
    label: t('isic4.isic4_0129'),
    value: '0129',
  },
  {
    label: t('isic4.isic4_0130'),
    value: '0130',
  },
  {
    label: t('isic4.isic4_0141'),
    value: '0141',
  },
  {
    label: t('isic4.isic4_0142'),
    value: '0142',
  },
  {
    label: t('isic4.isic4_0143'),
    value: '0143',
  },
  {
    label: t('isic4.isic4_0144'),
    value: '0144',
  },
  {
    label: t('isic4.isic4_0145'),
    value: '0145',
  },
  {
    label: t('isic4.isic4_0146'),
    value: '0146',
  },
  {
    label: t('isic4.isic4_0149'),
    value: '0149',
  },
  {
    label: t('isic4.isic4_0150'),
    value: '0150',
  },
  {
    label: t('isic4.isic4_0161'),
    value: '0161',
  },
  {
    label: t('isic4.isic4_0162'),
    value: '0162',
  },
  {
    label: t('isic4.isic4_0163'),
    value: '0163',
  },
  {
    label: t('isic4.isic4_0164'),
    value: '0164',
  },
  {
    label: t('isic4.isic4_0170'),
    value: '0170',
  },
  {
    label: t('isic4.isic4_0210'),
    value: '0210',
  },
  {
    label: t('isic4.isic4_0220'),
    value: '0220',
  },
  {
    label: t('isic4.isic4_0230'),
    value: '0230',
  },
  {
    label: t('isic4.isic4_0240'),
    value: '0240',
  },
  {
    label: t('isic4.isic4_0311'),
    value: '0311',
  },
  {
    label: t('isic4.isic4_0312'),
    value: '0312',
  },
  {
    label: t('isic4.isic4_0321'),
    value: '0321',
  },
  {
    label: t('isic4.isic4_0322'),
    value: '0322',
  },
  {
    label: t('isic4.isic4_0510'),
    value: '0510',
  },
  {
    label: t('isic4.isic4_0520'),
    value: '0520',
  },
  {
    label: t('isic4.isic4_0610'),
    value: '0610',
  },
  {
    label: t('isic4.isic4_0620'),
    value: '0620',
  },
  {
    label: t('isic4.isic4_0710'),
    value: '0710',
  },
  {
    label: t('isic4.isic4_0721'),
    value: '0721',
  },
  {
    label: t('isic4.isic4_0729'),
    value: '0729',
  },
  {
    label: t('isic4.isic4_0810'),
    value: '0810',
  },
  {
    label: t('isic4.isic4_0891'),
    value: '0891',
  },
  {
    label: t('isic4.isic4_0892'),
    value: '0892',
  },
  {
    label: t('isic4.isic4_0893'),
    value: '0893',
  },
  {
    label: t('isic4.isic4_0899'),
    value: '0899',
  },
  {
    label: t('isic4.isic4_0910'),
    value: '0910',
  },
  {
    label: t('isic4.isic4_0990'),
    value: '0990',
  },
  {
    label: t('isic4.isic4_1010'),
    value: '1010',
  },
  {
    label: t('isic4.isic4_1020'),
    value: '1020',
  },
  {
    label: t('isic4.isic4_1030'),
    value: '1030',
  },
  {
    label: t('isic4.isic4_1040'),
    value: '1040',
  },
  {
    label: t('isic4.isic4_1050'),
    value: '1050',
  },
  {
    label: t('isic4.isic4_1061'),
    value: '1061',
  },
  {
    label: t('isic4.isic4_1062'),
    value: '1062',
  },
  {
    label: t('isic4.isic4_1071'),
    value: '1071',
  },
  {
    label: t('isic4.isic4_1072'),
    value: '1072',
  },
  {
    label: t('isic4.isic4_1073'),
    value: '1073',
  },
  {
    label: t('isic4.isic4_1074'),
    value: '1074',
  },
  {
    label: t('isic4.isic4_1075'),
    value: '1075',
  },
  {
    label: t('isic4.isic4_1079'),
    value: '1079',
  },
  {
    label: t('isic4.isic4_1080'),
    value: '1080',
  },
  {
    label: t('isic4.isic4_1101'),
    value: '1101',
  },
  {
    label: t('isic4.isic4_1102'),
    value: '1102',
  },
  {
    label: t('isic4.isic4_1103'),
    value: '1103',
  },
  {
    label: t('isic4.isic4_1104'),
    value: '1104',
  },
  {
    label: t('isic4.isic4_1200'),
    value: '1200',
  },
  {
    label: t('isic4.isic4_1311'),
    value: '1311',
  },
  {
    label: t('isic4.isic4_1312'),
    value: '1312',
  },
  {
    label: t('isic4.isic4_1313'),
    value: '1313',
  },
  {
    label: t('isic4.isic4_1391'),
    value: '1391',
  },
  {
    label: t('isic4.isic4_1392'),
    value: '1392',
  },
  {
    label: t('isic4.isic4_1393'),
    value: '1393',
  },
  {
    label: t('isic4.isic4_1394'),
    value: '1394',
  },
  {
    label: t('isic4.isic4_1399'),
    value: '1399',
  },
  {
    label: t('isic4.isic4_1410'),
    value: '1410',
  },
  {
    label: t('isic4.isic4_1420'),
    value: '1420',
  },
  {
    label: t('isic4.isic4_1430'),
    value: '1430',
  },
  {
    label: t('isic4.isic4_1511'),
    value: '1511',
  },
  {
    label: t('isic4.isic4_1512'),
    value: '1512',
  },
  {
    label: t('isic4.isic4_1520'),
    value: '1520',
  },
  {
    label: t('isic4.isic4_1610'),
    value: '1610',
  },
  {
    label: t('isic4.isic4_2815'),
    value: '2815',
  },
  {
    label: t('isic4.isic4_2816'),
    value: '2816',
  },
  {
    label: t('isic4.isic4_2817'),
    value: '2817',
  },
  {
    label: t('isic4.isic4_2818'),
    value: '2818',
  },
  {
    label: t('isic4.isic4_2819'),
    value: '2819',
  },
  {
    label: t('isic4.isic4_2821'),
    value: '2821',
  },
  {
    label: t('isic4.isic4_2822'),
    value: '2822',
  },
  {
    label: t('isic4.isic4_2823'),
    value: '2823',
  },
  {
    label: t('isic4.isic4_2824'),
    value: '2824',
  },
  {
    label: t('isic4.isic4_2825'),
    value: '2825',
  },
  {
    label: t('isic4.isic4_2826'),
    value: '2826',
  },
  {
    label: t('isic4.isic4_2829'),
    value: '2829',
  },
  {
    label: t('isic4.isic4_2910'),
    value: '2910',
  },
  {
    label: t('isic4.isic4_2920'),
    value: '2920',
  },
  {
    label: t('isic4.isic4_2930'),
    value: '2930',
  },
  {
    label: t('isic4.isic4_3011'),
    value: '3011',
  },
  {
    label: t('isic4.isic4_3012'),
    value: '3012',
  },
  {
    label: t('isic4.isic4_3020'),
    value: '3020',
  },
  {
    label: t('isic4.isic4_3030'),
    value: '3030',
  },
  {
    label: t('isic4.isic4_3040'),
    value: '3040',
  },
  {
    label: t('isic4.isic4_3091'),
    value: '3091',
  },
  {
    label: t('isic4.isic4_3092'),
    value: '3092',
  },
  {
    label: t('isic4.isic4_3099'),
    value: '3099',
  },
  {
    label: t('isic4.isic4_3100'),
    value: '3100',
  },
  {
    label: t('isic4.isic4_3211'),
    value: '3211',
  },
  {
    label: t('isic4.isic4_3212'),
    value: '3212',
  },
  {
    label: t('isic4.isic4_3220'),
    value: '3220',
  },
  {
    label: t('isic4.isic4_3230'),
    value: '3230',
  },
  {
    label: t('isic4.isic4_3240'),
    value: '3240',
  },
  {
    label: t('isic4.isic4_3250'),
    value: '3250',
  },
  {
    label: t('isic4.isic4_3290'),
    value: '3290',
  },
  {
    label: t('isic4.isic4_3311'),
    value: '3311',
  },
  {
    label: t('isic4.isic4_3312'),
    value: '3312',
  },
  {
    label: t('isic4.isic4_3313'),
    value: '3313',
  },
  {
    label: t('isic4.isic4_3314'),
    value: '3314',
  },
  {
    label: t('isic4.isic4_3315'),
    value: '3315',
  },
  {
    label: t('isic4.isic4_3319'),
    value: '3319',
  },
  {
    label: t('isic4.isic4_3320'),
    value: '3320',
  },
  {
    label: t('isic4.isic4_3510'),
    value: '3510',
  },
  {
    label: t('isic4.isic4_3520'),
    value: '3520',
  },
  {
    label: t('isic4.isic4_3530'),
    value: '3530',
  },
  {
    label: t('isic4.isic4_3600'),
    value: '3600',
  },
  {
    label: t('isic4.isic4_3700'),
    value: '3700',
  },
  {
    label: t('isic4.isic4_3811'),
    value: '3811',
  },
  {
    label: t('isic4.isic4_3812'),
    value: '3812',
  },
  {
    label: t('isic4.isic4_3821'),
    value: '3821',
  },
  {
    label: t('isic4.isic4_3822'),
    value: '3822',
  },
  {
    label: t('isic4.isic4_3830'),
    value: '3830',
  },
  {
    label: t('isic4.isic4_3900'),
    value: '3900',
  },
  {
    label: t('isic4.isic4_4100'),
    value: '4100',
  },
  {
    label: t('isic4.isic4_4210'),
    value: '4210',
  },
  {
    label: t('isic4.isic4_4220'),
    value: '4220',
  },
  {
    label: t('isic4.isic4_4290'),
    value: '4290',
  },
  {
    label: t('isic4.isic4_4311'),
    value: '4311',
  },
  {
    label: t('isic4.isic4_4312'),
    value: '4312',
  },
  {
    label: t('isic4.isic4_4321'),
    value: '4321',
  },
  {
    label: t('isic4.isic4_4322'),
    value: '4322',
  },
  {
    label: t('isic4.isic4_4329'),
    value: '4329',
  },
  {
    label: t('isic4.isic4_4330'),
    value: '4330',
  },
  {
    label: t('isic4.isic4_4390'),
    value: '4390',
  },
  {
    label: t('isic4.isic4_4510'),
    value: '4510',
  },
  {
    label: t('isic4.isic4_4520'),
    value: '4520',
  },
  {
    label: t('isic4.isic4_4530'),
    value: '4530',
  },
  {
    label: t('isic4.isic4_4540'),
    value: '4540',
  },
  {
    label: t('isic4.isic4_4610'),
    value: '4610',
  },
  {
    label: t('isic4.isic4_4620'),
    value: '4620',
  },
  {
    label: t('isic4.isic4_4630'),
    value: '4630',
  },
  {
    label: t('isic4.isic4_4641'),
    value: '4641',
  },
  {
    label: t('isic4.isic4_4649'),
    value: '4649',
  },
  {
    label: t('isic4.isic4_4651'),
    value: '4651',
  },
  {
    label: t('isic4.isic4_4652'),
    value: '4652',
  },
  {
    label: t('isic4.isic4_4653'),
    value: '4653',
  },
  {
    label: t('isic4.isic4_4659'),
    value: '4659',
  },
  {
    label: t('isic4.isic4_4661'),
    value: '4661',
  },
  {
    label: t('isic4.isic4_4662'),
    value: '4662',
  },
  {
    label: t('isic4.isic4_4663'),
    value: '4663',
  },
  {
    label: t('isic4.isic4_4669'),
    value: '4669',
  },
  {
    label: t('isic4.isic4_4690'),
    value: '4690',
  },
  {
    label: t('isic4.isic4_4711'),
    value: '4711',
  },
  {
    label: t('isic4.isic4_4719'),
    value: '4719',
  },
  {
    label: t('isic4.isic4_4721'),
    value: '4721',
  },
  {
    label: t('isic4.isic4_4722'),
    value: '4722',
  },
  {
    label: t('isic4.isic4_4723'),
    value: '4723',
  },
  {
    label: t('isic4.isic4_4730'),
    value: '4730',
  },
  {
    label: t('isic4.isic4_4741'),
    value: '4741',
  },
  {
    label: t('isic4.isic4_4742'),
    value: '4742',
  },
  {
    label: t('isic4.isic4_4751'),
    value: '4751',
  },
  {
    label: t('isic4.isic4_4752'),
    value: '4752',
  },
  {
    label: t('isic4.isic4_4753'),
    value: '4753',
  },
  {
    label: t('isic4.isic4_4759'),
    value: '4759',
  },
  {
    label: t('isic4.isic4_4761'),
    value: '4761',
  },
  {
    label: t('isic4.isic4_4762'),
    value: '4762',
  },
  {
    label: t('isic4.isic4_4763'),
    value: '4763',
  },
  {
    label: t('isic4.isic4_4764'),
    value: '4764',
  },
  {
    label: t('isic4.isic4_4771'),
    value: '4771',
  },
  {
    label: t('isic4.isic4_4772'),
    value: '4772',
  },
  {
    label: t('isic4.isic4_4773'),
    value: '4773',
  },
  {
    label: t('isic4.isic4_4774'),
    value: '4774',
  },
  {
    label: t('isic4.isic4_4781'),
    value: '4781',
  },
  {
    label: t('isic4.isic4_4782'),
    value: '4782',
  },
  {
    label: t('isic4.isic4_4789'),
    value: '4789',
  },
  {
    label: t('isic4.isic4_4791'),
    value: '4791',
  },
  {
    label: t('isic4.isic4_4799'),
    value: '4799',
  },
  {
    label: t('isic4.isic4_4911'),
    value: '4911',
  },
  {
    label: t('isic4.isic4_4912'),
    value: '4912',
  },
  {
    label: t('isic4.isic4_4921'),
    value: '4921',
  },
  {
    label: t('isic4.isic4_4922'),
    value: '4922',
  },
  {
    label: t('isic4.isic4_4923'),
    value: '4923',
  },
  {
    label: t('isic4.isic4_4930'),
    value: '4930',
  },
  {
    label: t('isic4.isic4_5011'),
    value: '5011',
  },
  {
    label: t('isic4.isic4_5012'),
    value: '5012',
  },
  {
    label: t('isic4.isic4_5021'),
    value: '5021',
  },
  {
    label: t('isic4.isic4_5022'),
    value: '5022',
  },
  {
    label: t('isic4.isic4_5110'),
    value: '5110',
  },
  {
    label: t('isic4.isic4_5120'),
    value: '5120',
  },
  {
    label: t('isic4.isic4_5210'),
    value: '5210',
  },
  {
    label: t('isic4.isic4_5221'),
    value: '5221',
  },
  {
    label: t('isic4.isic4_5222'),
    value: '5222',
  },
  {
    label: t('isic4.isic4_5223'),
    value: '5223',
  },
  {
    label: t('isic4.isic4_5224'),
    value: '5224',
  },
  {
    label: t('isic4.isic4_5229'),
    value: '5229',
  },
  {
    label: t('isic4.isic4_5310'),
    value: '5310',
  },
  {
    label: t('isic4.isic4_5320'),
    value: '5320',
  },
  {
    label: t('isic4.isic4_5510'),
    value: '5510',
  },
  {
    label: t('isic4.isic4_5520'),
    value: '5520',
  },
  {
    label: t('isic4.isic4_5590'),
    value: '5590',
  },
  {
    label: t('isic4.isic4_5610'),
    value: '5610',
  },
  {
    label: t('isic4.isic4_5621'),
    value: '5621',
  },
  {
    label: t('isic4.isic4_5629'),
    value: '5629',
  },
  {
    label: t('isic4.isic4_5630'),
    value: '5630',
  },
  {
    label: t('isic4.isic4_5811'),
    value: '5811',
  },
  {
    label: t('isic4.isic4_5812'),
    value: '5812',
  },
  {
    label: t('isic4.isic4_5813'),
    value: '5813',
  },
  {
    label: t('isic4.isic4_5819'),
    value: '5819',
  },
  {
    label: t('isic4.isic4_5820'),
    value: '5820',
  },
  {
    label: t('isic4.isic4_5911'),
    value: '5911',
  },
  {
    label: t('isic4.isic4_5912'),
    value: '5912',
  },
  {
    label: t('isic4.isic4_8411'),
    value: '8411',
  },
  {
    label: t('isic4.isic4_8412'),
    value: '8412',
  },
  {
    label: t('isic4.isic4_8413'),
    value: '8413',
  },
  {
    label: t('isic4.isic4_8421'),
    value: '8421',
  },
  {
    label: t('isic4.isic4_8422'),
    value: '8422',
  },
  {
    label: t('isic4.isic4_8423'),
    value: '8423',
  },
  {
    label: t('isic4.isic4_8430'),
    value: '8430',
  },
  {
    label: t('isic4.isic4_8510'),
    value: '8510',
  },
  {
    label: t('isic4.isic4_8521'),
    value: '8521',
  },
  {
    label: t('isic4.isic4_8522'),
    value: '8522',
  },
  {
    label: t('isic4.isic4_8530'),
    value: '8530',
  },
  {
    label: t('isic4.isic4_8541'),
    value: '8541',
  },
  {
    label: t('isic4.isic4_8542'),
    value: '8542',
  },
  {
    label: t('isic4.isic4_8549'),
    value: '8549',
  },
  {
    label: t('isic4.isic4_8550'),
    value: '8550',
  },
  {
    label: t('isic4.isic4_8610'),
    value: '8610',
  },
  {
    label: t('isic4.isic4_8620'),
    value: '8620',
  },
  {
    label: t('isic4.isic4_8690'),
    value: '8690',
  },
  {
    label: t('isic4.isic4_8710'),
    value: '8710',
  },
  {
    label: t('isic4.isic4_8720'),
    value: '8720',
  },
  {
    label: t('isic4.isic4_8730'),
    value: '8730',
  },
  {
    label: t('isic4.isic4_8790'),
    value: '8790',
  },
  {
    label: t('isic4.isic4_8810'),
    value: '8810',
  },
  {
    label: t('isic4.isic4_8890'),
    value: '8890',
  },
  {
    label: t('isic4.isic4_9000'),
    value: '9000',
  },
  {
    label: t('isic4.isic4_9101'),
    value: '9101',
  },
  {
    label: t('isic4.isic4_9102'),
    value: '9102',
  },
  {
    label: t('isic4.isic4_9103'),
    value: '9103',
  },
  {
    label: t('isic4.isic4_9200'),
    value: '9200',
  },
  {
    label: t('isic4.isic4_9311'),
    value: '9311',
  },
  {
    label: t('isic4.isic4_9312'),
    value: '9312',
  },
  {
    label: t('isic4.isic4_9319'),
    value: '9319',
  },
  {
    label: t('isic4.isic4_9321'),
    value: '9321',
  },
  {
    label: t('isic4.isic4_9329'),
    value: '9329',
  },
  {
    label: t('isic4.isic4_9411'),
    value: '9411',
  },
  {
    label: t('isic4.isic4_9412'),
    value: '9412',
  },
  {
    label: t('isic4.isic4_9420'),
    value: '9420',
  },
  {
    label: t('isic4.isic4_9491'),
    value: '9491',
  },
  {
    label: t('isic4.isic4_9492'),
    value: '9492',
  },
  {
    label: t('isic4.isic4_9499'),
    value: '9499',
  },
  {
    label: t('isic4.isic4_9511'),
    value: '9511',
  },
  {
    label: t('isic4.isic4_9512'),
    value: '9512',
  },
  {
    label: t('isic4.isic4_9521'),
    value: '9521',
  },
  {
    label: t('isic4.isic4_9522'),
    value: '9522',
  },
  {
    label: t('isic4.isic4_9523'),
    value: '9523',
  },
  {
    label: t('isic4.isic4_9524'),
    value: '9524',
  },
  {
    label: t('isic4.isic4_9529'),
    value: '9529',
  },
  {
    label: t('isic4.isic4_9601'),
    value: '9601',
  },
  {
    label: t('isic4.isic4_9602'),
    value: '9602',
  },
  {
    label: t('isic4.isic4_9603'),
    value: '9603',
  },
  {
    label: t('isic4.isic4_9609'),
    value: '9609',
  },
  {
    label: t('isic4.isic4_9700'),
    value: '9700',
  },
  {
    label: t('isic4.isic4_9810'),
    value: '9810',
  },
  {
    label: t('isic4.isic4_9820'),
    value: '9820',
  },
  {
    label: t('isic4.isic4_9900'),
    value: '9900',
  },
  {
    label: t('isic4.isic4_0910'),
    value: '0910',
  },
  {
    label: t('isic4.isic4_0990'),
    value: '0990',
  },
  {
    label: t('isic4.isic4_2710'),
    value: '2710',
  },
  {
    label: t('isic4.isic4_9999'),
    value: '9999',
  },
  {
    label: t('isic4.isic4_1621'),
    value: '1621',
  },
  {
    label: t('isic4.isic4_1622'),
    value: '1622',
  },
  {
    label: t('isic4.isic4_1623'),
    value: '1623',
  },
  {
    label: t('isic4.isic4_1629'),
    value: '1629',
  },
  {
    label: t('isic4.isic4_1701'),
    value: '1701',
  },
  {
    label: t('isic4.isic4_1702'),
    value: '1702',
  },
  {
    label: t('isic4.isic4_1709'),
    value: '1709',
  },
  {
    label: t('isic4.isic4_1811'),
    value: '1811',
  },
  {
    label: t('isic4.isic4_1812'),
    value: '1812',
  },
  {
    label: t('isic4.isic4_1820'),
    value: '1820',
  },
  {
    label: t('isic4.isic4_1910'),
    value: '1910',
  },
  {
    label: t('isic4.isic4_1920'),
    value: '1920',
  },
  {
    label: t('isic4.isic4_2011'),
    value: '2011',
  },
  {
    label: t('isic4.isic4_2012'),
    value: '2012',
  },
  {
    label: t('isic4.isic4_2013'),
    value: '2013',
  },
  {
    label: t('isic4.isic4_2021'),
    value: '2021',
  },
  {
    label: t('isic4.isic4_2022'),
    value: '2022',
  },
  {
    label: t('isic4.isic4_2023'),
    value: '2023',
  },
  {
    label: t('isic4.isic4_2029'),
    value: '2029',
  },
  {
    label: t('isic4.isic4_2030'),
    value: '2030',
  },
  {
    label: t('isic4.isic4_2100'),
    value: '2100',
  },
  {
    label: t('isic4.isic4_2211'),
    value: '2211',
  },
  {
    label: t('isic4.isic4_2219'),
    value: '2219',
  },
  {
    label: t('isic4.isic4_2220'),
    value: '2220',
  },
  {
    label: t('isic4.isic4_2310'),
    value: '2310',
  },
  {
    label: t('isic4.isic4_2391'),
    value: '2391',
  },
  {
    label: t('isic4.isic4_2392'),
    value: '2392',
  },
  {
    label: t('isic4.isic4_2393'),
    value: '2393',
  },
  {
    label: t('isic4.isic4_2394'),
    value: '2394',
  },
  {
    label: t('isic4.isic4_2395'),
    value: '2395',
  },
  {
    label: t('isic4.isic4_2396'),
    value: '2396',
  },
  {
    label: t('isic4.isic4_2399'),
    value: '2399',
  },
  {
    label: t('isic4.isic4_2410'),
    value: '2410',
  },
  {
    label: t('isic4.isic4_2420'),
    value: '2420',
  },
  {
    label: t('isic4.isic4_2431'),
    value: '2431',
  },
  {
    label: t('isic4.isic4_2432'),
    value: '2432',
  },
  {
    label: t('isic4.isic4_2511'),
    value: '2511',
  },
  {
    label: t('isic4.isic4_2512'),
    value: '2512',
  },
  {
    label: t('isic4.isic4_2513'),
    value: '2513',
  },
  {
    label: t('isic4.isic4_2520'),
    value: '2520',
  },
  {
    label: t('isic4.isic4_2591'),
    value: '2591',
  },
  {
    label: t('isic4.isic4_2592'),
    value: '2592',
  },
  {
    label: t('isic4.isic4_2593'),
    value: '2593',
  },
  {
    label: t('isic4.isic4_2599'),
    value: '2599',
  },
  {
    label: t('isic4.isic4_2610'),
    value: '2610',
  },
  {
    label: t('isic4.isic4_2620'),
    value: '2620',
  },
  {
    label: t('isic4.isic4_2630'),
    value: '2630',
  },
  {
    label: t('isic4.isic4_2640'),
    value: '2640',
  },
  {
    label: t('isic4.isic4_2651'),
    value: '2651',
  },
  {
    label: t('isic4.isic4_2652'),
    value: '2652',
  },
  {
    label: t('isic4.isic4_2660'),
    value: '2660',
  },
  {
    label: t('isic4.isic4_2670'),
    value: '2670',
  },
  {
    label: t('isic4.isic4_2680'),
    value: '2680',
  },
  {
    label: t('isic4.isic4_2710'),
    value: '2710',
  },
  {
    label: t('isic4.isic4_2720'),
    value: '2720',
  },
  {
    label: t('isic4.isic4_2731'),
    value: '2731',
  },
  {
    label: t('isic4.isic4_2732'),
    value: '2732',
  },
  {
    label: t('isic4.isic4_2733'),
    value: '2733',
  },
  {
    label: t('isic4.isic4_2740'),
    value: '2740',
  },
  {
    label: t('isic4.isic4_2750'),
    value: '2750',
  },
  {
    label: t('isic4.isic4_2790'),
    value: '2790',
  },
  {
    label: t('isic4.isic4_2811'),
    value: '2811',
  },
  {
    label: t('isic4.isic4_2812'),
    value: '2812',
  },
  {
    label: t('isic4.isic4_2813'),
    value: '2813',
  },
  {
    label: t('isic4.isic4_2814'),
    value: '2814',
  },
  {
    label: t('isic4.isic4_5913'),
    value: '5913',
  },
  {
    label: t('isic4.isic4_5914'),
    value: '5914',
  },
  {
    label: t('isic4.isic4_5920'),
    value: '5920',
  },
  {
    label: t('isic4.isic4_6010'),
    value: '6010',
  },
  {
    label: t('isic4.isic4_6020'),
    value: '6020',
  },
  {
    label: t('isic4.isic4_6110'),
    value: '6110',
  },
  {
    label: t('isic4.isic4_6120'),
    value: '6120',
  },
  {
    label: t('isic4.isic4_6130'),
    value: '6130',
  },
  {
    label: t('isic4.isic4_6190'),
    value: '6190',
  },
  {
    label: t('isic4.isic4_6201'),
    value: '6201',
  },
  {
    label: t('isic4.isic4_6202'),
    value: '6202',
  },
  {
    label: t('isic4.isic4_6209'),
    value: '6209',
  },
  {
    label: t('isic4.isic4_6311'),
    value: '6311',
  },
  {
    label: t('isic4.isic4_6312'),
    value: '6312',
  },
  {
    label: t('isic4.isic4_6391'),
    value: '6391',
  },
  {
    label: t('isic4.isic4_6399'),
    value: '6399',
  },
  {
    label: t('isic4.isic4_6411'),
    value: '6411',
  },
  {
    label: t('isic4.isic4_6419'),
    value: '6419',
  },
  {
    label: t('isic4.isic4_6420'),
    value: '6420',
  },
  {
    label: t('isic4.isic4_6430'),
    value: '6430',
  },
  {
    label: t('isic4.isic4_6491'),
    value: '6491',
  },
  {
    label: t('isic4.isic4_6492'),
    value: '6492',
  },
  {
    label: t('isic4.isic4_6499'),
    value: '6499',
  },
  {
    label: t('isic4.isic4_6511'),
    value: '6511',
  },
  {
    label: t('isic4.isic4_6512'),
    value: '6512',
  },
  {
    label: t('isic4.isic4_6520'),
    value: '6520',
  },
  {
    label: t('isic4.isic4_6530'),
    value: '6530',
  },
  {
    label: t('isic4.isic4_6611'),
    value: '6611',
  },
  {
    label: t('isic4.isic4_6612'),
    value: '6612',
  },
  {
    label: t('isic4.isic4_6619'),
    value: '6619',
  },
  {
    label: t('isic4.isic4_6621'),
    value: '6621',
  },
  {
    label: t('isic4.isic4_6622'),
    value: '6622',
  },
  {
    label: t('isic4.isic4_6629'),
    value: '6629',
  },
  {
    label: t('isic4.isic4_6630'),
    value: '6630',
  },
  {
    label: t('isic4.isic4_6810'),
    value: '6810',
  },
  {
    label: t('isic4.isic4_6820'),
    value: '6820',
  },
  {
    label: t('isic4.isic4_6910'),
    value: '6910',
  },
  {
    label: t('isic4.isic4_6920'),
    value: '6920',
  },
  {
    label: t('isic4.isic4_7010'),
    value: '7010',
  },
  {
    label: t('isic4.isic4_7020'),
    value: '7020',
  },
  {
    label: t('isic4.isic4_7110'),
    value: '7110',
  },
  {
    label: t('isic4.isic4_7120'),
    value: '7120',
  },
  {
    label: t('isic4.isic4_7210'),
    value: '7210',
  },
  {
    label: t('isic4.isic4_7220'),
    value: '7220',
  },
  {
    label: t('isic4.isic4_7310'),
    value: '7310',
  },
  {
    label: t('isic4.isic4_7320'),
    value: '7320',
  },
  {
    label: t('isic4.isic4_7410'),
    value: '7410',
  },
  {
    label: t('isic4.isic4_7420'),
    value: '7420',
  },
  {
    label: t('isic4.isic4_7490'),
    value: '7490',
  },
  {
    label: t('isic4.isic4_7500'),
    value: '7500',
  },
  {
    label: t('isic4.isic4_7710'),
    value: '7710',
  },
  {
    label: t('isic4.isic4_7721'),
    value: '7721',
  },
  {
    label: t('isic4.isic4_7722'),
    value: '7722',
  },
  {
    label: t('isic4.isic4_7729'),
    value: '7729',
  },
  {
    label: t('isic4.isic4_7730'),
    value: '7730',
  },
  {
    label: t('isic4.isic4_7740'),
    value: '7740',
  },
  {
    label: t('isic4.isic4_7810'),
    value: '7810',
  },
  {
    label: t('isic4.isic4_7820'),
    value: '7820',
  },
  {
    label: t('isic4.isic4_7830'),
    value: '7830',
  },
  {
    label: t('isic4.isic4_7911'),
    value: '7911',
  },
  {
    label: t('isic4.isic4_7912'),
    value: '7912',
  },
  {
    label: t('isic4.isic4_7990'),
    value: '7990',
  },
  {
    label: t('isic4.isic4_8010'),
    value: '8010',
  },
  {
    label: t('isic4.isic4_8020'),
    value: '8020',
  },
  {
    label: t('isic4.isic4_8030'),
    value: '8030',
  },
  {
    label: t('isic4.isic4_8110'),
    value: '8110',
  },
  {
    label: t('isic4.isic4_8121'),
    value: '8121',
  },
  {
    label: t('isic4.isic4_8129'),
    value: '8129',
  },
  {
    label: t('isic4.isic4_8130'),
    value: '8130',
  },
  {
    label: t('isic4.isic4_8211'),
    value: '8211',
  },
  {
    label: t('isic4.isic4_8219'),
    value: '8219',
  },
  {
    label: t('isic4.isic4_8220'),
    value: '8220',
  },
  {
    label: t('isic4.isic4_8230'),
    value: '8230',
  },
  {
    label: t('isic4.isic4_8291'),
    value: '8291',
  },
  {
    label: t('isic4.isic4_8292'),
    value: '8292',
  },
  {
    label: t('isic4.isic4_8299'),
    value: '8299',
  },
];
