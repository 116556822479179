import React, { useState } from 'react';
import cn from 'classnames';

import EditInvoiceIcon from 'assets/img/edit-invoice.svg';
import NewInvoiceIcon from 'assets/img/new-invoice.svg';

import InvoiceIcon from 'assets/img/invoice.svg';

import { Typography } from 'components/shared/Typography';
import { Modal } from 'components/shared/Modal';
import { Loading } from 'components/shared/Loading';

import styles from './SettingsModal.styles.scss';
import { useTranslation } from 'react-i18next';
import { UploadLogo } from '../UploadLogo';
import SelectField from '../../../../components/FormikFields/SelectField';
import { FormikField } from 'components/FormikField';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'components/shared/Button';
import { updateUserData } from '../../../../api/methods';
import { showToast } from 'helpers/showToast';
import { viewUserLogo } from '../../../../helpers/viewUserLogo';
import { useUser } from 'context/user';

const SettingsModal = ({
  open,
  loading,
  onClose,
  loadActivityOptions,
  handleFormSubmit,
}) => {
  const { t, i18n } = useTranslation();
  const { user } = useUser();
  const languageDirection = i18n.dir();

  const [uploadInnerStyle, setUploadInnerStyle] = useState(styles.uploadInner);
  const [activityNumber, setActivityNumber] = useState();
  const [loadingImage, setLoadingImage] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [loadingUserData, setLoadingUserData] = useState(false);

  const setUploadStyle = (loading) => {
    loading
      ? setUploadInnerStyle(styles.uploadInnerLoading)
      : setUploadInnerStyle(styles.uploadInner);
  };

  const validationSchema = () =>
    Yup.object().shape({
      activityNumber: Yup.object().required(
        t('device_modal_validation_activitynumber'),
      ),
      invoiceLogo: Yup.mixed().test(
        'invoiceLogo',
        t('settings_logo_required'),
        (value) => {
          // eslint-disable-next-line no-undef
          return value instanceof File;
        },
      ),
      // invoiceLogo: Yup.object().required(t('settings_logo_required')),
    });

  return (
    <Modal
      className={styles.modalWrapper}
      centered
      open={open}
      onClose={onClose}
    >
      <div className={styles.modal}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <InvoiceIcon className={styles.icon} />
            <Typography className={styles.title}>
              {t('home_settings')}
            </Typography>
            <div className={styles.inner}>
              <div className={cn(styles.item)}>
                {/* <div className={styles.updateUserDataBtn}>
                  <Button
                    className={styles.action}
                    loading={loadingUserData}
                    onClick={() => {
                      setLoadingUserData(true);
                      updateUserData(user?.taxNumber || '')
                        .then(() => {
                          setLoadingUserData(false);
                          showToast({
                            data: {
                              variant: 'success',
                              text: t('user_data_been_updated'),
                            },
                          });
                        })
                        .catch((err) => {
                          setLoadingUserData(false);
                          if (typeof err.error === 'string') {
                            showToast({
                              data: {
                                variant: 'error',
                                text: err.error,
                              },
                            });
                          }
                        });
                    }}
                    type="button"
                  >
                    {t('update_user_data')}
                  </Button>
                </div> */}
                <Formik
                  // validateOnMount
                  enableReinitialize
                  validationSchema={validationSchema()}
                  initialValues={{
                    activityNumber: '',
                    invoiceLogo: '',
                  }}
                  onSubmit={handleFormSubmit}
                >
                  {({ setFieldValue, values, errors }) => (
                    <Form className={styles.form}>
                      <div>
                        <FormikField
                          name="activityNumber"
                          component={SelectField}
                          componentProps={{
                            className: styles.field,
                            type: 'async',
                            cacheOptions: true,
                            defaultOptions: true,
                            loadOptions: loadActivityOptions,
                            onChange: async (event) => {
                              setLoadingImage(true);

                              viewUserLogo({
                                setImage: setPreviewImage,
                                setLoading: setLoadingImage,
                                setFieldValue,
                                activityNumber: event,
                              });
                            },
                          }}
                          label={t('usersmodal_activity_number')}
                        />
                      </div>
                      <div className={cn(styles.itemInner, uploadInnerStyle)}>
                        <UploadLogo
                          setUploadStyle={setUploadStyle}
                          setFieldValue={setFieldValue}
                          values={values}
                          loading={loadingImage}
                          setLoading={setLoadingImage}
                          previewImage={previewImage}
                          setPreviewImage={setPreviewImage}
                        />
                      </div>
                      <p className={styles.formError}>{errors.invoiceLogo}</p>
                      <div
                        className={styles['logo-requirements']}
                        style={{ direction: languageDirection }}
                      >
                        <p>{t('logo_requirements')}:</p>
                        <p>{t('type')}: PNG, JPG, JPEG</p>
                        <p>{t('file_size')}: 1MB</p>
                        <p>{t('dimensions')}: 300x300</p>
                      </div>
                      <div className={styles.submitBtn}>
                        <Button
                          className={styles.action}
                          loading={loading}
                          type="submit"
                        >
                          {t('add_logo')}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default React.memo(SettingsModal);
