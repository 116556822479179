import React, { useState } from 'react';
import RatingModal from './RatingModal';

import happy from 'assets/img/rating/sanad/happy-face.png';
import neutral from 'assets/img/rating/sanad/neutral-face.png';
import unhappy from 'assets/img/rating/sanad/unhappy-face.png';

import { useTranslation } from 'react-i18next';

const RatingModalContainer = () => {
  const { t } = useTranslation();

  const ratingPoints = [
    { name: 'easeOfUse', title: t('rating_modal_easyToUse') },
    { name: 'performance', title: t('rating_modal_systemResponse') },
  ];

  const ratings = [
    { icon: happy, value: 'happy' },
    { icon: neutral, value: 'neutral' },
    { icon: unhappy, value: 'unhappy' },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [rating, setRating] = useState(
    ratingPoints.reduce(
      (final, { name }) => ({ ...final, [name]: 'happy' }),
      {},
    ) || {},
  );

  const openModal = () => {
    setIsOpen(true);
  };

  const handleCancel = () => {
    setIsOpen(false);
    setSubmitted(false);
  };

  const handleSubmit = () => {
    setSubmitted(true);
  };

  const updateRate = (point, rate) => {
    let temp = { ...rating };
    temp[point] = rate;
    setRating(temp);
  };

  return (
    <>
      <RatingModal
        openModal={openModal}
        isOpen={isOpen}
        onCancel={handleCancel}
        isSubmitted={isSubmitted}
        onSubmit={handleSubmit}
        ratingPoints={ratingPoints}
        ratings={ratings}
        updateRate={updateRate}
        rating={rating}
      />
    </>
  );
};

export default React.memo(RatingModalContainer);
