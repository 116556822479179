import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TOKEN_KEY, REFRESH_TOKEN } from 'constants/common';

import * as api from 'api/methods';

import { useUser } from 'context/user';

import { useLanguage } from 'hooks/useLanguage';

import LoginForm from './LoginForm';

const LoginFormContainer = (props) => {
  const history = useNavigate();
  const language = useLanguage();

  const { dispatchUser } = useUser();

  const [loading, setLoading] = useState(false);
  const [errorValue, setErrorValue] = useState('');

  const handleSubmit = (values) => {
    setLoading(true);

    api
      .loginUser(values)
      .then((data) => {
        sessionStorage.setItem(TOKEN_KEY, data.token);
        sessionStorage.setItem(REFRESH_TOKEN, data.refreshToken);
        localStorage.setItem(TOKEN_KEY, data.token);
        localStorage.setItem(REFRESH_TOKEN, data.refreshToken);

        api
          .getUserInfo()
          .then((user) => {
            dispatchUser({
              type: 'SET_USER',
              payload: user,
            });

            setLoading(false);

            history(`/${language}`);
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(({ error }) => {
        if (error) {
          setErrorValue(error);
        }

        setLoading(false);
      });
  };

  return (
    <LoginForm
      {...props}
      loading={loading}
      onSubmit={handleSubmit}
      errorValue={errorValue}
    />
  );
};

export default React.memo(LoginFormContainer);
