import { footerLinks } from './constants';
import i18n from '../../../config/i18n';

export const getFooterLinks = (type) => {
  return typeof footerLinks[type] === 'string'
    ? footerLinks[type]
    : footerLinks[type][i18n.language];
};

export const openSocialLinks = (link) => {
  window.open(link, '_blank') || window.location.assign(link);
};
