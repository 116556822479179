import React, { useState } from 'react';

import PasswordCell from './PasswordCell';

const PasswordCellContainer = (props) => {
  const [visible, setVisible] = useState(false);

  const handleIconClick = () => {
    setVisible(!visible);
  };

  return (
    <PasswordCell {...props} visible={visible} onIconClick={handleIconClick} />
  );
};

export default React.memo(PasswordCellContainer);
