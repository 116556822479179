import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useModal } from 'hooks/useModal';

import { FilterModal } from '../FilterModal';

import Filters from './Filters';

const FiltersContainer = ({
  onSubmitFilters,
  onResetFilters,
  onRemoveFilterClick,
  ...restProps
}) => {
  const [currentFilters, setCurrentFilters] = useState([]);
  const { t } = useTranslation();

  const [filterModalOpen, openFilterModal, closeFilterModal, filterModalData] =
    useModal({
      defaultOpen: false,
    });

  const handleFilterBtnClick = () => {
    openFilterModal({ currentFilters });
  };

  const handleRemoveFilterClick = (filter) => {
    const nextFilters = currentFilters.filter(
      (currentFilter) => currentFilter.type !== filter.type,
    );

    setCurrentFilters(nextFilters);

    const data = nextFilters.reduce((acc, nextFilter) => {
      acc[nextFilter.type] = nextFilter.value;

      return acc;
    }, {});

    if (onSubmitFilters) {
      onSubmitFilters(data);
    }

    onRemoveFilterClick(filter.type);
  };

  const handleSubmitFilters = (data) => {
    closeFilterModal();

    setCurrentFilters([]);

    if (data.noteType?.value) {
      setCurrentFilters((prevFilters) => [
        ...prevFilters,
        {
          type: 'noteType',
          text: `${data.noteType?.label} : ${t('filter_modal_notice_type')}`,
          value: data.noteType,
        },
      ]);
    } else {
      setCurrentFilters((prevFilters) =>
        prevFilters.filter((filter) => filter.type !== 'noteType'),
      );
    }

    if (data.invoiceType?.value) {
      setCurrentFilters((prevFilters) => [
        ...prevFilters,
        {
          type: 'invoiceType',
          text: `${data.invoiceType?.label} :${t(
            'filter_modal_invoice_type',
          )} `,
          value: data.invoiceType,
        },
      ]);
    } else {
      setCurrentFilters((prevFilters) =>
        prevFilters.filter((filter) => filter.type !== 'invoiceType'),
      );
    }

    if (data.activityNumber?.value) {
      setCurrentFilters((prevFilters) => [
        ...prevFilters,
        {
          type: 'activityNumber',
          text: `${data.activityNumber?.label} :  ${t(
            'filter_modal_input_source_sequence',
          )}`,
          value: data.activityNumber,
        },
      ]);
    } else {
      setCurrentFilters((prevFilters) =>
        prevFilters.filter((filter) => filter.type !== 'activityNumber'),
      );
    }

    if (data.invoiceStatus?.value) {
      setCurrentFilters((prevFilters) => [
        ...prevFilters,
        {
          type: 'invoiceStatus',
          text: `${data.invoiceStatus?.label} : ${t('Invoice_Kind')}`,
          value: data.invoiceStatus,
        },
      ]);
    } else {
      setCurrentFilters((prevFilters) =>
        prevFilters.filter((filter) => filter.type !== 'invoiceStatus'),
      );
    }

    if (data.issueDateFrom) {
      setCurrentFilters((prevFilters) => [
        ...prevFilters,
        {
          type: 'issueDateFrom',
          text: `${data.issueDateFrom} : ${t('filter_modal_date_from')}`,
          value: data.issueDateFrom,
        },
      ]);
    }

    if (data.issueDateTo) {
      setCurrentFilters((prevFilters) => [
        ...prevFilters,
        {
          type: 'issueDateTo',
          text: `${data.issueDateTo} : ${t('filter_modal_date_to')}`,
          value: data.issueDateTo,
        },
      ]);
    }

    if (data.totalAmountFrom) {
      setCurrentFilters((prevFilters) => [
        ...prevFilters,
        {
          type: 'totalAmountFrom',
          text: `${data.totalAmountFrom} :  ${t(
            'filter_modal_invoice_amount_from',
          )}`,
          value: data.totalAmountFrom,
        },
      ]);
    }

    if (data.totalAmountTo) {
      setCurrentFilters((prevFilters) => [
        ...prevFilters,
        {
          type: 'totalAmountTo',
          text: `${data.totalAmountTo} :   ${t(
            'filter_modal_invoice_amount_to',
          )}`,
          value: data.totalAmountTo,
        },
      ]);
    }

    if (data.username?.value) {
      setCurrentFilters((prevFilters) => [
        ...prevFilters,
        {
          type: 'username',
          text: `${data.username?.value} : ${t('filter_modal_invoice_editor')}`,
          value: data.username,
        },
      ]);
    } else {
      setCurrentFilters((prevFilters) =>
        prevFilters.filter((filter) => filter.type !== 'username'),
      );
    }

    if (onSubmitFilters) {
      onSubmitFilters(data);
    }
  };

  const handleClearFilters = () => {
    setCurrentFilters([]);
    onResetFilters();
    if (onSubmitFilters) {
      onSubmitFilters(null);
    }
  };

  return (
    <>
      <Filters
        {...restProps}
        currentFilters={currentFilters}
        onClearFilters={handleClearFilters}
        onRemoveFilterClick={handleRemoveFilterClick}
        onFilterBtnClick={handleFilterBtnClick}
      />
      <FilterModal
        {...filterModalData}
        open={filterModalOpen}
        onClose={closeFilterModal}
        onSubmitFilters={handleSubmitFilters}
      />
    </>
  );
};

export default React.memo(FiltersContainer);
