import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { UserProvider } from 'context/user';

import { SessionProvider } from 'components/SessionProvider';

import { HomePage } from 'pages/HomePage';
import { RegisterPage } from 'pages/RegisterPage';
import { LoginPage } from 'pages/LoginPage';
import { DevicesPage } from 'pages/DevicesPage';
import { InvoicesPage } from 'pages/InvoicesPage';
import { UsersPage } from 'pages/UsersPage';
import { NewInvoicePage } from 'pages/NewInvoicePage';
import { InvoicePage } from 'pages/InvoicePage';
import { ForgotPasswordPage } from 'pages/ForgotPasswordPage';
import { EditInvoicePage } from 'pages/EditInvoicePage';
import { ChangePasswordPage } from './pages/ChangePasswordPage';
import { ContactUs } from './pages/ContactUsPage';

import { useTranslation } from 'react-i18next';

import { SESSION_TIMEOUT } from 'constants/common';

import { SessionTimeoutModal } from './components/SessionTimeoutModal';

import { RatingModal } from './components/RatingModal';
import { PrivacyPage } from './pages/PrivacyPage';

const App = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const baseRoute = `/${i18n.language}`;

  const checkSession = () => {
    const expiry = sessionStorage.getItem(SESSION_TIMEOUT);
    const timeNow = new Date().getTime();
    if (expiry && expiry - timeNow <= 0) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const timer = setInterval(checkSession, 1000 * 60 * 15);
    return () => clearInterval(timer);
  }, []);

  return (
    <BrowserRouter>
      <UserProvider>
        <SessionProvider>
          <Routes>
            <Route exact path={`${baseRoute}`} element={<HomePage />} />
            <Route
              exact
              path={`${baseRoute}/register`}
              element={<RegisterPage />}
            />
            <Route
              exact
              path={`${baseRoute}/privacy`}
              element={<PrivacyPage />}
            />
            <Route exact path={`${baseRoute}/login`} element={<LoginPage />} />
            <Route
              exact
              path={`${baseRoute}/devices`}
              element={<DevicesPage />}
            />
            <Route
              exact
              path={`${baseRoute}/invoices`}
              element={<InvoicesPage />}
            />
            <Route
              exact
              path={`${baseRoute}/invoices/:invoiceId/:invoiceNumber`}
              element={<InvoicePage />}
            />
            <Route
              exact
              path={`${baseRoute}/invoices/:invoiceId/:invoiceNumber/:invoiceCreationType`}
              element={<InvoicePage />}
            />
            <Route exact path={`${baseRoute}/users`} element={<UsersPage />} />
            <Route
              exact
              path={`${baseRoute}/edit-invoice`}
              element={<EditInvoicePage />}
            />
            <Route
              exact
              path={`${baseRoute}/new-invoice/:invoiceGeneralType`}
              element={<NewInvoicePage />}
            />
            <Route
              exact
              path={`${baseRoute}/forgot-password`}
              element={<ForgotPasswordPage />}
            />
            <Route
              exact
              path={`${baseRoute}/change-password`}
              element={<ChangePasswordPage />}
            />
            <Route
              exact
              path={`${baseRoute}/contact-us`}
              element={<ContactUs />}
            />
            <Route
              path="*"
              element={<Navigate to={`${baseRoute}/login`} replace />}
            />
          </Routes>
          <ToastContainer hideProgressBar newestOnTop={false} closeOnClick />
          <SessionTimeoutModal
            closePopup={() => setIsOpen(false)}
            open={isOpen}
          />

          <RatingModal />
          {/* <Footer /> */}
        </SessionProvider>
      </UserProvider>
    </BrowserRouter>
  );
};

export default React.memo(App);
