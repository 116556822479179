import React from 'react';

import { Checkbox } from 'components/shared/Checkbox';

const CheckboxField = ({ haveError, field, form, ...restProps }) => {
  const handleChange = (event) => {
    form.setFieldValue(field.name, event.target.checked);
  };

  return (
    <Checkbox
      {...restProps}
      checked={field.value || false}
      name={field.name}
      onChange={handleChange}
    />
  );
};

export default React.memo(CheckboxField);
