import React from 'react';
import MobileMenu from './MobileMenu';
import { logoutUser } from '../../../../helpers/logUserOut';

const MobileMenuContainer = (props) => {
  const handleItemClick = (type) => {
    switch (type) {
      case 'logout': {
        logoutUser();
        break;
      }
    }
  };

  return <MobileMenu {...props} onItemClick={handleItemClick} />;
};

export default React.memo(MobileMenuContainer);
