import React from 'react';
import { Formik, Form } from 'formik';

import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';

import { FormikField } from 'components/FormikField';
import { InputField, SelectField } from 'components/FormikFields';

import { validationSchema, initialValues } from './AddDeviceModal.formConfig';

import styles from './AddDeviceModal.styles.scss';
import { useTranslation } from 'react-i18next';

import { useLanguage } from 'hooks/useLanguage';
import { AttentionModal } from 'components/AttentionModal';

const AddDeviceModal = ({
  loading,
  open,
  user,
  loadActivityOptions,
  onClose,
  onFormSubmit,
  openAttention,
  onCloseAttentionModal,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const language = useLanguage();

  return (
    <>
      <Modal
        className={styles.modalWrapper}
        closeClassName={styles.modalCloseIcon}
        open={open}
        centered
        onClose={onClose}
      >
        <div className={styles.modal}>
          <Typography className={styles.title} weight="bold">
            {t('devices_modal_new_integrate')}
          </Typography>
          <Formik
            validateOnMount
            enableReinitialize
            validationSchema={validationSchema(t)}
            initialValues={initialValues}
            onSubmit={onFormSubmit}
          >
            {() => (
              <Form className={styles.form}>
                {/* <div className={styles.formRow}> */}
                <div
                  className={language === 'ar' ? 'row flex-row-reverse' : 'row'}
                >
                  <div class="col-md-6 col-sm-12">
                    <FormikField
                      name="deviceName"
                      component={InputField}
                      componentProps={{ className: styles.field }}
                      label={t('usersmodal_user_name')}
                    />
                  </div>

                  <div class="col-md-6 col-sm-12 ">
                    <FormikField
                      name="activityNumber"
                      component={SelectField}
                      componentProps={{
                        className: styles.field,
                        type: 'async',
                        cacheOptions: true,
                        defaultOptions: true,
                        loadOptions: loadActivityOptions,
                      }}
                      label={t('usersmodal_activity_number')}
                    />
                  </div>
                </div>

                <div className={styles.actions}>
                  <Button
                    className={styles.action}
                    variant="outlined"
                    onClick={onClose}
                  >
                    {t('usersmodal_cancel')}
                  </Button>
                  <Button
                    className={styles.action}
                    loading={loading}
                    type="submit"
                  >
                    {t('usersmodal_add')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
      <AttentionModal
        title={t('device_attention_modal_title')}
        text={t('device_attention_modal_text')}
        onConfirm={onConfirm}
        open={openAttention}
        onClose={onCloseAttentionModal}
      />
    </>
  );
};

export default React.memo(AddDeviceModal);
