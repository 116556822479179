import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/shared/Typography';

import { AuthLayout } from 'components/AuthLayout';

import { RegisterForm } from './components/RegisterForm';

import styles from './RegisterPage.styles.scss';

const RegisterPage = () => {
  const { t } = useTranslation();

  return (
    <AuthLayout isRegister>
      <Typography weight="bold" className={styles.title}>
        {t('register_page_title')}
      </Typography>
      <RegisterForm />
    </AuthLayout>
  );
};

export default React.memo(RegisterPage);
