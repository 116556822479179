import React from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';

import { INVOICE_TYPES_ENUM } from 'constants/common';

import {
  TAX_RATE_OPTIONS,
  TYPE_OPTIONS,
  ISIC_4_OPTIONS,
} from 'config/invoice/config';

import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';

import { FormikField } from 'components/FormikField';
import { InputField, SelectField } from 'components/FormikFields';

import {
  validationSchema,
  initialValues,
} from './EditInvoiceProductModal.formConfig';

import styles from 'assets/styles/invoice.scss';

import innerStyles from './EditInvoiceProductModal.styles.scss';

const EditInvoiceProductModal = ({
  open,
  loading,
  item,
  invoiceType,
  onClose,
  onFormSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose}>
      <div className={innerStyles.modal}>
        <Typography className={innerStyles.title} weight="bold">
          {t('editInvoiceProduct_title')}
        </Typography>
        <Formik
          validateOnMount
          enableReinitialize
          validationSchema={validationSchema(t)}
          initialValues={item || initialValues}
          onSubmit={onFormSubmit}
        >
          {() => (
            <Form className={innerStyles.form}>
              <FormikField
                name="isic4"
                component={SelectField}
                componentProps={{
                  controlClassName: styles.selectControl,
                  fieldClassName: styles.groupField,
                  options: ISIC_4_OPTIONS(t),
                }}
                controlProps={{
                  labelProps: {
                    className: styles.groupControlLabel,
                  },
                }}
                required
                label={t('newInvoice_isic4')}
              />
              {invoiceType !== INVOICE_TYPES_ENUM.CASH_INCOME &&
                invoiceType !== INVOICE_TYPES_ENUM.RECEIVABLE_INCOME && (
                  <FormikField
                    name="type"
                    component={SelectField}
                    componentProps={{
                      controlClassName: styles.selectControl,
                      fieldClassName: styles.groupField,
                      options: TYPE_OPTIONS(t),
                    }}
                    controlProps={{
                      labelProps: {
                        className: styles.groupControlLabel,
                      },
                    }}
                    required
                    label={t('newInvoice_type')}
                  />
                )}
              {item?.type?.value === 'PRODUCT' && (
                <>
                  <FormikField
                    name="description"
                    component={InputField}
                    componentProps={{
                      fieldClassName: styles.groupField,
                    }}
                    controlProps={{
                      labelProps: {
                        className: styles.groupControlLabel,
                      },
                    }}
                    required
                    label={t('newInvoice_description_goodOrService')}
                  />
                  <FormikField
                    name="quantity"
                    component={InputField}
                    componentProps={{
                      fieldClassName: styles.groupField,
                      type: 'number',
                    }}
                    controlProps={{
                      labelProps: {
                        className: styles.groupControlLabel,
                      },
                    }}
                    required
                    label={t('newInvoice_quantity')}
                  />
                </>
              )}
              <FormikField
                name="unitPrice"
                component={InputField}
                componentProps={{
                  fieldClassName: styles.groupField,
                  type: 'number',
                }}
                controlProps={{
                  labelProps: {
                    className: styles.groupControlLabel,
                  },
                }}
                required
                label={t('newInvoice_unitPrice')}
              />
              {item?.type?.value === 'PRODUCT' && (
                <FormikField
                  name="discountAmount"
                  component={InputField}
                  componentProps={{
                    fieldClassName: styles.groupField,
                    type: 'number',
                  }}
                  controlProps={{
                    labelProps: {
                      className: styles.groupControlLabel,
                    },
                  }}
                  label={t('newInvoice_discount_value')}
                />
              )}
              {(invoiceType === INVOICE_TYPES_ENUM.CASH_SPECIAL_TAX ||
                invoiceType === INVOICE_TYPES_ENUM.RECEIVABLE_SPECIAL_TAX) && (
                <FormikField
                  name="specialTaxAmount"
                  component={InputField}
                  componentProps={{
                    fieldClassName: styles.groupField,
                  }}
                  controlProps={{
                    labelProps: {
                      className: styles.groupControlLabel,
                    },
                  }}
                  label={t('newInvoice_special_tax_amount')}
                />
              )}

              {invoiceType !== INVOICE_TYPES_ENUM.CASH_INCOME &&
                invoiceType !== INVOICE_TYPES_ENUM.RECEIVABLE_INCOME && (
                  <FormikField
                    name="generalTaxPercentage"
                    component={SelectField}
                    componentProps={{
                      controlClassName: styles.selectControl,
                      fieldClassName: styles.groupField,
                      options: TAX_RATE_OPTIONS(t),
                    }}
                    controlProps={{
                      labelProps: {
                        className: styles.groupControlLabel,
                      },
                    }}
                    required
                    label={t('newInvoice_general_tax_rate')}
                  />
                )}
              <div className={innerStyles.actions}>
                <Button
                  className={innerStyles.action}
                  variant="outlined"
                  onClick={onClose}
                >
                  {t('usersmodal_cancel')}
                </Button>
                <Button
                  className={innerStyles.action}
                  loading={loading}
                  type="submit"
                >
                  {t('usersmodal_edit')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default React.memo(EditInvoiceProductModal);
