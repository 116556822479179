import FacebookIcon from 'assets/icons/facebook.svg';
import InstgramIcon from 'assets/icons/instagram.svg';
import TwitterIcon from 'assets/icons/twitter.svg';
import YoutubeIcon from 'assets/icons/youtube.svg';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getFooterLinks, openSocialLinks } from './config/helpers';
import styles from './Footer.styles.scss';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.footer}>
      <div className={styles.socialResponsive + ''}>
        <InstgramIcon
          onClick={() => openSocialLinks(getFooterLinks('instagram'))}
          className={styles.icon + ' ' + styles.instagram}
        />
        <FacebookIcon
          onClick={() => openSocialLinks(getFooterLinks('facebook'))}
          className={styles.icon + ' ' + styles.facebook}
        />
        <TwitterIcon
          onClick={() => openSocialLinks(getFooterLinks('twitter'))}
          className={styles.icon + ' ' + styles.twitter}
        />
        <YoutubeIcon
          onClick={() => openSocialLinks(getFooterLinks('youTube'))}
          className={styles.icon + ' ' + styles.youTube}
        />
      </div>
      <div>
        {t('footer_copywrite', { date: format(new Date(), 'yyyy') })}{' '}
        <h6 className="text-white text-decoration-none mt-3">
          {t('footer_developedBy')}{' '}
          <a
            href={getFooterLinks('agile')}
            target="_blank"
            rel="noreferrer"
            className="text-white text-decoration-none"
          >
            {t('footer_developer')}
          </a>
        </h6>
      </div>

      <div className={styles.links}>
        <div className="row">
          <a href="./privacy" rel="noreferrer" className={styles.hovered}>
            {t('footer_links_privacy')}
          </a>

          <a
            href={getFooterLinks('terms')}
            target="_blank"
            rel="noreferrer"
            className={styles.hovered}
          >
            {t('footer_links_terms')}
          </a>

          <a href="contact-us" rel="noreferrer" className={styles.hovered}>
            {t('footer_links_contact_us')}
          </a>
        </div>
        <div className="row">
          <a
            href={getFooterLinks('disclaimer')}
            target="_blank"
            rel="noreferrer"
            className={styles.hovered}
          >
            {t('footer_links_responsibilty')}
          </a>
          <a
            href={getFooterLinks('copyRights')}
            target="_blank"
            rel="noreferrer"
            className={styles.hovered}
          >
            {t('footer_links_rights')}
          </a>
          <a
            href={getFooterLinks('userManual')}
            target="_blank"
            rel="noreferrer"
            className={styles.hovered}
          >
            {t('footer_links_user_manual')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
