import React from 'react';
import Lottie from 'lottie-react';

import attentionAnimation from 'assets/animations/attention.json';

import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';

import styles from './AttentionModal.styles.scss';

import { useTranslation } from 'react-i18next';

const AttentionModal = ({ open, onClose, title, text, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose}>
      <div className={styles.modal}>
        <Lottie
          className={styles.animation}
          animationData={attentionAnimation}
          loop
        />
        <Typography className={styles.title}>{title}</Typography>
        <Typography className={styles.text}>{text}</Typography>
        <Button className={styles.button} onClick={onConfirm}>
          {t('attention_modal_continue')}
        </Button>
      </div>
    </Modal>
  );
};

export default React.memo(AttentionModal);
