import * as Yup from 'yup';

export const validationSchema = (t) =>
  Yup.object().shape({
    taxNumber: Yup.string()
      .matches(/^\d+$/, t('login_form_validation_taxnumber'))
      .typeError(t('login_form_validation_taxnumber'))
      .required(t('login_form_validation_taxnumber_required')),
  });

export const initialValues = {
  taxNumber: '',
};

export default {
  initialValues,
  validationSchema,
};
