import React from 'react';
import Tippy from '@tippyjs/react/headless';
import cn from 'classnames';

import styles from './Popover.styles.scss';

const Popover = ({
  className,
  children,
  visible,
  shouldPlayAnimation,
  appendTo,
  interactive,
  mode,
  trigger,
  placement,
  content,
  onMount,
  onHide,
  onTriggerClick,
  onOutsideClick,
}) => {
  const tippyRender = (attrs) => (
    <div
      className={cn(styles.popover, className)}
      data-state={shouldPlayAnimation ? 'visible' : 'hidden'}
      data-animation="shift-toward"
      style={{
        transitionDuration: shouldPlayAnimation ? '300ms' : '0',
      }}
      {...attrs}
    >
      {content}
    </div>
  );

  const tippyProps =
    mode === 'uncontrolled'
      ? {
          appendTo,
          placement,
          animation: true,
          interactive,
          trigger,
          onMount,
          onHide,
          render: tippyRender,
        }
      : {
          appendTo,
          visible,
          placement,
          animation: true,
          interactive,
          onMount,
          onHide,
          onClickOutside: onOutsideClick,
          render: tippyRender,
        };

  return (
    <Tippy {...tippyProps}>
      {typeof children === 'function'
        ? children({ visible, onTriggerClick })
        : children}
    </Tippy>
  );
};

Popover.defaultProps = {
  appendTo: 'parent',
};

export default React.memo(Popover);
