import React from 'react';
import styles from './UploadLogo.styles.scss';
import { Image } from 'primereact/image';
import { FileUpload } from 'primereact/fileupload';
import UploadUserLogo from 'assets/img/uploadLogo.svg';
import { Loading } from 'components/shared/Loading';

const UploadLogo = ({ loading, previewImage, uploadHandler, t }) => {
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {!previewImage && !loading ? (
            <UploadUserLogo />
          ) : (
            <Image
              width="125px"
              src={previewImage}
              preview={{
                mask: (
                  <span>
                    <i className="pi pi-eye" />
                  </span>
                ),
              }}
            />
          )}
          <FileUpload
            mode="basic"
            name="file"
            accept=".jpeg,.jpg,.png"
            uploadHandler={uploadHandler}
            auto
            customUpload={true}
            chooseLabel={t('user_logo_upload_logo')}
            chooseOptions={{
              className: styles.chooseFile,
            }}
          />
        </>
      )}
    </>
  );
};

export default React.memo(UploadLogo);
