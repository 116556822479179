import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'hooks/useLanguage';
import ReactTooltip from 'react-tooltip';
import { FaSearch } from 'react-icons/fa';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';

import { FormikField } from 'components/FormikField';
import { InputField, SelectField } from 'components/FormikFields';

import { DOCUMENT_TYPE_OPTIONS, PROVINCE_OPTIONS } from 'config/invoice/config';

import styles from 'assets/styles/invoice.scss';
import { useFormikContext } from 'formik';
import { isTaxNumberValid } from '../../api/methods';

const BuyerIdTypeLabel = () => {
  const { t } = useTranslation();

  return (
    <>
      <span data-tip data-for="buyerTypeID" className={styles.labelIcon}>
        <Icon name="info" />
      </span>{' '}
      {t('newInvoice_buyerIDType')}
      <ReactTooltip id="buyerTypeID" type="dark">
        <span>{t('newInvoice_buyerIDTypeTooltip')}</span>
      </ReactTooltip>
    </>
  );
};

const InvoiceBuyerSection = ({ isBuyerFieldsRequired, isEdit }) => {
  const { t } = useTranslation();
  const language = useLanguage();
  const { values, handleChange, errors, validateField } = useFormikContext();

  const [isTaxNumberValidError, setIsTaxNumberValidError] = useState('');
  const [isTaxNumberValidMsg, setIsTaxNumberValidMsg] = useState('');

  const iconClickHandler = () => {
    isTaxNumberValid(values.buyer.additionalBuyerId).then((res) => {
      if (res === 'TRUE') {
        setIsTaxNumberValidError('');
        setIsTaxNumberValidMsg(t('tax_number_valid'));
      } else {
        setIsTaxNumberValidError(t('tax_number_not_valid'));
        setIsTaxNumberValidMsg('');
      }

      setTimeout(() => {
        validateField('buyer.isTaxNumberValid');
      }, 200);
    });
  };
  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      iconClickHandler();
    }
  };
  console.log(errors);
  return (
    <div className={styles.section}>
      <Typography weight="bold" className={styles.title}>
        {t('newInvoice_buyerData')}
      </Typography>
      <div className={language === 'ar' ? 'row flex-row-reverse' : 'row'}>
        <div class="col-md-4 col-sm-12">
          <FormikField
            name="buyer.name"
            component={InputField}
            controlProps={{
              labelProps: {
                className: styles.fieldLabel,
              },
            }}
            componentProps={{
              disabled: isEdit,
            }}
            required={isBuyerFieldsRequired}
            label={t('newInvoice_name')}
          />
        </div>

        <div class="col-md-4 col-sm-12">
          {!isEdit ? (
            <FormikField
              name="buyer.additionalBuyerIdType"
              component={SelectField}
              componentProps={{
                options: DOCUMENT_TYPE_OPTIONS(t),
                disabled: isEdit,
                isDisabled: values.invoiceKind?.value === 'DEVELOPMENTAL',
              }}
              controlProps={{
                labelProps: {
                  className: styles.fieldLabel,
                },
              }}
              // required={isBuyerFieldsRequired}
              label={<BuyerIdTypeLabel />}
            />
          ) : (
            <FormikField
              name="buyer.additionalBuyerIdType"
              component={InputField}
              controlProps={{
                labelProps: {
                  className: styles.fieldLabel,
                },
              }}
              componentProps={{
                disabled: isEdit,
              }}
              label={<BuyerIdTypeLabel />}
            />
          )}
        </div>

        <div class="col-md-4 col-sm-12">
          <FormikField
            name="buyer.additionalBuyerId"
            component={InputField}
            controlProps={{
              labelProps: {
                className: styles.fieldLabel,
              },
              customError:
                values.invoiceKind?.value === 'DEVELOPMENTAL'
                  ? isTaxNumberValidError
                  : '',
              successNote:
                values.invoiceKind?.value === 'DEVELOPMENTAL'
                  ? isTaxNumberValidMsg
                  : '',
            }}
            componentProps={
              values.invoiceKind?.value === 'DEVELOPMENTAL' && !isEdit
                ? {
                    iconElement: <FaSearch className="searchIcon" />,
                    iconClickHandler,
                    onKeyDown: handleSearchKeyPress,
                    onChange: (e) => {
                      handleChange(e);
                      setIsTaxNumberValidError(
                        t('please_click_on_search_to_check'),
                      );
                      setIsTaxNumberValidMsg('');
                    },
                    disabled: isEdit,
                  }
                : {
                    disabled: isEdit,
                  }
            }
            required={values.invoiceKind?.value === 'DEVELOPMENTAL'}
            label={
              values.buyer.additionalBuyerIdType &&
              values.buyer.additionalBuyerIdType.value
                ? values.buyer.additionalBuyerIdType.label
                : t('invoice_page_buyer_number')
            }
            // label={t('newInvoice_buyerIDNumber')}
          />
        </div>
        <div class="col-md-4 col-sm-12 d-none">
          <FormikField
            name="buyer.isTaxNumberValid"
            component={InputField}
            controlProps={{
              labelProps: {
                className: styles.fieldLabel,
              },
            }}
            validate={(value) => {
              if (
                values.invoiceKind?.value === 'DEVELOPMENTAL' &&
                isTaxNumberValidError &&
                !isEdit
              ) {
                return isTaxNumberValidError;
              }
            }}
            componentProps={{}}
            label={''}
          />
        </div>
        <div class="col-md-4 col-sm-12">
          <FormikField
            name="buyer.phoneNumber"
            component={InputField}
            controlProps={{
              labelProps: {
                className: styles.fieldLabel,
              },
            }}
            componentProps={{
              disabled: isEdit,
            }}
            // required={isBuyerFieldsRequired}
            label={t('newInvoice_phone_number')}
          />
        </div>
        <div class="col-md-4 col-sm-12">
          {!isEdit ? (
            <FormikField
              name="buyer.province"
              component={SelectField}
              componentProps={{
                options: PROVINCE_OPTIONS(),
                disabled: isEdit,
              }}
              controlProps={{
                labelProps: {
                  className: styles.fieldLabel,
                },
              }}
              // required={isBuyerFieldsRequired}
              label={t('newInvoice_city')}
            />
          ) : (
            <FormikField
              name="buyer.province"
              component={InputField}
              controlProps={{
                labelProps: {
                  className: styles.fieldLabel,
                },
              }}
              componentProps={{
                disabled: isEdit,
              }}
              label={t('newInvoice_city')}
            />
          )}
        </div>

        <div class="col-md-4 col-sm-12">
          <FormikField
            name="buyer.postalCode"
            component={InputField}
            controlProps={{
              labelProps: {
                className: styles.fieldLabel,
              },
            }}
            componentProps={{
              disabled: isEdit,
            }}
            label={t('newInvoice_postalCode')}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(InvoiceBuyerSection);
