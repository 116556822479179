export const convertDataToForm = (data) => {
  if (!data) {
    return null;
  }

  return {
    username: data.username,
    password: data.password,
    notes: data.notes,
    activityNumber: {
      label: data.activityNumber?.activity,
      value: data.activityNumber,
    },
  };
};
