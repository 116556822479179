import React from 'react';
import cn from 'classnames';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';
import { Checkbox } from 'components/shared/Checkbox';
import { Loading } from 'components/shared/Loading';

import styles from './Select.styles.scss';
import { useTranslation } from 'react-i18next';

const MODES = {
  default: 'default',
  portal: 'portal',
};

const TYPES = {
  default: ReactSelect,
  async: AsyncSelect,
};

const portalStyles = { menuPortal: (base) => ({ ...base, zIndex: 9999 }) };

const Control = React.memo(
  ({
    children,
    controlClassName,
    startAdornmentClassName,
    innerProps,
    menuIsOpen,
    innerRef,
    haveError,
    isFocused,
    startAdornment,
    ...restProps
  }) => {
    return (
      <div
        {...innerProps}
        ref={innerRef}
        className={cn(styles.control, controlClassName, {
          [styles.controlFocused]: isFocused,
          [styles.controlHaveError]: haveError,
          [styles.activeControl]: menuIsOpen,
        })}
      >
        {startAdornment && (
          <div className={cn(styles.startAdornment, startAdornmentClassName)}>
            {startAdornment}
          </div>
        )}
        {children}
      </div>
    );
  },
);

const DropdownIndicator = React.memo(() => (
  <Icon name="chevronDown" className={styles.indicator} />
));

const ClearIndicator = React.memo(({ innerProps }) => (
  <Typography {...innerProps} weight="medium" className={styles.clear}>
    صافي
  </Typography>
));

const Placeholder = React.memo(({ innerProps, children }) => (
  <Typography {...innerProps} className={styles.placeholder}>
    {children}
  </Typography>
));

const ValueContainer = React.memo(({ innerProps, children, ...restProps }) => (
  <div {...innerProps} className={styles.valueContainer}>
    {children}
  </div>
));

const SingleValue = React.memo(({ innerProps, children }) => (
  <Typography {...innerProps}>{children}</Typography>
));

const MultiValueLabel = React.memo(({ innerProps, children }) => (
  <Typography {...innerProps} className={cn(styles.label)}>
    {children}
  </Typography>
));

const MultiValueRemove = React.memo(({ innerProps }) => (
  <div {...innerProps} className={styles.removeContainer}>
    <Icon name="cross" className={styles.removeIcon} />
  </div>
));

const Menu = React.memo(({ innerProps, children }) => (
  <div {...innerProps} className={styles.menuList}>
    {children}
  </div>
));

const Option = React.memo(({ innerProps, children, isSelected, isMulti }) => (
  <div {...innerProps} className={styles.option}>
    {isMulti && (
      <Checkbox
        checked={isSelected}
        className={styles.checkbox}
        onChange={() => {}}
      />
    )}
    {children}
  </div>
));

const NoOptionsMessage = ({ innerProps }) => {
  const { t } = useTranslation();
  return (
    <Typography {...innerProps} className={styles.noOption}>
      {t('select_noChoices')}
    </Typography>
  );
};

const MultiValueContainer = React.memo(({ innerProps, children }) => (
  <div {...innerProps} className={styles.tag}>
    {children}
  </div>
));

const LoadingIndicator = React.memo(() => (
  <Loading className={styles.loading} />
));

const IndicatorsContainer = React.memo((props) => (
  <div className={styles.indicatorsContainer}>{props.children}</div>
));

const Select = ({
  controlClassName,
  startAdornmentClassName,
  className,
  loading,
  multi,
  searchable,
  type,
  mode,
  placeholder,
  options,
  variant,
  startAdornment,
  haveError,
  defaultValue,
  isDisabled,
  ...props
}) => {
  const { t } = useTranslation();
  const components = {
    Control: (controlProps) => (
      <Control
        controlClassName={controlClassName}
        startAdornmentClassName={startAdornmentClassName}
        haveError={haveError}
        startAdornment={startAdornment}
        {...controlProps}
      />
    ),
    DropdownIndicator,
    ClearIndicator,
    Placeholder,
    SingleValue,
    MultiValueLabel,
    MultiValueRemove,
    Menu,
    Option,
    NoOptionsMessage,
    MultiValueContainer,
    ValueContainer,
    LoadingIndicator,
    IndicatorsContainer,
    IndicatorSeparator: null,
  };

  const commonProps = {
    // defaultMenuIsOpen: true,
    isLoading: loading,
    isMulti: multi,
    defaultValue: defaultValue,
    isDisabled: isDisabled,
    isSearchable: searchable,
    hideSelectedOptions: !multi,
    closeMenuOnSelect: !multi,
    placeholder: loading ? 'Loading...' : t('select_select'),
    options: loading ? Object.freeze([]) : options,
    components,
  };

  const SelectComponent = TYPES[type];

  switch (MODES[mode]) {
    case MODES.default: {
      return <SelectComponent {...commonProps} {...props} />;
    }
    case MODES.portal: {
      return (
        <SelectComponent
          {...commonProps}
          {...props}
          menuPortalTarget={document.body}
          menuPlacement="auto"
          menuPosition="fixed"
          menuShouldBlockScroll
          styles={portalStyles}
          className={cn(
            styles.container,
            className,
            isDisabled ? styles['disabled-select'] : '',
          )}
        />
      );
    }
  }
};

Select.defaultProps = {
  mode: 'portal',
  type: 'default',
  searchable: false,
  multi: false,
};

export default React.memo(Select);
