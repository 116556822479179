import React, { useState } from 'react';

import { Input } from 'components/shared/Input';
import { Icon } from 'components/shared/Icon';

import styles from './PasswordField.styles.scss';

const PasswordField = ({ field, form, ...restProps }) => {
  const [visible, setVisible] = useState(false);

  const handleIconClick = () => {
    setVisible(!visible);
  };

  return (
    <Input
      {...field}
      {...restProps}
      startAdornment={
        !visible ? (
          <Icon className={styles.icon} name="eye" onClick={handleIconClick} />
        ) : (
          <Icon
            className={styles.icon}
            name="crossEye"
            onClick={handleIconClick}
          />
        )
      }
      type={visible ? 'text' : 'password'}
    />
  );
};

export default React.memo(PasswordField);
