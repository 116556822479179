import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import parse from 'date-fns/parse';
import format from 'date-fns/format';

import BillsIcon from 'assets/img/bills.svg';

import { useLanguage } from 'hooks/useLanguage';

import { Table } from 'components/shared/Table';
import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';
import { Link } from 'components/shared/Link';

import { MainLayout } from 'components/MainLayout';
import { Breadcrumbs } from 'components/Breadcrumbs';

import { Info } from './components/Info';
import { Filters } from './components/Filters';

import { INVOICES_COLUMNS } from './InvoicesPage.config';

import {
  getInvoiceStatusTextByCode,
  getInvoiceNoteTypeTextByCode,
  getInvoiceTypeTextByCode,
  getInvoiceKindTextByCode,
} from './InvoicesPage.helpers';

import styles from './InvoicesPage.styles.scss';

import { Button } from 'components/shared/Button';

const cellRenderer = (
  { className, item, itemIndex, key, minWidth, maxWidth },
  DefaultCell,
  { language, onDownloadItemClick, downloadInvoicePdfLoading = false },
) => {
  switch (key) {
    case 'order': {
      return (
        <DefaultCell
          key={key}
          minWidth={minWidth}
          maxWidth={maxWidth}
          className={className}
        >
          {itemIndex + 1}
        </DefaultCell>
      );
    }
    case 'action': {
      return (
        <div
          className={cn(styles.actionCell, className)}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <Icon
            className={styles.downloadIcon}
            name="download"
            onClick={() =>
              !downloadInvoicePdfLoading && onDownloadItemClick(item)
            }
          />
        </div>
      );
    }
    case 'issueDate': {
      const issueDate = format(
        parse(item[key], 'dd-MM-yyyy', new Date()),
        'dd MMM yyyy',
      );

      return (
        <DefaultCell
          key={key}
          minWidth={minWidth}
          maxWidth={maxWidth}
          className={className}
        >
          {issueDate}
        </DefaultCell>
      );
    }
    case 'invoiceTotal': {
      return (
        <DefaultCell
          key={key}
          minWidth={minWidth}
          maxWidth={maxWidth}
          className={className}
        >
          {item[key]}
        </DefaultCell>
      );
    }
    case 'invoiceStatus': {
      const invoiceStatus = getInvoiceStatusTextByCode({
        code: item[key],
        language,
      });

      return (
        <DefaultCell
          key={key}
          minWidth={minWidth}
          maxWidth={maxWidth}
          className={className}
        >
          {invoiceStatus}
        </DefaultCell>
      );
    }
    case 'noteType': {
      const noteType = getInvoiceNoteTypeTextByCode({
        code: item[key],
        language,
      });

      return (
        <DefaultCell
          key={key}
          minWidth={minWidth}
          maxWidth={maxWidth}
          className={className}
        >
          {noteType || '-'}
        </DefaultCell>
      );
    }
    case 'invoiceKind': {
      const invoiceKind = getInvoiceKindTextByCode({
        code: item[key],
        language,
      });

      return (
        <DefaultCell
          key={key}
          minWidth={minWidth}
          maxWidth={maxWidth}
          className={className}
        >
          {invoiceKind || '-'}
        </DefaultCell>
      );
    }
    case 'invoiceType': {
      const invoiceType = getInvoiceTypeTextByCode({
        code: item[key],
        language,
      });

      return (
        <DefaultCell
          key={key}
          minWidth={minWidth}
          maxWidth={maxWidth}
          className={className}
        >
          <Link
            to={
              `/${language}/invoices/${item.id}/${item.invoiceNumber}` +
              (item.noteType ? '/edit' : '')
            }
          >
            {invoiceType}
          </Link>
        </DefaultCell>
      );
    }
    default: {
      return (
        <DefaultCell
          key={key}
          minWidth={minWidth}
          maxWidth={maxWidth}
          className={className}
        >
          {item[key] || '-'}
        </DefaultCell>
      );
    }
  }
};

const InvoicesPage = ({
  loading,
  invoices,
  generatedThisYear,
  generatedInvoices,
  generatedNotes,
  onSearchQueryChange,
  totalOfTotals,
  onSubmitFilters,
  onResetFilters,
  onDownloadItemClick,
  getPageCount,
  totalPages,
  onRemoveFilterClick,
  onDownloadReportHandler,
  downloading,
  totalItems,
  downloadInvoicePdfLoading,
}) => {
  const { t } = useTranslation();

  const language = useLanguage();

  return (
    <MainLayout loading={loading}>
      <div className={styles.page}>
        <div className={styles.header}>
          <Breadcrumbs
            routeName={t('invoices_view_invoices')}
            routeIcon={BillsIcon}
          />
        </div>
        {/* {loading ? (
          <Loading className={styles.loading} />
        ) : ( */}
        <>
          <Info
            generatedThisYear={generatedThisYear}
            generatedInvoices={generatedInvoices}
            generatedNotes={generatedNotes}
            totalOfTotals={totalOfTotals}
          />
          <div className={styles.inner}>
            <div className={styles.filtersAndDownload}>
              <Filters
                onSearchQueryChange={onSearchQueryChange}
                onSubmitFilters={onSubmitFilters}
                onResetFilters={onResetFilters}
                onRemoveFilterClick={onRemoveFilterClick}
                onDownloadReportHandler={onDownloadReportHandler}
              />
              <div>
                <Button
                  onClick={onDownloadReportHandler}
                  loading={downloading}
                  className="px-5"
                >
                  {t('invoices_download_invoices')}
                </Button>
                {/* !hasAtLeastTwoNonNullValues(params) */}
              </div>
            </div>
            {/* {hasAtLeastTwoNonNullValues() && (
              )} */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p>{t('invoice_download_limit')}</p>
              <p>{totalItems && t('invoice_number') + ': ' + totalItems}</p>
            </div>
            {invoices.length || loading ? (
              <Table
                loading={loading}
                columns={
                  language === 'en'
                    ? INVOICES_COLUMNS(t).reverse()
                    : INVOICES_COLUMNS(t)
                }
                data={invoices}
                cellRenderer={(props, DefaultCell) =>
                  cellRenderer(props, DefaultCell, {
                    language,
                    onDownloadItemClick,
                    downloadInvoicePdfLoading,
                  })
                }
                cellClassName={styles.text}
                headerTextClassName={styles.headerText}
                withPagination={true}
                getPageCount={getPageCount}
                totalPages={totalPages}
                fitContent={true}
              />
            ) : (
              <Typography className={styles.emptyText}>
                {t('invoices_no_invoices')}
              </Typography>
            )}
          </div>
        </>
      </div>
    </MainLayout>
  );
};

export default React.memo(InvoicesPage);
