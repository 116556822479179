import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/shared/Typography';

import { AuthLayout } from 'components/AuthLayout';

import { LoginForm } from './components/LoginForm';

import styles from './LoginPage.styles.scss';

const LoginPage = () => {
  const { t } = useTranslation();

  return (
    <AuthLayout>
      <Typography weight="bold" className={styles.title}>
        {t('login_page_sign_in')}
      </Typography>
      <LoginForm />
    </AuthLayout>
  );
};

export default React.memo(LoginPage);
