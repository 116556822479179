import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TOKEN_KEY } from 'constants/common';

import * as api from 'api/methods';

import { useUser } from 'context/user';

import { useQuery } from 'hooks/useQuery';
import { useLanguage } from 'hooks/useLanguage';
import { showToast } from 'helpers/showToast';

import RegisterForm from './RegisterForm';

const RegisterFormContainer = (props) => {
  const query = useQuery();
  const history = useNavigate();
  const language = useLanguage();

  const { dispatchUser } = useUser();

  const [taxNumberLoading, setTaxNumberLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [taxNumber, setTaxNumber] = useState('');

  const token = query.get('q');

  // useEffect(() => {
  //   api
  //     .getTaxNumber(token)
  //     .then((data) => {
  //       if (data && data.error) {
  //         // that's because 409 http status not reflected in catch because of using fetch not axios.
  //         history(`/${language}/login`, { replace: true });
  //       }
  //       setTaxNumber(data.taxNumber);

  //       setTaxNumberLoading(false);
  //     })
  //     .catch((error) => {
  //       if (error) {
  //         history(`/${language}/login`, { replace: true });
  //         showToast({
  //           data: {
  //             variant: 'success',
  //             text: 'Taxpayer already registered before',
  //           },
  //         });
  //       }

  //       setTaxNumberLoading(false);
  //     });
  // }, []);
  useEffect(() => {
    api
      .getTaxNumber(token)
      .then((data) => {
        if (data && data.status === false) {
          // Redirect to login page when status is false
          setTaxNumber(data.taxNumber);

          history(`/${language}/login`, { replace: true });
          showToast({
            data: {
              variant: 'success',
              text: 'Taxpayer already registered before',
            },
          });
        } else {
          // Set taxNumber when status is true
          setTaxNumber(data.taxNumber);
        }

        setTaxNumberLoading(false);
      })
      .catch((error) => {
        // Handle other errors, e.g., network issues
        history(`/${language}/login`, { replace: true });

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          showToast({
            data: {
              variant: 'error',
              text:
                'Response Data :' +
                error.response.data +
                '// Response Status : ' +
                error.response.status,
            },
          });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // error.request is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          showToast({
            data: {
              variant: 'error',
              text: error.request,
            },
          });
          console.log(error.request);
        } else {
          showToast({
            data: {
              variant: 'error',
              text: error.message,
            },
          });
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }

        setTaxNumberLoading(false);
      });
  }, []);
  // useEffect(() => {
  //   api
  //     .getTaxNumber(token)
  //     .then((data) => {
  //       if (data && data.error) {
  //         // that's because 409 http status not reflected in catch because of using fetch not axios.
  //         history(`/${language}/login`, { replace: true });
  //         showToast({
  //           data: {
  //             variant: 'success',
  //             text: 'Taxpayer already registered before',
  //           },
  //         });
  //       }
  //       setTaxNumber(data.taxNumber);
  //       setTaxNumberLoading(false);
  //     })
  //     .catch((error) => {
  //       if (error) {
  //         history(`/${language}/login`, { replace: true });
  //          showToast({
  //           data: {
  //           variant: 'error',
  //           text: error,
  //         },
  //       });
  //       }

  //       setTaxNumberLoading(false);
  //     });
  // }, []);

  const handleSubmit = (values, { setFieldError }) => {
    setLoading(true);

    api
      .registerUser({ data: values, token })
      .then((data) => {
        localStorage.setItem(TOKEN_KEY, data.token);

        api
          .getUserInfo()
          .then((user) => {
            dispatchUser({
              type: 'SET_USER',
              payload: user,
            });

            setLoading(false);

            history(`/${language}`);
          })
          .catch(() => {
            setLoading(false);
          });

        localStorage.setItem(TOKEN_KEY, data.token);
      })
      .catch(({ error }) => {
        setLoading(false);

        if (error) {
          setFieldError('username', error);
        }
      });
  };

  return (
    <RegisterForm
      {...props}
      loading={loading}
      taxNumberLoading={taxNumberLoading}
      taxNumber={taxNumber}
      onSubmit={handleSubmit}
    />
  );
};

export default React.memo(RegisterFormContainer);
