import React, { useState, useEffect } from 'react';

import * as api from 'api/methods';

import { useUser } from 'context/user';

import SettingsModal from './SettingsModal';
import { useTranslation } from 'react-i18next';
import { uploadUserLogo } from '../../../../api/methods';
import { showToast } from 'helpers/showToast';

const SettingsModalModalContainer = ({ open, ...restProps }) => {
  const { user } = useUser();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const loadActivityOptions = () =>
    api.getAllActivities().then((data) =>
      data.map((item) => {
        let label, value;

        if (item.invoiceType === 0) {
          label = t('activity_number_income', {
            activityNumber: item.activity,
          });
          value = item.activity;
        } else if (item.invoiceType === 1) {
          label = t('activity_number_general', {
            activityNumber: item.activity,
          });
          value = item.activity;
        } else {
          label = t('activity_number_special', {
            activityNumber: item.activity,
          });
          value = item.activity;
        }

        return { label, value };
      }),
    );

  useEffect(() => {
    setLoading(true);
    const getIncomeSource = async () => {
      try {
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    if (open) {
      getIncomeSource();
    }
  }, [open]);

  const handleFormSubmit = (formData) => {
    const data = new window.FormData();
    data.append('file', formData.invoiceLogo);
    data.append('activityNumber', formData.activityNumber?.value);
    uploadUserLogo(data).then(async () => {
      // await viewUserLogo({ setImage: setPreviewImage, setLoading });

      showToast({
        data: {
          variant: 'success',
          text: t('user_logo_logo_uploaded_successfully'),
        },
      });
      // e.options.clear();
    });
  };
  return (
    <SettingsModal
      {...restProps}
      open={open}
      loading={loading}
      loadActivityOptions={loadActivityOptions}
      handleFormSubmit={handleFormSubmit}
    />
  );
};

export default React.memo(SettingsModalModalContainer);
