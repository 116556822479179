import React from 'react';
import { useLanguage } from 'hooks/useLanguage';
import { useFormikContext } from 'formik';
import cn from 'classnames';

import { INVOICE_TYPES_ENUM } from 'constants/common';

import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';
import { Table } from 'components/shared/Table';
import { Icon } from 'components/shared/Icon';

import { FormikField } from 'components/FormikField';
import { InputField, SelectField } from 'components/FormikFields';
import { InvoiceTotals } from 'components/InvoiceTotals';

import {
  INVOICE_COLUMNS,
  TAX_RATE_OPTIONS,
  TYPE_OPTIONS,
} from 'config/invoice/config';

import { getTaxRateValueByType } from 'config/invoice/helpers';

import styles from 'assets/styles/invoice.scss';
import { t } from 'i18next';
import { getTaxRateValueText } from '../../../../config/invoice/helpers';

const cellRenderer = (
  { className, item, itemIndex, key, minWidth, maxWidth },
  DefaultCell,
  { onEditItemClick, onRemoveItemClick, openDeleteInvoiceProductModal },
) => {
  switch (key) {
    case 'order': {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {itemIndex + 1}
        </DefaultCell>
      );
    }
    case 'quantity':
    case 'specialTaxAmount':
    case 'discountAmount': {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {Number(item[key]).toFixed(3)}
        </DefaultCell>
      );
    }
    case 'isic4': {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          <p
            dir="rtl"
            title={item?.isic4 ? t(`isic4.isic4_${item?.isic4}`) : '-'}
          >
            {item?.isic4 ? t(`isic4.isic4_${item?.isic4}`) : '-'}
          </p>
        </DefaultCell>
      );
    }
    case 'generalTaxPercentage': {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {getTaxRateValueText(item[key]?.value)}
        </DefaultCell>
      );
    }
    case 'action': {
      return (
        <div
          className={cn(styles.actionCell, className)}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <Icon
            className={styles.trashIcon}
            name="trash"
            onClick={() => openDeleteInvoiceProductModal(item.id)}
            // onClick={() => onRemoveItemClick(item.id)}
          />
          <Icon
            className={styles.editIcon}
            name="edit"
            onClick={() => onEditItemClick({ item, itemIndex })}
            hidden={() => item.type.value === 'SERVICE_CHARGE'}
          />
        </div>
      );
    }
    default: {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {item[key]}
        </DefaultCell>
      );
    }
  }
};

const InvoiceProductsSection = ({
  onAddItemClick,
  onEditItemClick,
  onRemoveItemClick,
  invoiceType,
  isEdit,
  openDeleteInvoiceProductModal,
}) => {
  const {
    values,
    setFieldValue,
    setTouched,
    setErrors,
    validateForm,
    resetForm,
  } = useFormikContext();
  const language = useLanguage();

  return (
    <>
      {!isEdit && (
        <>
          <div className={styles.section}>
            <Typography weight="bold" className={styles.title}>
              {t('newInvoice_serviceOrcommodity')}
            </Typography>
            <div className={language === 'ar' ? 'row flex-row-reverse' : 'row'}>
              {values.tempItem.type?.value === 'PRODUCT' && (
                <>
                  <div class="col-md-3 col-sm-12">
                    <FormikField
                      name="tempItem.description"
                      component={InputField}
                      componentProps={{}}
                      controlProps={{
                        labelProps: {
                          className: styles.fieldLabel,
                        },
                      }}
                      required
                      label={t('newInvoice_description_goodOrService')}
                    />
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <FormikField
                      name="tempItem.quantity"
                      component={InputField}
                      componentProps={{
                        type: 'number',
                        min: 0,
                      }}
                      controlProps={{
                        labelProps: {
                          className: styles.fieldLabel,
                        },
                      }}
                      required
                      label={t('newInvoice_quantity')}
                    />
                  </div>
                </>
              )}
              <div class="col-md-3 col-sm-12">
                <FormikField
                  name="tempItem.unitPrice"
                  component={InputField}
                  componentProps={{
                    type: 'number',
                    min: 0,
                  }}
                  controlProps={{
                    labelProps: {
                      className: styles.fieldLabel,
                    },
                  }}
                  required
                  label={
                    values.tempItem.type?.value === 'PRODUCT'
                      ? t('newInvoice_unitPrice')
                      : t('newInvoice_totalAmount')
                  }
                />
              </div>
              {(invoiceType === INVOICE_TYPES_ENUM.CASH_SPECIAL_TAX ||
                invoiceType === INVOICE_TYPES_ENUM.RECEIVABLE_SPECIAL_TAX) && (
                <div class="col-md-3 col-sm-12">
                  <FormikField
                    name="tempItem.specialTaxAmount"
                    component={InputField}
                    componentProps={{
                      type: 'number',
                      min: 0,
                    }}
                    controlProps={{
                      labelProps: {
                        className: styles.fieldLabel,
                      },
                    }}
                    required={!values.items.length}
                    label={t('newInvoice_special_tax_amount')}
                  />
                </div>
              )}

              {invoiceType !== INVOICE_TYPES_ENUM.CASH_INCOME &&
                invoiceType !== INVOICE_TYPES_ENUM.RECEIVABLE_INCOME && (
                  <div class="col-md-3 col-sm-12">
                    <FormikField
                      name="tempItem.generalTaxPercentage"
                      component={SelectField}
                      componentProps={{
                        options: TAX_RATE_OPTIONS(t),
                      }}
                      controlProps={{
                        labelProps: {
                          className: styles.fieldLabel,
                        },
                      }}
                      required
                      label={t('newInvoice_general_tax_rate')}
                    />
                  </div>
                )}

              {values.tempItem.type?.value === 'PRODUCT' && (
                <div class="col-md-3 col-sm-12">
                  <FormikField
                    name="tempItem.discountAmount"
                    component={InputField}
                    componentProps={{
                      type: 'number',
                      min: 0,
                    }}
                    controlProps={{
                      labelProps: {
                        className: styles.fieldLabel,
                      },
                    }}
                    label={t('newInvoice_discount_value')}
                  />
                </div>
              )}

              {invoiceType !== INVOICE_TYPES_ENUM.CASH_INCOME &&
                invoiceType !== INVOICE_TYPES_ENUM.RECEIVABLE_INCOME && (
                  <div class="col-md-3 col-sm-12">
                    <FormikField
                      name="tempItem.type"
                      component={SelectField}
                      componentProps={{
                        options: TYPE_OPTIONS(t),
                      }}
                      controlProps={{
                        labelProps: {
                          className: styles.fieldLabel,
                        },
                      }}
                      required
                      label={t('newInvoice_type')}
                    />
                  </div>
                )}
            </div>
          </div>
          <Button
            className={styles.button}
            onClick={() =>
              onAddItemClick({
                values,
                setTouched,
                setErrors,
                validateForm,
                resetForm,
              })
            }
          >
            {t('newInvoice_addItem')}
          </Button>
        </>
      )}

      {values.items.some((item) => !item.creating) && (
        <>
          <Table
            className={styles.table}
            headerTextClassName={styles.tableHeaderText}
            columns={INVOICE_COLUMNS({ t, invoiceType })}
            data={values.items}
            cellRenderer={(props, DefaultCell) =>
              cellRenderer(props, DefaultCell, {
                onEditItemClick: ({ item, itemIndex }) =>
                  onEditItemClick(
                    { item, itemIndex },
                    { values, setFieldValue },
                  ),
                onRemoveItemClick: (itemId) =>
                  onRemoveItemClick(itemId, {
                    values,
                    setFieldValue,
                  }),
                openDeleteInvoiceProductModal: (itemId) =>
                  openDeleteInvoiceProductModal({
                    itemId,
                    values,
                    setFieldValue,
                  }),
              })
            }
          />
          <InvoiceTotals
            items={values.items}
            invoiceType={invoiceType}
            invoiceKind={values.invoiceKind?.value}
            currencyType={values.currencyType}
          />
        </>
      )}
    </>
  );
};

export default React.memo(InvoiceProductsSection);
