import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { INVOICE_TYPES_ENUM } from 'constants/common';

import { Typography } from 'components/shared/Typography';

import {
  calculateSubtotalAmount,
  calculateTotalDiscountAmount,
  calculateTotalTaxesAmount,
  calculateTotalAmountAfterTaxes,
  calculateTotalSpecialTaxAmount,
  calculateTotalWithSpecialTaxAmount,
} from 'config/invoice/helpers';

import { formatNumberWithComma } from 'helpers/formatNumberWithComma';

import styles from './InvoiceTotals.styles.scss';
import { roundToThreeDecimalPlaces } from '../../helpers/formatDicimalNumbers';

const InvoiceTotals = ({
  className,
  items,
  invoiceType,
  invoiceKind,
  currencyType,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.totals, className)}>
      {invoiceType !== INVOICE_TYPES_ENUM.CASH_INCOME &&
        invoiceType !== INVOICE_TYPES_ENUM.RECEIVABLE_INCOME && (
          <>
            <div className={styles.total}>
              <Typography className={styles.totalValue} weight="bold">
                {formatNumberWithComma(
                  roundToThreeDecimalPlaces(calculateSubtotalAmount(items)),
                )}
              </Typography>
              <Typography className={styles.totalText} weight="bold">
                {t('newInvoice_totalInvoiceAmount', {
                  currency: currencyType?.value,
                })}
              </Typography>
            </div>
          </>
        )}
      <div className={styles.total}>
        <Typography className={styles.totalValue} weight="bold">
          {formatNumberWithComma(
            roundToThreeDecimalPlaces(calculateTotalDiscountAmount(items)),
          )}
        </Typography>
        <Typography className={styles.totalText} weight="bold">
          {t('view_invoice_Total_discount_value', {
            currency: currencyType?.value,
          })}
        </Typography>
      </div>

      {(invoiceType === INVOICE_TYPES_ENUM.CASH_SPECIAL_TAX ||
        invoiceType === INVOICE_TYPES_ENUM.RECEIVABLE_SPECIAL_TAX) && (
        <>
          <div className={styles.total}>
            <Typography className={styles.totalValue} weight="bold">
              {formatNumberWithComma(
                roundToThreeDecimalPlaces(
                  calculateTotalSpecialTaxAmount(items, invoiceKind),
                ),
              )}
            </Typography>
            <Typography className={styles.totalText} weight="bold">
              {t('newInvoice_totalSpecialTaxAmount', {
                currency: currencyType?.value,
              })}
            </Typography>
          </div>
          <div className={styles.total}>
            <Typography className={styles.totalValue} weight="bold">
              {formatNumberWithComma(
                roundToThreeDecimalPlaces(
                  calculateTotalWithSpecialTaxAmount(items, invoiceKind),
                ),
              )}
            </Typography>
            <Typography className={styles.totalText} weight="bold">
              {t('newInvoice_total_with_SpecialTaxAmount', {
                currency: currencyType?.value,
              })}
            </Typography>
          </div>
        </>
      )}

      {invoiceType !== INVOICE_TYPES_ENUM.CASH_INCOME &&
        invoiceType !== INVOICE_TYPES_ENUM.RECEIVABLE_INCOME && (
          <>
            <div className={styles.total}>
              <Typography className={styles.totalValue} weight="bold">
                {formatNumberWithComma(
                  roundToThreeDecimalPlaces(
                    calculateTotalTaxesAmount(items, invoiceKind),
                  ),
                )}
              </Typography>
              <Typography className={styles.totalText} weight="bold">
                {t('newInvoice_Total_general_tax_amount', {
                  currency: currencyType?.value,
                })}
              </Typography>
            </div>
          </>
        )}

      <div className={styles.total}>
        <Typography className={styles.totalValue} weight="bold">
          {formatNumberWithComma(
            roundToThreeDecimalPlaces(
              calculateTotalAmountAfterTaxes(items, invoiceKind),
            ),
          )}
        </Typography>
        <Typography className={styles.totalText} weight="bold">
          {t('newInvoice_total_bill', {
            currency: currencyType?.value,
          })}
        </Typography>
      </div>
    </div>
  );
};

export default React.memo(InvoiceTotals);
