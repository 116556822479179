import React, { useState } from 'react';

import * as api from 'api/methods';

import { useFetch } from 'hooks/useFetch';
import { useModal } from 'hooks/useModal';

import { AddDeviceModal } from './components/AddDeviceModal';

import DevicesPage from './DevicesPage';

const DevicesPageContainer = () => {
  const [triggerDevicesRequest, setTriggerDevicesRequest] = useState(null);

  const [
    addDeviceModalOpen,
    openAddDeviceModal,
    closeAddDeviceModal,
    addDeviceModalData,
  ] = useModal({ defaultOpen: false });

  const {
    loading,
    data: devices,
    setState: setDevicesState,
  } = useFetch(
    {
      defaultData: [],
      fetcher: api.getAllDevices,
      immediately: true,
    },
    {},
    [triggerDevicesRequest],
  );

  const handleAddDeviceClick = () => {
    openAddDeviceModal({ setTriggerDevicesRequest });
  };

  const handleDeviceEnabledChange = (event, deviceId) => {
    const checked = event.target.checked;

    if (!checked) {
      // api
      //   .disableDeviceById(deviceId)
      //   .then(() => {
      //     setDevicesState((state) => ({
      //       ...state,
      //       data: state.data.map((device) => {
      //         if (device.id === deviceId) {
      //           return {
      //             ...device,
      //             enabled: false,
      //           };
      //         }
      //         return device;
      //       }),
      //     }));
      //   })
      //   .catch((error) => {
      //     console.warn(error);
      //   });
    } else {
      api
        .enableDeviceById(deviceId)
        .then(() => {
          setDevicesState((state) => ({
            ...state,
            data: state.data.map((device) => {
              if (device.id === deviceId) {
                return {
                  ...device,
                  enabled: true,
                };
              }

              return device;
            }),
          }));
        })
        .catch((error) => {
          console.warn(error);
        });
    }
  };

  return (
    <>
      <DevicesPage
        loading={loading}
        devices={devices}
        onDeviceEnabledChange={handleDeviceEnabledChange}
        onAddDeviceClick={handleAddDeviceClick}
      />
      <AddDeviceModal
        {...addDeviceModalData}
        open={addDeviceModalOpen}
        onClose={closeAddDeviceModal}
      />
    </>
  );
};

export default React.memo(DevicesPageContainer);
