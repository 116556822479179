import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './LangSelect.styles.scss';

const LangSelect = ({ className, currentLanguage, onLangClick }) => (
  <div className={cn(styles.select, className)} onClick={onLangClick}>
    <Icon className={styles.icon} name="globe" />
    <Typography className={styles.text + ' mb-0'}>
      {currentLanguage === 'en' ? 'العربية' : 'English'}
    </Typography>
  </div>
);

export default React.memo(LangSelect);
