import * as Yup from 'yup';

export const validationSchema = (t) =>
  Yup.object().shape({
    deviceName: Yup.string().required(t('device_modal_validation_devicename')),
    activityNumber: Yup.object().required(
      t('device_modal_validation_activitynumber'),
    ),
  });

export const initialValues = {
  deviceName: '',
  activityNumber: '',
};

export default {
  initialValues,
  validationSchema,
};
