import * as api from 'api/methods';
import i18n from 'config/i18n';
import {
  TOKEN_KEY,
  OTP_KEY,
  REFRESH_TOKEN,
  SESSION_TIMEOUT,
} from 'constants/common';

export const logoutUser = () => {
  return api
    .logout()
    .then(() => {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN);
      localStorage.removeItem(OTP_KEY);

      sessionStorage.removeItem(TOKEN_KEY);
      sessionStorage.removeItem(REFRESH_TOKEN);
      sessionStorage.removeItem(SESSION_TIMEOUT);
      window.location.replace(`/${i18n.language}/login`);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN);
      localStorage.removeItem(OTP_KEY);

      sessionStorage.removeItem(TOKEN_KEY);
      sessionStorage.removeItem(REFRESH_TOKEN);
      sessionStorage.removeItem(SESSION_TIMEOUT);
      window.location.replace(`/${i18n.language}/login`);
    });
};
