import i18n from 'config/i18n';

export const INVOICE_STATUSES_OPTIONS = () => {
  const language = i18n.language;

  return [
    {
      label: language === 'en' ? 'Approved' : 'موافق عليه',
      value: 'ACTIVE',
    },
    // { label: language === 'en' ? 'Cancelled' : 'ألغيت', value: 'CANCELED' },
  ];
};

export const INVOICE_NOTE_TYPES_OPTIONS = () => {
  const language = i18n.language;

  return [
    {
      label: language === 'en' ? 'unspecified' : 'غير محدد',
      value: null,
    },
    // {
    //   label: language === 'en' ? 'Debit' : 'مدين',
    //   value: 'DEBIT_INVOICE',
    // },
    { label: language === 'en' ? 'Credit' : 'دائن', value: 'CREDIT_INVOICE' },
  ];
};

const getOptionsByType = ({ invoiceType, t }) => {
  switch (invoiceType) {
    case 0: {
      return [
        {
          label: t('filter_modal_filter_cash_income'),
          value: 'CASH_INCOME',
        },
        {
          label: t('filter_modal_receivable_income'),
          value: 'RECEIVABLE_INCOME',
        },
      ];
    }
    case 1: {
      return [
        {
          label: t('filter_modal_cash_general_tax'),
          value: 'CASH_GENERAL_TAX',
        },
        {
          label: t('filter_modal_receivable_general_tax'),
          value: 'RECEIVABLE_GENERAL_TAX',
        },
      ];
    }
    case 2: {
      return [
        {
          label: t('filter_modal_cash_special_tax'),
          value: 'CASH_SPECIAL_TAX',
        },
        {
          label: t('filter_modal_receivable_special_tax'),
          value: 'RECEIVABLE_SPECIAL_TAX',
        },
      ];
    }
    default: {
      return [];
    }
  }
};

export const INVOICE_TYPES_OPTIONS = ({ t }) => {
  return [
    {
      label: t('unspecified'),
      value: null,
    },
    {
      label: t('CASH'),
      value: 'CASH',
    },
    {
      label: t('RECEIVABLE'),
      value: 'RECEIVABLE',
    },
  ];
};
// export const INVOICE_TYPES_OPTIONS = ({ t, activitiesList }) => {
//   const options = [];

//   activitiesList.map((item) => {
//     options.push(...getOptionsByType({ invoiceType: item.invoiceType, t }));
//   });
//   return options;
// };
