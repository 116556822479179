import React from 'react';
import styles from './Paginator.styles.scss';

const Paginator = ({ pages, onNext, onPrevious, onPageClick, activePage }) => {
  const newPages = pages.filter(
    (page) =>
      page + 1 === activePage ||
      (page <= activePage + 10 && page >= activePage),
  );
  return (
    <div className={styles.pagination}>
      <a onClick={() => onPageClick(1)}>{'<<'}</a>
      <a onClick={onPrevious}>{'<'}</a>
      {newPages.map((page) => (
        <a
          onClick={() => onPageClick(page + 1)}
          key={page + 1}
          className={page + 1 === activePage ? styles.active : ''}
        >
          {page + 1}
        </a>
      ))}
      <a onClick={onNext}>{'>'}</a>
      <a onClick={() => onPageClick(pages.length)}>{'>>'}</a>
    </div>
  );
};

export default React.memo(Paginator);
