import React, { useEffect } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/shared/Button';
import { FormikField } from 'components/FormikField';
import { InputField, PasswordField } from 'components/FormikFields';

import {
  validationSchema,
  initialValues,
} from './ForgotPasswordForm.formConfig';

import styles from './ForgotPasswordForm.styles.scss';

const ForgotPasswordFormInner = ({ loading, errorValue, username }) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('username', username);
    console.log('username2', username);
  }, [username, setFieldValue]);

  return (
    <Form className={styles.form}>
      <FormikField
        name="username"
        component={InputField}
        label={t('reset_password_username')}
        componentProps={{
          disabled: true,
        }}
      />
      <FormikField
        name="password"
        component={PasswordField}
        label={t('reset_password_password')}
      />
      <FormikField
        name="confirmPassword"
        component={PasswordField}
        label={t('reset_password_confirm_password')}
      />
      <p className={styles.errorMessage}>{errorValue}</p>
      <Button className={styles.button} loading={loading} type="submit">
        {t('reset_password_reset_password')}
      </Button>
    </Form>
  );
};

const ForgotPasswordForm = ({ loading, onSubmit, errorValue, username }) => {
  const { t } = useTranslation();
  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={validationSchema(t)}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {() => (
        <ForgotPasswordFormInner
          loading={loading}
          errorValue={errorValue}
          username={username}
        />
      )}
    </Formik>
  );
};

export default React.memo(ForgotPasswordForm);
