import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import * as api from 'api/methods';

import { useFetch } from 'hooks/useFetch';
import { useLanguage } from 'hooks/useLanguage';
import { useUser } from 'context/user';

import CreateInvoiceModal from './CreateInvoiceModal';

const CreateInvoiceModalContainer = ({ open, ...restProps }) => {
  const { user } = useUser();

  const history = useNavigate();
  const language = useLanguage();

  const [showNewInvoiceOptinos, setShowNewInvoiceOptions] = useState(false);
  const [canEditInvoice, setCancelEditInvoices] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleNewInvoiceClick = () => {
    setShowNewInvoiceOptions(true);
  };

  const handleNewInvoiceOptionClick = (invoiceType) => {
    history(`/${language}/new-invoice/${invoiceType}`);
  };

  const handleEditInvoiceOptionClick = () => {
    history(`/${language}/edit-invoice`);
  };

  useEffect(() => {
    setLoading(true);
    const checkIfUserHasInvoices = async () => {
      try {
        const hasInvoices = await api.checkIfTheUserHaveInvoices(
          user.activityNumber,
        );
        setLoading(false);
        // Set cancelEditInvoices based on the API response
        setCancelEditInvoices(!hasInvoices);
      } catch (error) {
        setLoading(false);
        console.error('Error checking if user has invoices:', error);
      }
    };
    if (open) {
      checkIfUserHasInvoices();
    }
  }, [open]);

  return (
    <CreateInvoiceModal
      {...restProps}
      open={open}
      loading={loading}
      canEditInvoice={!canEditInvoice}
      showNewInvoiceOptinos={showNewInvoiceOptinos}
      onEditInvoiceOptionClick={handleEditInvoiceOptionClick}
      onNewInvoiceClick={handleNewInvoiceClick}
      onNewInvoiceOptionClick={handleNewInvoiceOptionClick}
    />
  );
};

export default React.memo(CreateInvoiceModalContainer);
