import React, { useState } from 'react';

import Popover from './Popover';

const PopoverContainer = ({ visible, mode, onShow, onHide, ...restProps }) => {
  const [shouldPlayAnimation, setShouldPlayAnimation] = useState(false);
  const [_visible, setVisible] = useState(false);

  const handleMount = () => {
    setShouldPlayAnimation(true);
    setVisible(true);

    if (onShow) {
      onShow();
    }
  };

  const handleHide = (instance) => {
    const unmountInstance = () => {
      instance.unmount();

      // need to control when we remove the listener because transitionend fires multiple times
      instance.popper.firstChild.removeEventListener(
        'transitionend',
        unmountInstance,
      );
    };

    instance.popper.firstChild.addEventListener(
      'transitionend',
      unmountInstance,
    );

    setShouldPlayAnimation(false);
    setVisible(false);

    if (onHide) {
      onHide();
    }
  };

  return (
    <Popover
      {...restProps}
      visible={mode === 'controlled' ? visible : _visible}
      shouldPlayAnimation={shouldPlayAnimation}
      mode={mode}
      onMount={handleMount}
      onHide={handleHide}
    />
  );
};

PopoverContainer.defaultProps = {
  interactive: true,
  mode: 'uncontrolled',
  placement: 'bottom-end',
  trigger: 'click',
};

export default React.memo(PopoverContainer);
