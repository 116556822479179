import _camelCase from 'lodash/camelCase';

const webpackContext = require.context('assets/icons', true, /\.svg$/);

export const getIcons = () => {
  return webpackContext.keys().reduce((acc, k) => {
    const iconName = _camelCase(
      k
        .split('/')
        .slice(-1)[0]
        .replace(/\.svg$/, ''),
    );

    acc[iconName] = webpackContext(k).default;

    return acc;
  }, {});
};
