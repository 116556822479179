import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as api from 'api/methods';

import { useUser } from 'context/user';

import { defaultItemData } from 'config/invoice/constants';

import {
  getProductWithTotals,
  convertFormDataToApi,
  getInvoiceType,
  validateItemFields,
} from 'config/invoice/helpers';

import { showToast } from 'helpers/showToast';

import { useLanguage } from 'hooks/useLanguage';

import EditInvoicePage from './EditInvoicePage';
import { TAX_RATE_OPTIONS } from '../../config/invoice/config';

const EditInvoicePageContainer = () => {
  const history = useNavigate();
  const language = useLanguage();

  const { t } = useTranslation();

  const { user } = useUser();

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState('-');
  const [invoiceType, setInvoiceType] = useState(null);
  const [invoice, setInvoice] = useState(null);

  useEffect(() => {
    const promises = [
      api.getNextInvoiceNumber(),
      api.getInvoiceType(user.activityNumber),
    ];

    Promise.all(promises)
      .then(([invoiceNumberData, userInvoicesData]) => {
        setLoading(false);

        setInvoiceNumber(invoiceNumberData.invoiceNumber);
        setInvoiceType(
          getInvoiceType({
            generalType: userInvoicesData?.invoiceType
              .split('_')[0]
              .toLowerCase(),
            user,
          }),
        );

        // setUserInvoices(userInvoicesData);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const loadActivityOptions = () =>
    api.getAllActivities().then((data) =>
      data.map((item) => ({
        label: `${item.activity} (${item.description})`,
        value: item.activity,
      })),
    );

  const handleAddItemClick = async ({
    values,
    setTouched,
    setErrors,
    validateForm,
    resetForm,
  }) => {
    const haveError = await validateItemFields({
      t,
      item: values.tempItem,
      invoiceType,
      setTouched,
      setErrors,
      validateForm,
    });

    if (haveError) {
      return;
    }

    const currentItem = getProductWithTotals({
      item: values.tempItem,
      invoiceType,
    });

    currentItem.creating = false;

    if (values.invoiceKind && values.invoiceKind.value !== 'LOCAL') {
      resetForm({
        values: {
          ...values,
          items: [...values.items, currentItem],
          tempItem: {
            id: uuid(),
            ...defaultItemData(
              TAX_RATE_OPTIONS(t).find((item) => item.value === 'ZERO'),
            ),
          },
        },
      });
    } else {
      resetForm({
        values: {
          ...values,
          items: [...values.items, currentItem],
          tempItem: {
            id: uuid(),
            ...defaultItemData(),
          },
        },
      });
    }
  };

  const handleRemoveItemClick = (itemId, { values, setFieldValue }) => {
    values.items.forEach((element) => {
      if (element.id === itemId) {
        element.updated = true;
        element.deleted = true;
      }
    });
    setFieldValue('items', values.items);
  };

  const handleCancelClick = () => {
    history(`/${language}`);
  };

  const handleSubmitFormClick = (values) => {
    if (!values.items.length) {
      showToast({
        data: {
          variant: 'error',
          text: t('create_invoice_validation_all_fields'),
        },
      });

      return;
    }
    const editedItems = values.items.filter((item) => item.updated === true);
    values.editedItems = editedItems;

    const data = convertFormDataToApi({
      invoiceTypeCode: invoiceType,
      data: values,
      user,
      isEdit: true,
    });

    if (
      data.noteType === 'DEBIT_INVOICE' &&
      data.totalPayableAmount >= data.originalInvoiceTotal
    ) {
      showToast({
        data: {
          variant: 'error',
          text: t(
            'edit_invoice_reference_invocie_total_more_then_debit_invoice_total',
          ),
        },
      });
    }

    if (!editedItems.length) {
      showToast({
        data: {
          variant: 'error',
          text: t('edit_invoice_no_edited_item'),
        },
      });
      return;
    }

    setSubmitLoading(true);

    api
      .submitInvoice(data)
      .then((response) => {
        setSubmitLoading(false);
        showToast({
          data: {
            variant: 'success',
            text: t('invoice_has_been_sent'),
          },
        });
        history(
          `/${language}/invoices/${response.invoiceId}/${response.invoiceNumber}/edit`,
        );
      })
      .catch(({ error }) => {
        setSubmitLoading(false);

        showToast({
          data: {
            variant: 'error',
            text: error,
          },
        });
      });
  };

  return (
    <div>
      {submitLoading ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(255, 255, 255, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 999,
          }}
        >
          <div
            style={{
              border: '8px solid #f3f3f3',
              borderTop: '8px solid #3498db',
              borderRadius: '50%',
              width: '50px',
              height: '50px',
              animation: 'spin 1s linear infinite',
            }}
          />
        </div>
      ) : (
        <>
          <EditInvoicePage
            loading={loading}
            submitLoading={submitLoading}
            user={user}
            invoiceNumber={invoiceNumber}
            invoiceType={invoiceType}
            setInvoiceType={setInvoiceType}
            loadActivityOptions={loadActivityOptions}
            onAddItemClick={handleAddItemClick}
            onRemoveItemClick={handleRemoveItemClick}
            onCancelClick={handleCancelClick}
            onSubmitFormClick={handleSubmitFormClick}
            setInvoice={setInvoice}
            invoice={invoice}
          />
        </>
      )}
    </div>
  );
};

export default React.memo(EditInvoicePageContainer);
