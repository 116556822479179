import React from 'react';
import AccountMenu from './AccountMenu';
import { logoutUser } from '../../../../helpers/logUserOut';
import { useNavigate } from 'react-router-dom';

const AccountMenuContainer = (props) => {
  const history = useNavigate();
  const handleItemClick = (type) => {
    switch (type) {
      case 'logout': {
        logoutUser();
        break;
      }
      case 'changePassword': {
        history('change-password');
        break;
      }
    }
  };

  return <AccountMenu {...props} onItemClick={handleItemClick} />;
};

export default React.memo(AccountMenuContainer);
