// import React from 'react';
// import { useTranslation } from 'react-i18next';

// import { Typography } from 'components/shared/Typography';

// import styles from './Info.styles.scss';

// const Info = ({
//   generatedThisYear,
//   generatedInvoices,
//   generatedNotes,
//   totalOfTotals,
// }) => {
//   const { t } = useTranslation();

//   return (
//     <div className={styles.info}>
//       <div className={styles.infoRow}>
//         <div className={styles.item}>
//           <Typography className={styles.title}>
//             {t('invoices_invoices_issued')}
//           </Typography>
//           <Typography className={styles.text} weight="bold">
//             {generatedInvoices || 0}
//           </Typography>
//         </div>
//         {/* <div className={styles.item}>
//           <Typography className={styles.title}>
//             {t('invoices_invoices_issued_year')}
//           </Typography>
//           <Typography className={styles.text} weight="bold">
//             {generatedThisYear || 0}
//           </Typography>
//         </div>
//         <div className={styles.item}>
//           <Typography className={styles.title}>
//             {t('invoices_notifications_issued')}
//           </Typography>
//           <Typography className={styles.text} weight="bold">
//             {generatedNotes || 0}
//           </Typography>
//         </div> */}
//       </div>
//       <div className={styles.infoRow}>
//         <div className={styles.item}>
//           <Typography className={styles.title}>
//             {t('invoices_total')}
//           </Typography>
//           <Typography className={styles.text} weight="bold">
//             {generatedInvoices || generatedNotes
//               ? generatedInvoices + generatedNotes
//               : 0}
//           </Typography>
//         </div>
//         <div className={styles.item}>
//           <Typography className={styles.title}>
//             {t('invoices_notifications_issued')}
//           </Typography>
//           <Typography className={styles.text} weight="bold">
//             {generatedNotes || 0}
//           </Typography>
//         </div>
//       </div>
//       <div className={styles.infoRow}>
//         <div className={styles.item}>
//           <Typography className={styles.title}>
//             {/* {t('invoices_invoices_issued')} */}
//             {t('invoices_invoices_total_sales')}
//           </Typography>
//           <Typography className={styles.text} weight="bold">
//             {totalOfTotals || 0}
//           </Typography>
//         </div>
//         <div className={styles.item}>
//           <Typography className={styles.title}>
//             {t('invoices_invoices_issued_year')}
//           </Typography>
//           <Typography className={styles.text} weight="bold">
//             {generatedThisYear || 0}
//           </Typography>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default React.memo(Info);

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/shared/Typography';

import styles from './Info.styles.scss';

import { formatNumberWithComma } from 'helpers/formatNumberWithComma';

const Info = ({
  generatedThisYear,
  generatedInvoices,
  generatedNotes,
  totalOfTotals,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.info}>
      <div className={styles.infoRow}>
        <div className={styles.item}>
          <Typography className={styles.title}>
            {t('invoices_invoices_issued')}
          </Typography>
          <Typography className={styles.text} weight="bold">
            {generatedInvoices || 0}
          </Typography>
        </div>
        <div className={styles.item}>
          <Typography className={styles.title}>
            {t('invoices_invoices_issued_year')}
          </Typography>
          <Typography className={styles.text} weight="bold">
            {generatedThisYear || 0}
          </Typography>
        </div>
        <div className={styles.item}>
          <Typography className={styles.title}>
            {t('invoices_notifications_issued')}
          </Typography>
          <Typography className={styles.text} weight="bold">
            {generatedNotes || 0}
          </Typography>
        </div>
        <div className={styles.item}>
          <Typography className={styles.title}>
            {t('invoices_total')}
          </Typography>
          <Typography className={styles.text} weight="bold">
            {generatedInvoices || generatedNotes
              ? generatedInvoices + generatedNotes
              : 0}
          </Typography>
        </div>
      </div>
      <div className={styles.infoRow}>
        {/* <div className={styles.item}>
          <Typography className={styles.title}>
            {t('invoices_total')}
          </Typography>
          <Typography className={styles.text} weight="bold">
            {generatedInvoices || generatedNotes
              ? generatedInvoices + generatedNotes
              : 0}
          </Typography>
        </div> */}
        {/* <div className={styles.item}>
          <Typography className={styles.title}>
            {t('invoices_invoices_total_sales')}
          </Typography>
          <Typography className={styles.text} weight="bold">
            {(totalOfTotals && formatNumberWithComma(totalOfTotals)) || 0}
          </Typography>
        </div> */}
      </div>
    </div>
  );
};

export default React.memo(Info);
