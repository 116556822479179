import React from 'react';

import { Icon } from 'components/shared/Icon';
import { Input } from 'components/shared/Input';
import { Typography } from 'components/shared/Typography';

import styles from './Filters.styles.scss';

const Filters = ({
  currentFilters,
  onSearchQueryChange,
  onClearFilters,
  onRemoveFilterClick,
  onFilterBtnClick,
}) => (
  <div className={styles.filters}>
    <div className={styles.items}>
      {currentFilters.map((filter) => (
        <div className={styles.item} key={filter.text}>
          <Icon
            className={styles.itemIcon}
            name="cross"
            onClick={() => onRemoveFilterClick(filter)}
          />
          <Typography className={styles.itemText}>{filter.text}</Typography>
        </div>
      ))}
    </div>
    {!!currentFilters.length && (
      <div className={styles.clearIconWrapper} onClick={onClearFilters}>
        <Icon className={styles.clearIcon} name="cross" />
      </div>
    )}
    <div className={styles.filterBtn} onClick={onFilterBtnClick}>
      <Icon className={styles.filterIcon} name="filter" />
    </div>
    <Input
      className={styles.input}
      fieldClassName={styles.inputField}
      startAdornment={
        <Icon className={styles.searchIcon} name="magnifyingGlass" />
      }
      onChange={onSearchQueryChange}
    />
  </div>
);

export default React.memo(Filters);
