import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactModal from 'react-modal';

import { initTranslations } from 'config/i18n';
import { setupAxiosInterceptors } from 'config/axios';

import 'assets/styles/styles.scss';
import 'bootstrap/dist/css/bootstrap.css';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';

import App from './App.container';

setupAxiosInterceptors();
initTranslations();

ReactModal.setAppElement('#einv-app');

const root = createRoot(document.getElementById('einv-app'));

root.render(<App />);
