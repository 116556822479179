export const fileSizeLimit = 1024 * 1024; // 1 MB in bytes;
export const allowedImgFormats = {
  labels: ['JPG', 'JPEG', 'PNG'],
  values: [
    'img/jpeg',
    'img/jpg',
    'img/png',
    'image/png',
    'image/jpeg',
    'image/jpg',
  ],
};
