import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'hooks/useLanguage';
import { useUser } from 'context/user';

import { Typography } from 'components/shared/Typography';
import { Invoice_Kind } from 'config/invoice/config';

import { FormikField } from 'components/FormikField';
import {
  InputField,
  DatePickerInputField,
  SelectField,
} from 'components/FormikFields';

import styles from 'assets/styles/invoice.scss';
import {
  Currencies,
  CurrenciesFullName,
  DOCUMENT_TYPE_OPTIONS,
  TAX_RATE_OPTIONS,
} from '../../../../config/invoice/config';
import { useFormikContext } from 'formik';

const InvoiceMainSection = () => {
  const { t } = useTranslation();
  const language = useLanguage();
  const { user } = useUser(); // Make sure you have access to the user object
  const { values, setFieldValue } = useFormikContext();

  return (
    <div className={styles.invoiceKindContainer}>
      <Typography weight="bold" className={styles.title}>
        {t('newInvoice_invoiceData')}
      </Typography>
      <div className={language === 'ar' ? 'row' : 'row flex-row-reverse'}>
        <div className="col-md-6 col-sm-12">
          <FormikField
            name="issueDate"
            component={DatePickerInputField}
            componentProps={{
              disabledDays: [{ after: new Date() }],
            }}
            controlProps={{
              className: styles.fieldControl,
              labelProps: {
                className: styles.fieldLabel,
              },
            }}
            required
            label={t('newInvoice_invoice_issueDate')}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <FormikField
            name="buyerInvoiceNumber"
            component={InputField}
            componentProps={{}}
            controlProps={{
              className: styles.fieldControl,
              labelProps: {
                className: styles.fieldLabel,
              },
            }}
            label={t('newInvoice_vendor_invoiceNumber')}
          />
        </div>

        <div className="col-md-6 col-sm-12">
          <FormikField
            name="invoiceKind"
            component={SelectField}
            componentProps={{
              options: Invoice_Kind(t),
              defaultValue: Invoice_Kind(t)[0],
              isDisabled: values.items?.length,
              onChange: (data) => {
                if (data.value !== 'LOCAL') {
                  setFieldValue('tempItem.specialTaxAmount', 0);
                  setFieldValue(
                    'tempItem.generalTaxPercentage',
                    TAX_RATE_OPTIONS(t).find((item) => item.value === 'ZERO'),
                  );
                }
                if (data.value === 'DEVELOPMENTAL') {
                  setFieldValue(
                    'buyer.additionalBuyerIdType',
                    DOCUMENT_TYPE_OPTIONS(t).find(
                      (item) => item.value === 'TAXPAYER_NUMBERS',
                    ),
                  );
                } else {
                  setFieldValue('buyer.additionalBuyerIdType', '');
                }
              },
            }}
            controlProps={{
              labelProps: {
                className: styles.fieldLabel,
              },
            }}
            label={t('Invoice_Kind')}
          />
          {values.invoiceKind?.value === 'DEVELOPMENTAL' && (
            <Typography className={styles.errorText}>
              {t(
                'he_buyer_must_have_a_valid_certificate_from_the_investment_promotion_authority',
              )}
            </Typography>
          )}
        </div>
        <div className="col-md-6 col-sm-12">
          <FormikField
            name="currencyType"
            component={SelectField}
            componentProps={{
              options: Currencies(t).map((item) => ({
                value: item.value,
                // label: `${item.label}`,
                label: `${CurrenciesFullName(t)[item.value]} (${item.label})`,
              })),
              defaultValue: Currencies(t).map((item) => ({
                value: item.value,
                // label: `${item.label}`,
                label: `${CurrenciesFullName(t)[item.value]} (${item.label})`,
              }))[0],
              isDisabled: values.items?.length,
            }}
            controlProps={{
              labelProps: {
                className: styles.fieldLabel,
              },
            }}
            required
            label={t('Currency_Type')}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(InvoiceMainSection);
