import * as Yup from 'yup';
import { PASSWORD_REGEX } from 'constants/validation';

export const validationSchema = (t) =>
  Yup.object().shape({
    currentPassword: Yup.string().required(
      t('change_password_form_validation_curren_password'),
    ),
    newPassword: Yup.string()
      .required(t('change_password_form_validation_password'))
      .matches(PASSWORD_REGEX, t('password_validation')),
    confirmPassword: Yup.string()
      .required(t('change_password_form_validation_confirm_password_required'))
      .oneOf(
        [Yup.ref('newPassword'), null],
        t('change_password_form_validation_confirm_password'),
      ),
  });

export const initialValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export default {
  initialValues,
  validationSchema,
};
