import React, { useState, useRef, createRef, useEffect } from 'react';

import { CODE_LENGTH } from './CodeInput.constants';

import CodeInput from './CodeInput';

const CodeInputContainer = ({
  codeLength,
  resetTrigger,
  onChange,
  ...restProps
}) => {
  const [currentCodeLength, setCurrentCodeLength] = useState(
    codeLength || CODE_LENGTH,
  );

  useEffect(() => {
    if (currentCodeLength !== codeLength) {
      setCurrentCodeLength(codeLength);
    }
  }, [codeLength]);

  const numbersRef = useRef(
    Array.from(Array(currentCodeLength)).map(() => createRef('')),
  );

  const [value, setValue] = useState(
    Array.from(Array(currentCodeLength)).map(() => ''),
  );

  useEffect(() => {
    if (numbersRef.current.length > 0) {
      numbersRef.current[0].current.focus();
    }
  }, [numbersRef.current]);

  useEffect(() => {
    setValue(Array.from(Array(currentCodeLength)).map(() => ''));

    numbersRef.current[0].current.focus();
  }, [resetTrigger]);

  const handleChange = (event, index) => {
    const numberValue = event.target.value;

    const codeArray = numberValue.split('');

    if (index === 0 && numberValue.length === currentCodeLength) {
      setValue(codeArray);

      numbersRef.current[currentCodeLength - 1].current.focus();

      if (onChange) {
        onChange(codeArray.join(''));
      }

      return;
    }

    if (numberValue.length > 1 && index + 1 === currentCodeLength) {
      return;
    }

    if (numberValue === '') {
      if (index > 0) {
        numbersRef.current[index - 1].current.focus();
      }

      const nextValue = [...value];

      nextValue[index] = numberValue;

      setValue(nextValue);

      if (onChange) {
        onChange(nextValue.join(''));
      }

      return;
    }

    if (numberValue.length > 1) {
      numbersRef.current[index + 1].current.focus();

      const nextValue = [...value];

      nextValue[index + 1] = numberValue[numberValue.length - 1];

      setValue(nextValue);

      if (onChange) {
        onChange(nextValue.join(''));
      }

      return;
    }

    if (index + 1 < currentCodeLength) {
      numbersRef.current[index + 1].current.focus();
    }

    if (index + 1 === currentCodeLength) {
      numbersRef.current[index].current.blur();
    }

    const nextValue = [...value];

    nextValue[index] = numberValue;

    setValue(nextValue);

    if (onChange) {
      onChange(nextValue.join(''));
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace') {
      if (index > 0 && !event.target.value) {
        event.stopPropagation();
        event.preventDefault();

        numbersRef.current[index - 1].current.focus();
      }
    }
  };

  return (
    <CodeInput
      {...restProps}
      numbersRef={numbersRef}
      currentCodeLength={currentCodeLength}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  );
};

export default React.memo(CodeInputContainer);
