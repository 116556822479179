import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TOKEN_KEY } from 'constants/common';

import { useUser } from 'context/user';

import SessionProvider from './SessionProvider';

const SessionProviderContainer = (props) => {
  const history = useNavigate();
  const location = useLocation();

  const { i18n } = useTranslation();

  const { user } = useUser();

  const authToken = sessionStorage.getItem(TOKEN_KEY);

  const allowedPages = [
    '/contact-us',
    '/login',
    '/register',
    '/forgot-password',
    '/privacy',
  ];

  useEffect(() => {
    const baseRoute = `/${i18n.language}`;

    const page = location.pathname?.split(baseRoute)[1];

    if (!authToken || !user) {
      if (allowedPages.indexOf(page) === -1) {
        history(`${baseRoute}/login`, { replace: true });

        return;
      }
    }

    if (
      authToken &&
      (location.pathname === `${baseRoute}/login` ||
        location.pathname === `${baseRoute}/register`)
    ) {
      history(`${baseRoute}/`);
    }
  }, [location.pathname]);

  return <SessionProvider {...props} />;
};

export default React.memo(SessionProviderContainer);
