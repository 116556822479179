import React from 'react';
import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';
import { useTranslation } from 'react-i18next';
import styles from './SessionTimeoutModal.styles.scss';

const SessionTimeoutModal = ({ open, onClose, onRenewToken, timeout }) => {
  const { t } = useTranslation();
  const expiryIn = {
    ar: '',
    en: '',
  };
  expiryIn.ar = timeout === 2 ? 'دقيقتين' : 'دقيقة واحدة';
  expiryIn.en = timeout === 2 ? 'Two Minutes' : 'One Minute';

  return (
    <Modal open={open} onClose={onClose}>
      <div className={styles.modal}>
        <Typography className={styles.title}>
          {t('session_timeout_modal_attention')}
        </Typography>
        <Typography className={styles.text}>
          {t('session_timeout_modal_session_is_expiring', { ...expiryIn })}
        </Typography>
        <div className="row">
          <Button onClick={onRenewToken} className={styles.button}>
            {t('session_timeout_modal_renew_token')}
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            className={styles.button}
          >
            {t('session_timeout_modal_logout')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(SessionTimeoutModal);
