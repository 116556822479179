import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/shared/Typography';

import styles from './NotesInfo.styles.scss';

const NotesInfo = ({ notes }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.notes}>
      <Typography className={styles.notesLabel}>
        {t('view_invoice_notes')}
      </Typography>
      <Typography className={styles.notesText}>{notes || '-'}</Typography>
    </div>
  );
};

export default React.memo(NotesInfo);
