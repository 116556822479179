import React from 'react';
import { Field, getIn } from 'formik';

import { FormControl } from 'components/shared/FormControl';

const FormikField = ({
  required,
  name,
  validate,
  label,
  component: Component,
  componentProps,
  controlProps,
}) => (
  <Field name={name} validate={validate}>
    {({ field, form }) => {
      const error = getIn(form.errors, field.name);
      const touched = getIn(form.touched, field.name);
      const haveError = touched && !!error;

      return (
        <FormControl
          haveError={haveError}
          error={error}
          label={label}
          required={required}
          {...controlProps}
        >
          <Component
            haveError={haveError}
            name={name}
            field={field}
            form={form}
            {...componentProps}
          />
        </FormControl>
      );
    }}
  </Field>
);

export default React.memo(FormikField);
