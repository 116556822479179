import axios from 'axios';
import { TOKEN_KEY, REFRESH_TOKEN, OTP_KEY, API_URL } from 'constants/common';
import i18n from 'config/i18n';

const _interceptResponseSuccess = (response) => {
  return response.config.url.indexOf('/sme/invoices/pdf') === -1
    ? response.data
    : response;
};

const _interceptResponseError = (error) => {
  const refreshToken = sessionStorage.getItem(REFRESH_TOKEN);
  if (!error.response) {
    if (error.message) {
      // Something happened in setting up the request that triggered an Error
      return Promise.reject({
        message: `Server Error => ${error.message}`,
        error: `Server Error => ${error.message}`,
      });
    }
    if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return Promise.reject({
        message: `Server Error => ${error.request.responseText}`,
        error: `Server Error request => ${error.request.responseText}`,
      });
    }
    return Promise.reject({ message: 'Server Error', error: 'Server Error' });
  }

  const { status } = error.response;
  const originalConfig = error.config;

  if (
    status === 401 &&
    refreshToken &&
    error.config.url.indexOf('/logout') === -1
  ) {
    originalConfig._retry = true;
    return axios
      .post('/users/auth/refresh-token', { 'refresh-token': refreshToken })
      .then((data) => {
        localStorage.setItem(TOKEN_KEY, data.access_token);
        sessionStorage.setItem(TOKEN_KEY, data.access_token);
        localStorage.setItem(REFRESH_TOKEN, data.refresh_token);
        sessionStorage.setItem(REFRESH_TOKEN, data.refresh_token);

        originalConfig.headers.Authorization = `Bearer ${data.access_token}`;

        return axios(originalConfig);
      })
      .catch(() => {
        localStorage.removeItem(TOKEN_KEY);
        sessionStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN);
        sessionStorage.removeItem(REFRESH_TOKEN);
        localStorage.removeItem(OTP_KEY);

        window.location.replace(`/${i18n.language}/login`);
      });
  }

  return Promise.reject(error.response.data);
};

const _interceptRequest = (request) => {
  const token = localStorage.getItem(TOKEN_KEY);
  const language = i18n.language || 'ar';

  request.headers.common['Accept-Language'] = language;

  if (token) {
    request.headers.common.Authorization = `Bearer ${token}`;
  }

  return request;
};

export const setupAxiosInterceptors = () => {
  axios.defaults.baseURL = API_URL;
  axios.interceptors.request.use(_interceptRequest);

  axios.interceptors.response.use(
    _interceptResponseSuccess,
    _interceptResponseError,
  );
};
