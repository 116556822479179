import React from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/shared/Button';
import { FormikField } from 'components/FormikField';
import { InputField } from 'components/FormikFields';
import { validationSchema, initialValues } from './AddVatForm.formConfig';
import styles from './AddVatForm.styles.scss';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import { useLanguage } from 'hooks/useLanguage';
import { ReCAPTCHA_SITE_KEY } from 'constants/common';

const AddVatForm = ({
  loading,
  onSubmit,
  errorValue,
  onCaptchaChange,
  isValidCaptcha,
}) => {
  const { t } = useTranslation();
  const language = useLanguage();

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={validationSchema(t)}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {() => (
        <Form className={styles.form}>
          <FormikField
            name="taxNumber"
            component={InputField}
            label={t('reset_password_page_tax_number')}
          />

          {/* <ReCAPTCHA
            className={language === 'ar' ? styles.reCaptcha : 'my-3'}
            hl={language}
            sitekey={ReCAPTCHA_SITE_KEY}
            onChange={onCaptchaChange}
          /> */}

          <p className={styles.errorMessage}>{errorValue}</p>

          <Button
            // disabled={!isValidCaptcha}
            className={styles.button}
            loading={loading}
            type="submit"
          >
            {t('reset_password_page_submit')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(AddVatForm);
