import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormikField } from 'components/FormikField';
import { InputField } from 'components/FormikFields';

import styles from './InvoiceNotes.styles.scss';

const InvoiceNotes = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.notes}>
      <FormikField
        name="notes"
        label={t('newInvoice_notes')}
        component={InputField}
        componentProps={{ multiline: true }}
        controlProps={{
          labelProps: {
            className: styles.notesLabel,
          },
        }}
      />
    </div>
  );
};

export default React.memo(InvoiceNotes);
