import React from 'react';

import Input from './Input';

const InputContainer = ({ type, ...restProps }) => {
  const handleKeyPress = (event) => {
    if (
      type === 'number' &&
      (event.code === 'Minus' ||
        event.code === 'NumpadSubtract' ||
        event.key === '+')
    ) {
      event.preventDefault();
    }
  };

  return <Input {...restProps} type={type} onKeyPress={handleKeyPress} />;
};

export default React.memo(InputContainer);
