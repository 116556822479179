import React from 'react';
import cn from 'classnames';

import styles from './Input.styles.scss';

const Input = ({
  className,
  fieldClassName,
  endAdornmentClassName,
  startAdornmentClassName,
  disabled,
  multiline,
  haveError,
  inputRef,
  startAdornment,
  endAdornment,
  iconElement,
  iconClickHandler,

  ...restProps
}) => {
  const Component = multiline ? 'textarea' : 'input';

  const handleIconClick = () => {
    if (iconClickHandler) {
      iconClickHandler();
    }
  };

  return (
    <div
      className={cn(
        styles.input,
        { [styles.inputDisabled]: disabled },
        className,
      )}
    >
      {startAdornment && (
        <div className={cn(styles.startAdornment, startAdornmentClassName)}>
          {startAdornment}
        </div>
      )}

      {iconElement && (
        <div className={cn(styles.startAdornment)}>
          {/* Apply the search icon style to iconElement */}
          <span className={cn(styles.searchIcon)} onClick={handleIconClick}>
            {iconElement}
          </span>
        </div>
      )}

      <Component
        {...restProps}
        ref={inputRef}
        className={cn(
          styles.field,
          {
            [styles.haveError]: haveError,
            [styles.multiline]: multiline,
            [styles.withStartAdorment]: !!startAdornment,
            [styles.withEndAdorment]: !!endAdornment,
          },
          fieldClassName,
        )}
        disabled={disabled}
      />

      {endAdornment && (
        <div className={cn(styles.endAdornment, endAdornmentClassName)}>
          {endAdornment}
        </div>
      )}
    </div>
  );
};

export default React.memo(Input);
