import React from 'react';

import { Header } from 'components/Header';
import { Footer } from 'components/Footer';

import styles from './MainLayout.styles.scss';

const MainLayout = ({ children, loading }) => (
  <div className={styles.layout}>
    <div className={styles.line} />
    <Header />
    <div className={styles.inner}>{children}</div>
    {!loading && <Footer />}
  </div>
);

export default React.memo(MainLayout);
