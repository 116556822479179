import React from 'react';
import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';

import mobileAnimation from 'assets/animations/mobile.json';

import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import { CodeInput } from './components/CodeInput';

import styles from './CheckOTPModal.styles.scss';

const CheckOTPModal = ({
  open,
  loading,
  verifyLoading,
  phoneNumber,
  otpCodeLength,
  onClose,
  onCodeChange,
  onSubmitCodeClick,
  otpError,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      className={styles.modalWrapper}
      centered
      open={open}
      onClose={onClose}
    >
      <div className={styles.modal}>
        {loading ? (
          <Loading className={styles.loading} />
        ) : (
          <>
            <Lottie
              className={styles.animation}
              animationData={mobileAnimation}
              loop
            />
            <Typography className={styles.title}>
              {t('otpmodal_verification_code')}
            </Typography>

            <Typography className={styles.text}>
              {t('otpmodal_sent')}
            </Typography>
            <p className={styles.phoneNumberText}>
              {phoneNumber.replace(/[^\w\s]/gi, '')}
            </p>

            <CodeInput codeLength={otpCodeLength} onChange={onCodeChange} />

            {otpError && (
              <p className={styles.errorMessage}>
                {t('otpmodal_incorrect_otp_message')}
              </p>
            )}
            <Button
              className={styles.button}
              loading={verifyLoading}
              onClick={onSubmitCodeClick}
            >
              {t('otpmodal_confirm')}
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default React.memo(CheckOTPModal);
