import { INVOICE_TYPES_ENUM } from 'constants/common';

export const INVOICE_COLUMNS = ({ t, invoiceType }) => {
  let columns = [
    {
      key: 'totalAmountAfterTaxes',
      label: t('view_invoice_columns_totalAmountAfterTaxes'),
      minWidth: 174,
      maxWidth: 174,
    },
    {
      key: 'generalTaxAmount',
      label: t('view_invoice_columns_generalTaxAmount'),
      minWidth: 174,
      maxWidth: 174,
    },
    {
      key: 'generalTaxPercentage',
      label: t('view_invoice_columns_generalTaxPercentage'),
      minWidth: 174,
      maxWidth: 174,
    },
    {
      key: 'specialTaxAmount',
      label: t('invoice_columns_special_tax_amount'),
      minWidth: 174,
      maxWidth: 174,
    },
    {
      key: 'totalAmountAfterDiscount',
      label: t('view_invoice_columns_totalAmountAfterDiscount'),
      minWidth: 136,
      maxWidth: 136,
    },
    {
      key: 'discountAmount',
      label: t('view_invoice_columns_discountAmount'),
      minWidth: 136,
      maxWidth: 136,
    },
    {
      key: 'subtotalAmount',
      label: t('view_invoice_columns_subtotalAmount'),
      minWidth: 136,
      maxWidth: 136,
    },
    {
      key: 'unitPrice',
      label: t('view_invoice_columns_unitPrice'),
      minWidth: 136,
      maxWidth: 136,
    },
    {
      key: 'quantity',
      label: t('view_invoice_columns_quantity'),
      minWidth: 136,
      maxWidth: 136,
    },
    {
      key: 'description',
      label: t('view_invoice_columns_description'),
      minWidth: 200,
    },
    {
      key: 'isic4',
      label: t('invoice_columns_isic4'),
      minWidth: 200,
    },
  ];

  if (
    invoiceType === INVOICE_TYPES_ENUM.CASH_INCOME ||
    invoiceType === INVOICE_TYPES_ENUM.RECEIVABLE_INCOME
  ) {
    columns = columns.filter(
      (column) =>
        column.key !== 'subtotalAmount' &&
        column.key !== 'totalAmountAfterDiscount' &&
        column.key !== 'generalTaxPercentage' &&
        column.key !== 'generalTaxAmount',
    );
  }

  if (
    invoiceType !== INVOICE_TYPES_ENUM.CASH_SPECIAL_TAX &&
    invoiceType !== INVOICE_TYPES_ENUM.RECEIVABLE_SPECIAL_TAX
  ) {
    columns = columns.filter((column) => column.key !== 'specialTaxAmount');
  }

  return columns;
};
