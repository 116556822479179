import React from 'react';
import { useNavigation } from 'react-day-picker';
import format from 'date-fns/format';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './Caption.styles.scss';

const Caption = ({ displayMonth }) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();

  return (
    <div className={styles.caption}>
      <Icon
        className={styles.prevIcon}
        name="leftArrow"
        onClick={() => previousMonth && goToMonth(previousMonth)}
      />
      <Typography className={styles.text} weight="bold">
        {format(displayMonth, 'MMMM yyyy')}
      </Typography>
      <Icon
        className={styles.nextIcon}
        name="rightArrow"
        onClick={() => nextMonth && goToMonth(nextMonth)}
      />
    </div>
  );
};

export default React.memo(Caption);
