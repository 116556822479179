import * as Yup from 'yup';

import { PASSWORD_REGEX } from 'constants/validation';

export const validationSchema = (t) =>
  Yup.object().shape({
    taxNumber: Yup.string().required(t('register_form_validation_taxnumber')),
    username: Yup.string()
      .matches(/^\w+$/, t('register_form_validation_username_english'))
      .required(t('register_form_validation_username')),
    password: Yup.string()
      .required(t('register_form_validation_password'))
      .matches(PASSWORD_REGEX, t('password_validation')),
    confirmPassword: Yup.string()
      .required(t('register_form_validation_confirm_password_required'))
      .oneOf(
        [Yup.ref('password'), null],
        t('register_form_validation_confirm_password'),
      ),
  });

export const initialValues = {
  taxNumber: '',
  username: '',
  password: '',
  confirmPassword: '',
};

export default {
  initialValues,
  validationSchema,
};
