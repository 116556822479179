import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import homeBgUrl from 'assets/img/home-bg.png';
import homeBgEnUrl from 'assets/img/home-bg-en.png';

import DeviceIcon from 'assets/img/device.svg';
import BillsIcon from 'assets/img/bills.svg';
import InvoiceIcon from 'assets/img/invoice.svg';
import UserIcon from 'assets/img/user.svg';

import { Typography } from 'components/shared/Typography';

import { MainLayout } from 'components/MainLayout';

import styles from './HomePage.styles.scss';
import { updateUserData } from '../../api/methods';
import { showToast } from 'helpers/showToast';

const HomePage = ({ isAdmin, user, onItemClick, isDeviceInUse }) => {
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.dir();

  return (
    <MainLayout>
      <div className={styles.page}>
        <div className={styles.line} />
        <img
          className={styles.bg}
          src={i18n.language === 'ar' ? homeBgUrl : homeBgEnUrl}
        />
        <Typography className={styles.title}>
          {t('home_electronic_bills')}
        </Typography>
        <div className={styles.actions}>
          {isAdmin && (
            <div
              className={cn(styles.action)}
              onClick={() => onItemClick('settings')}
            >
              <InvoiceIcon className={styles.actionIcon} />
              <Typography className={styles.actionTitle}>
                {t('home_settings')}
              </Typography>
            </div>
          )}
          {!isAdmin && user?.activitiesList?.length === 1 && (
            <div
              className={cn(styles.action, {
                [styles.disabledAction]: isDeviceInUse,
              })}
              onClick={() =>
                !isDeviceInUse ? onItemClick('createInvoice') : () => {}
              }
            >
              <InvoiceIcon className={styles.actionIcon} />
              <Typography className={styles.actionTitle}>
                {t('home_create_modify_invoice')}
              </Typography>
            </div>
          )}
          {/* <div
            className={cn(styles.action, {
              [styles.disabledAction]: false,
            })}
            onClick={() => onItemClick('createInvoice')}
          >
            <InvoiceIcon className={styles.actionIcon} />
            <Typography className={styles.actionTitle}>
              {t('home_create_modify_invoice')}
            </Typography>
          </div> */}

          <div
            className={styles.action}
            onClick={() => onItemClick('invoices')}
          >
            <BillsIcon className={styles.actionIcon} />
            <Typography className={styles.actionTitle}>
              {t('home_view_bills')}
            </Typography>
          </div>
          {isAdmin && (
            <div
              className={styles.action}
              onClick={() => onItemClick('devices')}
            >
              <DeviceIcon className={styles.actionIcon} />
              <Typography className={styles.actionTitle}>
                {t('home_connect_devices')}
              </Typography>
            </div>
          )}
          {isAdmin && (
            <div className={styles.action} onClick={() => onItemClick('users')}>
              <UserIcon className={styles.actionIcon} />
              <Typography className={styles.actionTitle}>
                {t('home_add_sub_user')}
              </Typography>
            </div>
          )}
        </div>
        {isAdmin && (
          <div
            style={{
              backgroundColor: 'lightblue',
              padding: '10px 20px',
              borderRadius: '5px',
              marginTop: '20px',
              color: 'var(--color-red)',
              textAlign: languageDirection === 'rtl' ? 'right' : 'left',
              direction: languageDirection,
            }}
          >
            <p style={{ fontWeight: 'bold' }}>{t('notes') + ':'}</p>
            <ul>
              <li>
                <p>- {t('admin_note')}</p>
              </li>
              <li>
                <p>
                  - {t('update_user_data_note')}{' '}
                  <button
                    className={styles['update-user-btn']}
                    onClick={() => {
                      updateUserData(user?.taxNumber || '')
                        .then(() => {
                          showToast({
                            data: {
                              variant: 'success',
                              text: t('user_data_been_updated'),
                            },
                          });
                        })
                        .catch((err) => {
                          if (typeof err.error === 'string') {
                            showToast({
                              data: {
                                variant: 'error',
                                text: err.error,
                              },
                            });
                          }
                        });
                    }}
                  >
                    {t('here')}
                  </button>
                </p>
              </li>
            </ul>
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default React.memo(HomePage);
