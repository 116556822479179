import React from 'react';

import { getProductWithTotals } from 'config/invoice/helpers';

import EditInvoiceProductModal from './EditInvoiceProductModal';

const EditInvoiceProductModalContainer = ({
  itemIndex,
  invoiceType,
  parentSetFieldValue,
  parentValues,
  onClose,
  isEdit,
  ...restProps
}) => {
  const handleFormSubmit = (currentItem) => {
    const nextItems = parentValues.items.map((item, index) => {
      if (index === itemIndex) {
        if (item !== currentItem) {
          currentItem.updated = true;
          currentItem.deleted = false;
        }
        currentItem.quantity = Number(currentItem.quantity).toFixed(9);
        currentItem.specialTaxAmount = Number(
          currentItem.specialTaxAmount,
        ).toFixed(9);
        currentItem.subtotalAmount = Number(currentItem.subtotalAmount).toFixed(
          9,
        );
        currentItem.unitPrice = Number(currentItem.unitPrice).toFixed(9);
        return getProductWithTotals({ item: currentItem, invoiceType });
      }

      return item;
    });

    parentSetFieldValue('items', nextItems);

    onClose();
  };

  const prepareQuantity = (val) => {
    return val.replace(/^0+/, '');
  };

  const handleChange = (e, setFieldValue, initialValues) => {
    const { discountAmount, quantity, specialTaxAmount } = initialValues;

    if (!isNaN(+e.target.value) && e.target.value) {
      if (+e.target.value <= quantity) {
        const discountPercentage = discountAmount
          ? discountAmount / (quantity ? quantity : 1)
          : 0;
        const specialTaxPercentage = specialTaxAmount
          ? specialTaxAmount / (quantity ? quantity : 1)
          : 0;

        const newDiscount =
          discountPercentage * (e?.target?.value ? +e?.target?.value : 1);
        const newspecialTaxAmount =
          specialTaxPercentage * (e?.target?.value ? +e?.target?.value : 1);

        setFieldValue('quantity', prepareQuantity(e?.target?.value) || '');
        setFieldValue('discountAmount', +newDiscount.toFixed(9));
        setFieldValue('specialTaxAmount', +newspecialTaxAmount.toFixed(9));
      } else {
        return;
      }
    } else {
      setFieldValue('quantity', +e?.target?.value || 0);
    }
  };

  return (
    <EditInvoiceProductModal
      {...restProps}
      invoiceType={invoiceType}
      isEdit={isEdit}
      onClose={onClose}
      onFormSubmit={handleFormSubmit}
      handleChange={handleChange}
    />
  );
};

export default React.memo(EditInvoiceProductModalContainer);
