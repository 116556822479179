import React from 'react';
import { useTranslation } from 'react-i18next';

import { INVOICE_TYPES_ENUM } from 'constants/common';

import { DOCUMENT_TYPE_OPTIONS, PROVINCE_OPTIONS } from 'config/invoice/config';
import { useUser } from 'context/user';

import { Typography } from 'components/shared/Typography';

import styles from './UsersInfo.styles.scss';

const UsersInfo = ({ seller, buyer, invoiceType }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const getBuyerLabel = () => {
    const labelObj = DOCUMENT_TYPE_OPTIONS(t).find(
      (item) => item.value === buyer.buyerIdTypeCode,
    );
    if (labelObj) {
      return labelObj.label;
    }
    return t('invoice_page_buyer_number');
  };
  return (
    <div className={`${styles.info} ${styles.borderTop}`}>
      <div className={`${styles.item}`}>
        <div className={`${styles.itemInner} ${styles.threeCol}`}>
          <div className={styles.itemCell}>
            <Typography className={styles.itemCellType}>
              {t('invoice_page_buyer_name')}:
            </Typography>
            <Typography className={styles.itemCellValue}>
              {buyer.name || '-'}
            </Typography>
          </div>
          <div />
          <div className={styles.itemCell}>
            <Typography className={styles.itemCellType}>
              {getBuyerLabel()}:
            </Typography>
            <Typography className={styles.itemCellValue}>
              {buyer.buyerId || '-'}
            </Typography>
          </div>
        </div>
        <div className={`${styles.itemInner} ${styles.threeCol}`}>
          <div className={styles.itemCell}>
            <Typography className={styles.itemCellType}>
              {t('view_invoice_usersInfo_phoneNumber')}:
            </Typography>
            <Typography className={styles.itemCellValue}>
              {buyer.phoneNumber || '-'}
            </Typography>
          </div>
          <div className={styles.itemCell}>
            <Typography className={styles.itemCellType}>
              {t('view_invoice_usersInfo_city')}:
            </Typography>
            <Typography className={styles.itemCellValue}>
              {PROVINCE_OPTIONS().find(
                (item) => item.value === buyer?.provinceCode,
              )?.label || '-'}
            </Typography>
          </div>
          <div className={styles.itemCell}>
            <Typography className={styles.itemCellType}>
              {t('view_invoice_usersInfo_postalCode')}:
            </Typography>
            <Typography className={styles.itemCellValue}>
              {buyer.postalCode || '-'}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(UsersInfo);
