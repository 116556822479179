import { getUserLogo } from '../api/methods';

export const viewUserLogo = ({
  setLoading,
  setImage,
  setFieldValue,
  activityNumber,
}) => {
  setLoading(true);
  getUserLogo(activityNumber)
    .then((resp) => {
      if (setFieldValue) {
        const parts = [resp];
        // eslint-disable-next-line no-undef
        const file = new File(parts, 'logo', {
          type: resp?.type,
        });
        setFieldValue('invoiceLogo', file);
      }

      const reader = new window.FileReader();
      reader.readAsDataURL(resp);
      reader.onloadend = function () {
        const base64data = reader.result;
        setImage(base64data);
      };
      setLoading(false);
    })
    .catch(() => {
      if (setFieldValue) {
        setFieldValue('invoiceLogo', '');
      }
      setImage('');
      setLoading(false);
    });
};
