import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import LangSelect from './LangSelect';

const LangSelectContainer = (props) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const history = useNavigate();

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const handleLangClick = () => {
    const pathnameWithoutLang = location.pathname
      .split('/')
      .filter((pathChunk) => pathChunk !== 'en' && pathChunk !== 'ar')
      .join('/');

    if (currentLanguage === 'en') {
      i18n.changeLanguage('ar');

      history(`/ar${pathnameWithoutLang}`, { replace: true });

      setCurrentLanguage('ar');
    } else {
      i18n.changeLanguage('en');

      history(`/en${pathnameWithoutLang}`, { replace: true });

      setCurrentLanguage('en');
    }
  };

  return (
    <LangSelect
      {...props}
      currentLanguage={currentLanguage}
      onLangClick={handleLangClick}
    />
  );
};

export default React.memo(LangSelectContainer);
