import React, { useState } from 'react';
import ChangePasswordForm from './ChangePasswordForm';

import * as api from 'api/methods';

import { showToast } from 'helpers/showToast';

import { useTranslation } from 'react-i18next';
import { logoutUser } from '../../../../helpers/logUserOut';

const ChangePasswordFormContainer = (props) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const handleSubmit = (values) => {
    setLoading(true);
    api
      .changePassword({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      })
      .then(() => {
        setLoading(false);
        showToast({
          data: {
            variant: 'success',
            text: t('reset_password_success'),
          },
        });
        logoutUser();
      })
      .catch((error) => {
        if (error) {
          setLoading(false);
          showToast({
            data: {
              variant: 'error',
              text: error.error,
            },
          });
        }
        setLoading(false);
      });
  };

  return (
    <>
      <ChangePasswordForm onSubmit={handleSubmit} loading={loading} />
    </>
  );
};

export default React.memo(ChangePasswordFormContainer);
