import React from 'react';
import SessionTimeoutModal from './SessionTimeoutModal';
import { logoutUser } from '../../helpers/logUserOut';
import axios from 'axios';
import { TOKEN_KEY, REFRESH_TOKEN, SESSION_TIMEOUT } from 'constants/common';

const SessionTimeoutModalContainer = ({ open, closePopup, ...restProps }) => {
  const handleClose = () => {
    return logoutUser();
  };

  const handleRenewToken = async () => {
    const dateNow = new Date().getTime();
    const refreshToken = sessionStorage.getItem(REFRESH_TOKEN);
    try {
      const data = await axios.post('/users/auth/refresh-token', {
        'refresh-token': refreshToken,
      });
      localStorage.setItem(TOKEN_KEY, data.access_token);
      localStorage.setItem(REFRESH_TOKEN, data.refresh_token);

      sessionStorage.setItem(TOKEN_KEY, data.access_token);
      sessionStorage.setItem(REFRESH_TOKEN, data.refresh_token);
      sessionStorage.setItem(SESSION_TIMEOUT, data.expires_in + dateNow);
      closePopup();
    } catch {
      return logoutUser();
    }
  };

  return (
    <SessionTimeoutModal
      {...restProps}
      open={open}
      onClose={handleClose}
      onRenewToken={handleRenewToken}
    />
  );
};

export default React.memo(SessionTimeoutModalContainer);
