import React from 'react';
import cn from 'classnames';

import styles from './CodeInput.styles.scss';

const CodeInput = ({
  numbersRef,
  currentCodeLength,
  value,
  error,
  onChange,
  onKeyDown,
}) => (
  <div className={styles.input}>
    {Array.from(Array(currentCodeLength)).map((_, index) => (
      <input
        key={index}
        ref={numbersRef.current[index]}
        className={cn(styles.field, { [styles.fieldError]: error })}
        type="number"
        value={value[index]}
        onChange={(event) => onChange(event, index)}
        onKeyDown={(event) => {
          onKeyDown(event, index);
        }}
      />
    ))}
  </div>
);

export default React.memo(CodeInput);
