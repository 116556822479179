import React from 'react';
import { toast } from 'react-toastify';

import { Toast } from 'components/shared/Toast';

export const showToast = ({ toastOptions, data, component = Toast }) => {
  const Component = component;

  toast(<Component {...data} />, {
    ...toastOptions,
    closeOnClick: true,
    autoClose: 2000,
    closeButton: () => null,
  });
};
