import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { t } from 'i18next';

import { DOCUMENT_TYPE_OPTIONS, PROVINCE_OPTIONS } from 'config/invoice/config';

import { INVOICE_NOTE_TYPES, INVOICE_TYPES_ENUM } from 'constants/common';

const INVOICE_TYPES_BY_NUMBER = {
  10: 'CASH_INCOME',
  11: 'CASH_GENERAL_TAX',
  12: 'CASH_SPECIAL_TAX',
  20: 'RECEIVABLE_INCOME',
  21: 'RECEIVABLE_GENERAL_TAX',
  22: 'RECEIVABLE_SPECIAL_TAX',
};

// 0 - INCOME_TAX 1 - GENERAL_TAX 2 - SPECIAL_TAX

export const calculateItemSubtotalAmount = ({ quantity, unitPrice }) =>
  quantity * unitPrice;

export const calculateItemTotalAmountAfterDiscount = ({
  subtotalAmount,
  discountAmount,
}) => {
  return subtotalAmount - discountAmount;
};

export const calculateItemTaxAmount = ({
  totalAmountAfterDiscount,
  invoiceType,
  taxPercent,
}) => {
  if (
    totalAmountAfterDiscount === undefined ||
    taxPercent === undefined ||
    invoiceType === INVOICE_TYPES_ENUM.CASH_INCOME ||
    invoiceType === INVOICE_TYPES_ENUM.RECEIVABLE_INCOME
  ) {
    return 0;
  }

  return ((totalAmountAfterDiscount * parseFloat(taxPercent)) / 100).toFixed(9);
};

export const calculateItemTotalAmountAfterTaxes = ({
  totalAmountAfterDiscount,
  taxAmount,
  specialTaxAmount,
}) => (+totalAmountAfterDiscount + +taxAmount + +specialTaxAmount).toFixed(9);

export const calculateSubtotalAmount = (items) =>
  items.reduce((amount, item) => +item.subtotalAmount + amount, 0).toFixed(3);

export const calculateTotalSpecialTaxAmount = (
  items,
  invoiceKind = 'LOCAL',
) => {
  if (invoiceKind === 'LOCAL') {
    return items
      .reduce((amount, item) => +item.specialTaxAmount + amount, 0)
      .toFixed(3);
  }
  return 0;
};

export const calculateTotalDiscountAmount = (items) =>
  items.reduce((amount, item) => +item.discountAmount + amount, 0).toFixed(3);

export const calculateTotalTaxesAmount = (items, invoiceKind = 'LOCAL') => {
  if (invoiceKind === 'LOCAL') {
    return items
      .reduce((amount, item) => +item.generalTaxAmount + amount, 0)
      .toFixed(3);
  }
  return 0;
};

export const calculateTotalAmountAfterTaxes = (
  items,
  invoiceKind = 'LOCAL',
) => {
  if (invoiceKind === 'LOCAL') {
    return items
      .reduce((amount, item) => +item.totalAmountAfterTaxes + amount, 0)
      .toFixed(3);
  }
  return items
    .reduce(
      (amount, item) =>
        +item.totalAmountAfterTaxes -
        +item.generalTaxAmount -
        +(item.specialTaxAmount || 0) +
        amount,
      0,
    )
    .toFixed(3);
};

export const calculateTotalWithSpecialTaxAmount = (
  items,
  invoiceKind = 'LOCAL',
) => {
  if (invoiceKind === 'LOCAL') {
    return items
      .reduce(
        (amount, item) =>
          +item.totalAmountAfterTaxes - +item.generalTaxAmount + amount,
        0,
      )
      .toFixed(3);
  }
  return items
    .reduce(
      (amount, item) =>
        +item.totalAmountAfterTaxes -
        +item.generalTaxAmount -
        +(item.specialTaxAmount || 0) +
        amount,
      0,
    )
    .toFixed(3);
};

export const getInvoiceType = ({ generalType, user }) => {
  const invoiceTypeNumber = user.invoiceTypeNumber;

  switch (generalType) {
    case 'cash': {
      return INVOICE_TYPES_BY_NUMBER[`1${invoiceTypeNumber}`];
    }
    case 'receivable': {
      return INVOICE_TYPES_BY_NUMBER[`2${invoiceTypeNumber}`];
    }
  }
};

export const getInvoiceNoteTypesOptions = () =>
  INVOICE_NOTE_TYPES.map((noteType) => {
    switch (noteType) {
      case 'DEBIT_INVOICE': {
        return {
          label: 'مدين',
          value: noteType,
        };
      }
      case 'CREDIT_INVOICE': {
        return {
          label: 'دائن',
          value: noteType,
        };
      }
    }
  });

export const getProductWithTotals = ({ item, invoiceType }) => {
  if (item.type.value === 'PRODUCT') {
    const subtotalAmount = calculateItemSubtotalAmount({
      quantity: item.quantity,
      unitPrice: item.unitPrice,
    });
    const totalAmountAfterDiscount = calculateItemTotalAmountAfterDiscount({
      subtotalAmount,
      discountAmount: item.discountAmount,
    });
    const generalTaxAmount = calculateItemTaxAmount({
      totalAmountAfterDiscount:
        totalAmountAfterDiscount + +item.specialTaxAmount,
      invoiceType,
      taxPercent: getTaxRateValueByType(item.generalTaxPercentage?.value),
    });
    const totalAmountAfterTaxes = calculateItemTotalAmountAfterTaxes({
      totalAmountAfterDiscount,
      taxAmount: generalTaxAmount,
      specialTaxAmount: item.specialTaxAmount,
    });

    item.totalAmountAfterTaxes = Number(totalAmountAfterTaxes).toFixed(9);
    item.generalTaxAmount = Number(generalTaxAmount).toFixed(9);
    item.totalAmountAfterDiscount = Number(totalAmountAfterDiscount).toFixed(9);
    item.subtotalAmount = Number(subtotalAmount).toFixed(9);
  } else {
    const subtotalAmount = calculateItemSubtotalAmount({
      quantity: 1,
      unitPrice: item.unitPrice,
    });
    const totalAmountAfterDiscount = calculateItemTotalAmountAfterDiscount({
      subtotalAmount: item.unitPrice,
      discountAmount: item.discountAmount,
    });
    const generalTaxAmount = calculateItemTaxAmount({
      totalAmountAfterDiscount:
        totalAmountAfterDiscount + +item.specialTaxAmount,
      taxPercent: getTaxRateValueByType(item.generalTaxPercentage?.value),
    });
    const totalAmountAfterTaxes = calculateItemTotalAmountAfterTaxes({
      totalAmountAfterDiscount: item.unitPrice,
      taxAmount: generalTaxAmount,
      specialTaxAmount: item.specialTaxAmount,
    });

    item.unitPrice = '';
    item.totalAmountAfterTaxes = Number(totalAmountAfterTaxes).toFixed(9);
    item.generalTaxAmount = Number(generalTaxAmount).toFixed(9);
    item.totalAmountAfterDiscount = Number(totalAmountAfterDiscount).toFixed(9);
    item.description = 'بدل خدمة';
    item.subtotalAmount = Number(subtotalAmount).toFixed(9);
  }

  return item;
};

export const convertFormDataToApi = ({
  invoiceTypeCode,
  data,
  isEdit,
  invoiceTypeCodeTemp = '',
}) => {
  if (!data) {
    return null;
  }
  const items = isEdit ? [...data.editedItems] : [...data.items];

  const totalAmountExcludingTaxes = calculateSubtotalAmount(items);
  const totalDiscountsAmount = calculateTotalDiscountAmount(items);
  const totalGeneralTaxesAmount = calculateTotalTaxesAmount(
    items,
    data.invoiceKind?.value,
  );
  const totalSpecialTaxAmount = calculateTotalSpecialTaxAmount(
    items,
    data.invoiceKind?.value,
  );
  const totalPayableAmount = calculateTotalAmountAfterTaxes(
    items,
    data.invoiceKind?.value,
  );
  const totalWithSpecialTaxAmount = calculateTotalWithSpecialTaxAmount(
    items,
    data.invoiceKind?.value,
  );

  const formData = {
    invoiceTypeCode: isEdit ? data.invoiceTypeCodeTemp : invoiceTypeCode,
    invoiceNumber: data.invoiceNumber,
    buyerInvoiceNumber: data.buyerInvoiceNumber,
    issueDate: format(
      parse(data.issueDate, 'dd/MM/yyyy', new Date()),
      'dd-MM-yyyy',
    ),
    invoiceKind: data.invoiceKind?.value,
    currencyEnum: data.currencyType?.value,
    notes: data.notes,
    buyerDTO:
      data.buyer.name ||
      data.buyer?.postalCode ||
      data.buyer?.phoneNumber ||
      data.buyer?.additionalBuyerIdType?.value ||
      data.buyer?.additionalBuyerId ||
      data.buyer?.province?.value
        ? {
            buyerName: data.buyer?.name,
            postalCode: data.buyer?.postalCode || null,
            phoneNumber: data.buyer?.phoneNumber || null,
            additionalBuyerIdType:
              (isEdit
                ? DOCUMENT_TYPE_OPTIONS(t).find(
                    (val) => val.label === data?.buyer?.additionalBuyerIdType,
                  )?.value
                : data.buyer?.additionalBuyerIdType?.value) || null,
            additionalBuyerId: data.buyer?.additionalBuyerId,
            provinceDTO: {
              provinceCode: isEdit
                ? PROVINCE_OPTIONS().find(
                    (ele) => ele.label === data?.buyer?.province,
                  )?.value
                : data.buyer?.province?.value,
            },
          }
        : null,
    activityDTO: {
      activity: data.seller?.activityNumber,
    },
    totalAmountExcludingTaxes: +totalAmountExcludingTaxes,
    totalDiscountsAmount: +totalDiscountsAmount,
    totalGeneralTaxesAmount: +totalGeneralTaxesAmount,
    totalSpecialTaxAmount: +totalSpecialTaxAmount,
    totalWithSpecialTaxAmount: +totalWithSpecialTaxAmount,
    totalPayableAmount: +totalPayableAmount,
    invoiceItemDTOList: items.map((item) => {
      const itemData = {
        invoiceItemType: item.type?.value,
        productDescription: item.description,
        quantity: +item.quantity,
        unitPrice: +item.unitPrice,
        subtotalAmount: +item.subtotalAmount,
        discountAmount: +item.discountAmount,
        totalAmountAfterDiscount: +item.totalAmountAfterDiscount,
        generalTaxAmount: +item.generalTaxAmount,
        totalAmountAfterTaxes: +item.totalAmountAfterTaxes,
        specialTaxAmount: +item.specialTaxAmount,
        uuid: item.id,
        isic4: item.isic4?.value,
      };

      if (
        invoiceTypeCode === INVOICE_TYPES_ENUM.CASH_INCOME ||
        invoiceTypeCode === INVOICE_TYPES_ENUM.RECEIVABLE_INCOME
      ) {
        return {
          ...itemData,
          generalTaxPercentage: null,
          generalTaxType: null,
        };
      }

      return {
        ...itemData,
        generalTaxPercentage: getTaxRateValueByType(
          item.generalTaxPercentage?.value,
        ),
        generalTaxType: item.generalTaxPercentage?.value,
      };
    }),
  };

  if (data.originalInvoiceNumber) {
    formData.originalInvoiceNumber = data.originalInvoiceNumber;
  }

  if (data.originalInvoiceTotal || data.originalInvoiceTotal === 0) {
    formData.originalInvoiceTotal = data.originalInvoiceTotal;
  }

  if (data.reasonOfNote) {
    formData.reasonOfNote = data.reasonOfNote;
  }

  if (isEdit) {
    formData.originalInvoiceUUID = data.originalInvoiceUUID;
    formData.noteType = 'CREDIT_INVOICE';
  }

  return formData;
};

export const getTaxRateValueByType = (type) => {
  switch (type) {
    case 'EXEMPT':
    case 'ZERO': {
      return 0;
    }
    case 'ONE': {
      return 1;
    }
    case 'TWO': {
      return 2;
    }
    case 'THREE': {
      return 3;
    }
    case 'FOUR': {
      return 4;
    }
    case 'FIVE': {
      return 5;
    }
    case 'SIX': {
      return 6;
    }
    case 'SEVEN': {
      return 7;
    }
    case 'EIGHT': {
      return 8;
    }
    case 'TEN': {
      return 10;
    }
    case 'SIXTEEN': {
      return 16;
    }
  }
};
export const getTaxRateValueByTypeForTable = (type) => {
  switch (type) {
    case 'EXEMPT': {
      return 'EXEMPT';
    }
    case 'ZERO': {
      return 0;
    }
    case 'ONE': {
      return 1;
    }
    case 'TWO': {
      return 2;
    }
    case 'THREE': {
      return 3;
    }
    case 'FOUR': {
      return 4;
    }
    case 'FIVE': {
      return 5;
    }
    case 'SIX': {
      return 6;
    }
    case 'SEVEN': {
      return 7;
    }
    case 'EIGHT': {
      return 8;
    }
    case 'TEN': {
      return 10;
    }
    case 'SIXTEEN': {
      return 16;
    }
  }
};

export const getTaxRateValueText = (type) => {
  return type === 'EXEMPT'
    ? t('tax_rate_exempt')
    : `${getTaxRateValueByType(type)} %`;
};

export const validateItemFields = async ({
  item,
  invoiceType,
  setTouched,
  setErrors,
  validateForm,
}) => {
  const formErrors = await validateForm();
  const itemType = item.type?.value;

  const errors = [];

  if (
    invoiceType !== INVOICE_TYPES_ENUM.CASH_INCOME &&
    invoiceType !== INVOICE_TYPES_ENUM.RECEIVABLE_INCOME
  ) {
    errors.push({
      key: 'generalTaxPercentage',
      value: formErrors?.tempItem?.generalTaxPercentage,
    });

    if (!item.generalTaxPercentage) {
      errors.push({
        key: 'generalTaxPercentage',
        value: t('create_invoice_validation_tax_percentage_required'),
      });
    }
  }

  if (
    invoiceType === INVOICE_TYPES_ENUM.CASH_SPECIAL_TAX ||
    invoiceType === INVOICE_TYPES_ENUM.RECEIVABLE_SPECIAL_TAX
  ) {
    errors.push({
      key: 'specialTaxAmount',
      value: formErrors?.tempItem?.specialTaxAmount,
    });
  }

  if (itemType === 'PRODUCT') {
    errors.push({
      key: 'discountAmount',
      value: formErrors?.tempItem?.discountAmount,
    });
    errors.push({
      key: 'quantity',
      value: formErrors?.tempItem?.quantity,
    });
    errors.push({
      key: 'description',
      value: formErrors?.tempItem?.description,
    });

    if (!item.quantity) {
      errors.push({
        key: 'quantity',
        value: t('create_invoice_validation_quantity_required'),
      });
    }
    if (!item.description) {
      errors.push({
        key: 'description',
        value: t('create_invoice_validation_description_required'),
      });
    }
  }

  errors.push({
    key: 'unitPrice',
    value: formErrors?.tempItem?.unitPrice,
  });

  if (!item.unitPrice) {
    errors.push({
      key: 'unitPrice',
      value: t('create_invoice_validation_unit_price_required'),
    });
  }

  const errorsToShow = errors
    .filter((error) => !!error.value)
    .reduce(
      (errorsObj, error) => {
        errorsObj.tempItem[error.key] = error.value;

        return errorsObj;
      },
      { tempItem: {} },
    );
  const errorsToTouched = errors
    .filter((error) => !!error.value)
    .reduce(
      (errorsObj, error) => {
        errorsObj.tempItem[error.key] = true;

        return errorsObj;
      },
      { tempItem: {} },
    );

  setTouched(errorsToTouched, false);
  setErrors(errorsToShow);

  return errors.filter((error) => !!error.value).length > 0;
};
